const pinen = "5 Pieces Mahogany/Rosewood";
const pinzh = "五拼一体 桃花芯/玫瑰木";
const pinfr = "5 piéces Acajou/Palissandre";
const xsen = "D'Addario XS";
const xszh = "达达里奥 XS";
const gdcen = "Gomans Die Cast Gold With Gold Buttons";

export const guitarSpecs =
{
  o000_S12HC: {
    series: "000",
    shape: "000",
    model: "000-S12HC",
    desc: {
      en: "",
      zh: "",
      fr: ""
    },
    pics: {
      nav: "/000-S12HC/nav.jpg",
      head: "/000-S12HC/head.jpg",
      bg: "/000-S12HC/bg.jpg",
      special: [
        "/000-S12HC/1.jpg",
        "/000-S12HC/2.jpg",
        "/000-S12HC/3.jpg",
        "/000-S12HC/4.jpg",
        "/000-S12HC/5.jpg",
        "/000-S12HC/6.jpg",
        "/000-S12HC/7.jpg"
      ]
    },
    video: {
      zh: "//player.bilibili.com/player.html?bvid=BV1cg411C72y&autoplay=0",
      en: ""
    },
    spec: {
      en: {
        model: "000-S12HC",
        shape: "000",
        Top: "Solid Sitka Spruce",
        BackSides: "Solid Mahogany",
        Neck: pinen,
        Fingerboard: "Rosewood",
        brg: "Rosewood",
        Nut: "Bone",
        Saddle: "Bone",
        TuningMachine: gdcen,
        String: xsen,
        FinishTop: "Natural",
        FinishNeck: "Satin",
        FinishBody: "Gloss"
      },
      zh: {
        model: "000-S12HC",
        shape: "000 型",
        Top: "西特卡云杉单板",
        BackSides: "桃花芯单板",
        Neck: pinzh,
        Fingerboard: "玫瑰木",
        brg: "玫瑰木",
        Nut: "牛骨",
        Saddle: "牛骨",
        TuningMachine: "金色全封闭带金色钮",
        String: xszh,
        FinishTop: "自然色",
        FinishNeck: "哑光",
        FinishBody: "亮光"
      },
      fr: {
        model: "000-S12HC",
        shape: "000",
        Top: "Epicéa Sitka Massif",
        BackSides: "Epicéa Acajou Massif",
        Neck: pinfr,
        Fingerboard: "Palissandre",
        brg: "Palissandre",
        Nut: "Os",
        Saddle: "Os",
        TuningMachine: "Gomans Die Cast Doré avec boutons dorés",
        String: xsen,
        FinishTop: "Nature",
        FinishNeck: "Satiné",
        FinishBody: "Brillant"
      }
    }
  },
  o000_S12HCS: {
    series: "000",
    shape: "000",
    model: "000-S12HCS",
    desc: {
      en: "",
      zh: "",
      fr: ""
    },
    pics: {
      nav: "/000-S12HCS/nav.jpg",
      head: "/000-S12HCS/head.jpg",
      bg: "/000-S12HCS/bg.jpg",
      special: [
        "/000-S12HCS/1.jpg",
        "/000-S12HCS/2.jpg",
        "/000-S12HCS/3.jpg",
        "/000-S12HCS/4.jpg",
        "/000-S12HCS/5.jpg",
        "/000-S12HCS/6.jpg",
        "/000-S12HCS/7.jpg",
        "/000-S12HCS/8.jpg"
      ]
    },
    video: {
      zh: "",
      en: ""
    },
    spec: {
      en: {
        model: "000-S12HCS",
        shape: "000",
        Top: "Solid Sitka Spruce",
        BackSides: "Solid Flammed Mahogany",
        Neck: pinen,
        Fingerboard: "Rosewood",
        brg: "Rosewood",
        Nut: "Bone",
        Saddle: "Bone",
        TuningMachine: gdcen,
        String: xsen,
        FinishTop: "Natural",
        FinishNeck: "Satin",
        FinishBody: "Gloss"
      },
      zh: {
        model: "000-S12HCS",
        shape: "000 型",
        Top: "西特卡云杉单板",
        BackSides: "桃花芯单板",
        Neck: pinzh,
        Fingerboard: "玫瑰木",
        brg: "玫瑰木",
        Nut: "牛骨",
        Saddle: "牛骨",
        TuningMachine: "金色全封闭带金色钮",
        String: xszh,
        FinishTop: "自然色",
        FinishNeck: "哑光",
        FinishBody: "亮光"
      },
      fr: {
        model: "000-S12HCS",
        shape: "000",
        Top: "Epicéa Sitka Massif",
        BackSides: "Epicéa Flamme Acajou Massif",
        Neck: pinfr,
        Fingerboard: "Palissandre",
        brg: "Palissandre",
        Nut: "Os",
        Saddle: "Os",
        TuningMachine: "Gomans Die Cast Doré avec boutons dorés",
        String: xsen,
        FinishTop: "Nature",
        FinishNeck: "Satiné",
        FinishBody: "Brillant"
      }
    }
  },
  o000_S15HCS: {
    series: "000",
    shape: "000",
    model: "000-S15HCS",
    desc: {
      en: "",
      zh: "",
      fr: ""
    },
    aud: "/aud/000-S15HCS.mp3",
    ply: "Tak Chan",
    sti: "AM G F E",
    pics: {
      nav: "/000-S15HCS/nav.jpg",
      head: "/000-S15HCS/head.jpg",
      bg: "/000-S15HCS/bg.jpg",
      special: [
        "/000-S15HCS/1.jpg",
        "/000-S15HCS/2.jpg",
        "/000-S15HCS/3.jpg",
        "/000-S15HCS/4.jpg",
        "/000-S15HCS/5.jpg",
        "/000-S15HCS/6.jpg",
        "/000-S15HCS/7.jpg",
        "/000-S15HCS/8.jpg",
      ]
    },
    video: {
      zh: "",
      en: ""
    },
    spec: {
      en: {
        model: "000-S15HCS",
        shape: "000",
        Top: "Solid Alpine Moon Spruce",
        BackSides: "Solid Flammed Walnut",
        Neck: pinen,
        Fingerboard: "Ebony",
        brg: "Ebony",
        Nut: "Bone",
        Saddle: "Bone",
        TuningMachine: "Gotoh/Grover Die Cast Gold With Black Buttons",
        String: xsen,
        FinishTop: "Natural",
        FinishNeck: "Satin",
        FinishBody: "Gloss"
      },
      zh: {
        model: "000-S15HCS",
        shape: "000 型",
        Top: "月亮云杉单板",
        BackSides: "强影胡桃木单板",
        Neck: pinzh,
        Fingerboard: "乌木",
        brg: "乌木",
        Nut: "牛骨",
        Saddle: "牛骨",
        TuningMachine: "Gotoh/Grover 金色全封闭黑色钮",
        String: xszh,
        FinishTop: "自然色",
        FinishNeck: "哑光",
        FinishBody: "亮光"
      },
      fr: {
        model: "000-S15HCS",
        shape: "000",
        Top: "Alpine Moon Spruce Massif",
        BackSides: "Solide Flamme Noyer",
        Neck: pinfr,
        Fingerboard: "Ebène",
        brg: "Ebène",
        Nut: "Os",
        Saddle: "Os",
        TuningMachine: "Gotoh/Grover Die Cast Doré avec boutons noirs",
        String: xsen,
        FinishTop: "Nature",
        FinishNeck: "Satiné",
        FinishBody: "Brillant"
      }
    }
  },
  o000_S16HC: {
    series: "000",
    shape: "000",
    model: "000-S16HC",
    desc: {
      en: "",
      zh: "",
      fr: ""
    },
    pics: {
      nav: "/000-S16HC/nav.jpg",
      head: "/000-S16HC/head.jpg",
      bg: "/000-S16HC/bg.jpg",
      special: [
        "/000-S16HC/1.jpg",
        "/000-S16HC/2.jpg",
        "/000-S16HC/3.jpg",
        "/000-S16HC/4.jpg",
        "/000-S16HC/5.jpg",
        "/000-S16HC/6.jpg"
      ]
    },
    video: {
      zh: "",
      en: ""
    },
    spec: {
      en: {
        model: "000-S16HC",
        shape: "000",
        Top: "Solid Sitka Spruce ",
        BackSides: "Solid Padouk",
        Neck: pinen,
        Fingerboard: "Rosewood",
        brg: "Rosewood",
        Nut: "Bone",
        Saddle: "Bone",
        TuningMachine: gdcen,
        String: xsen,
        FinishTop: "Natural",
        FinishNeck: "Satin",
        FinishBody: "Gloss"
      },
      zh: {
        model: "000-S16HC",
        shape: "000 型",
        Top: "西特卡云杉单板 ",
        BackSides: "红花梨单板",
        Neck: pinzh,
        Fingerboard: "玫瑰木",
        brg: "玫瑰木",
        Nut: "牛骨",
        Saddle: "牛骨",
        TuningMachine: "金色全封闭带金色钮",
        String: xszh,
        FinishTop: "自然色",
        FinishNeck: "哑光",
        FinishBody: "亮光"
      },
      fr: {
        model: "000-S16HC",
        shape: "000",
        Top: "Epicéa Sitka Massif ",
        BackSides: "Epicéa Padouk Massif",
        Neck: pinfr,
        Fingerboard: "Palissandre",
        brg: "Palissandre",
        Nut: "Os",
        Saddle: "Os",
        TuningMachine: "Gomans Die Cast Doré avec boutons dorés",
        String: xsen,
        FinishTop: "Nature",
        FinishNeck: "Satiné",
        FinishBody: "Brillant"
      }
    }
  },
  o000_S18: {
    series: "000",
    shape: "000",
    model: "000-S18",
    desc: {
      en: "",
      zh: "",
      fr: ""
    },
    pics: {
      nav: "/000-S18/nav.jpg",
      head: "/000-S18/head.jpg",
      bg: "/000-S18/bg.jpg",
      special: [
        "/000-S18/1.jpg",
        "/000-S18/2.jpg",
        "/000-S18/3.jpg",
        "/000-S18/4.jpg",
        "/000-S18/5.jpg",
      ]
    },
    video: {
      zh: "",
      en: ""
    },
    spec: {
      en: {
        model: "000-S18",
        shape: "000",
        Top: "Solid Alpine Moon Spruce",
        BackSides: "Solid Indian Rosewood",
        Neck: pinen,
        Fingerboard: "Ebony",
        brg: "Ebony",
        Nut: "Bone",
        Saddle: "Bone",
        TuningMachine: "Gomans Die Cast Gold With Black Buttons",
        String: xsen,
        FinishTop: "Natural",
        FinishNeck: "Satin",
        FinishBody: "Gloss"
      },
      zh: {
        model: "000-S18",
        shape: "000 型",
        Top: "月亮云杉单板",
        BackSides: "玫瑰木单板",
        Neck: pinzh,
        Fingerboard: "乌木",
        brg: "乌木",
        Nut: "牛骨",
        Saddle: "牛骨",
        TuningMachine: "金色全封闭黑色钮",
        String: xszh,
        FinishTop: "自然色",
        FinishNeck: "哑光",
        FinishBody: "亮光"
      },
      fr: {
        model: "000-S18",
        shape: "000",
        Top: "Epicéa Alpine Massif",
        BackSides: "Epicéa Palissandre Massif",
        Neck: pinfr,
        Fingerboard: "Ebène",
        brg: "Ebène",
        Nut: "Os",
        Saddle: "Os",
        TuningMachine: "Gomans Die Cast Doré avec boutons noirs",
        String: xsen,
        FinishTop: "Nature",
        FinishNeck: "Satiné",
        FinishBody: "Brillant"
      }
    }
  },
  o000_S18AC: {
    series: "000",
    shape: "000",
    model: "000-S18AC",
    desc: {
      en: "",
      zh: "",
      fr: ""
    },
    aud: "/aud/000-S18AC_G02073.mp3",
    ply: "Tak Chan",
    sti: "Sound Test",
    pics: {
      nav: "/000-S18AC/nav.jpg",
      head: "/000-S18AC/head.jpg",
      bg: "/000-S18AC/bg.jpg",
      special: [
        "/000-S18AC/1.jpg",
        "/000-S18AC/2.jpg",
        "/000-S18AC/3.jpg",
        "/000-S18AC/4.jpg",
        "/000-S18AC/5.jpg",
        "/000-S18AC/6.jpg",
        "/000-S18AC/7.jpg",
      ]
    },
    video: {
      zh: "//player.bilibili.com/player.html?bvid=BV1RY4y1c7E6&autoplay=0",
      en: "https://www.youtube.com/embed/OeZHmipcSpM"
    },
    spec: {
      en: {
        model: "000-S18AC",
        shape: "000",
        Top: "Solid Alpine Moon Spruce",
        BackSides: "Solid Indian Rosewood",
        Neck: pinen,
        Fingerboard: "Ebony",
        brg: "Ebony",
        Nut: "Bone",
        Saddle: "Bone",
        TuningMachine: "Gomans Die Cast Gold With Black Buttons",
        String: xsen,
        FinishTop: "Natural",
        FinishNeck: "Satin",
        FinishBody: "Gloss"
      },
      zh: {
        model: "000-S18AC",
        shape: "000 型",
        Top: "月亮云杉单板",
        BackSides: "玫瑰木单板",
        Neck: pinzh,
        Fingerboard: "乌木",
        brg: "乌木",
        Nut: "牛骨",
        Saddle: "牛骨",
        TuningMachine: "金色全封闭黑色钮",
        String: xszh,
        FinishTop: "自然色",
        FinishNeck: "哑光",
        FinishBody: "亮光"
      },
      fr: {
        model: "000-S18AC",
        shape: "000",
        Top: "Epicéa Alpine Massif",
        BackSides: "Epicéa Palissandre Massif",
        Neck: pinfr,
        Fingerboard: "Ebène",
        brg: "Ebène",
        Nut: "Os",
        Saddle: "Os",
        TuningMachine: "Gomans Die Cast Doré avec boutons noirs",
        String: xsen,
        FinishTop: "Nature",
        FinishNeck: "Satiné",
        FinishBody: "Brillant"
      }
    }
  },
  o000_S18ACS: {
    series: "000",
    shape: "000",
    model: "000-S18ACS",
    desc: {
      en: "",
      zh: "",
      fr: ""
    },
    aud: "/aud/000-S18AC_G02073.mp3",
    ply: "Tak Chan",
    sti: "Sound Test",
    pics: {
      nav: "/000-S18ACS/nav.jpg",
      head: "/000-S18ACS/head.jpg",
      bg: "/000-S18ACS/bg.jpg",
      special: [
        "/000-S18ACS/1.jpg",
        "/000-S18ACS/2.jpg",
        "/000-S18ACS/3.jpg",
        "/000-S18ACS/4.jpg"
      ]
    },
    video: {
      zh: "//player.bilibili.com/player.html?bvid=BV1RY4y1c7E6&autoplay=0",
      en: ""
    },
    spec: {
      en: {
        model: "000-S18ACS",
        shape: "000",
        Top: "Solid Alpine Moon Spruce",
        BackSides: "Solid Indian Rosewood",
        Neck: pinen,
        Fingerboard: "Ebony",
        brg: "Ebony",
        Nut: "Bone",
        Saddle: "Bone",
        TuningMachine: "Gomans Die Cast Gold With Black Buttons",
        String: xsen,
        FinishTop: "Natural",
        FinishNeck: "Satin",
        FinishBody: "Gloss"
      },
      zh: {
        model: "000-S18ACS",
        shape: "000 型",
        Top: "月亮云杉单板",
        BackSides: "玫瑰木单板",
        Neck: pinzh,
        Fingerboard: "乌木",
        brg: "乌木",
        Nut: "牛骨",
        Saddle: "牛骨",
        TuningMachine: "金色全封闭黑色钮",
        String: xszh,
        FinishTop: "自然色",
        FinishNeck: "哑光",
        FinishBody: "亮光"
      },
      fr: {
        model: "000-S18ACS",
        shape: "000",
        Top: "Epicéa Alpine Massif",
        BackSides: "Epicéa Palissandre Massif",
        Neck: pinfr,
        Fingerboard: "Ebène",
        brg: "Ebène",
        Nut: "Os",
        Saddle: "Os",
        TuningMachine: "Gomans Die Cast Doré avec boutons noirs",
        String: xsen,
        FinishTop: "Nature",
        FinishNeck: "Satiné",
        FinishBody: "Brillant"
      }
    }
  },
  o000_S18AC_DLX: {
    series: "DLX",
    shape: "000",
    model: "000-S18AC DLX",
    desc: {
      en: "",
      zh: "",
      fr: ""
    },
    pics: {
      nav: "/000-S18AC_DLX/nav.jpg",
      head: "/000-S18AC_DLX/head.jpg",
      bg: "/000-S18AC_DLX/bg.jpg",
      special: [
        "/000-S18AC_DLX/1.jpg",
        "/000-S18AC_DLX/2.jpg",
        "/000-S18AC_DLX/3.jpg",
        "/000-S18AC_DLX/4.jpg",
        "/000-S18AC_DLX/5.jpg",
        "/000-S18AC_DLX/6.jpg"
      ]
    },
    video: {
      zh: "",
      en: ""
    },
    spec: {
      en: {
        model: "000-S18AC DLX",
        shape: "000",
        Top: "Solid Red Cedar",
        BackSides: "Solid Indian Rosewood",
        Neck: pinen,
        Fingerboard: "Ebony",
        brg: "Ebony",
        Nut: "Bone",
        Saddle: "Bone",
        TuningMachine: "Gotoh/Grover Die Cast Gold With Black Button",
        String: xsen,
        FinishTop: "Natural",
        FinishNeck: "Satin",
        FinishBody: "Gloss"
      },
      zh: {
        model: "000-S18AC DLX",
        shape: "000 型",
        Top: "红松单板",
        BackSides: "玫瑰木单板",
        Neck: pinzh,
        Fingerboard: "乌木",
        brg: "乌木",
        Nut: "牛骨",
        Saddle: "牛骨",
        TuningMachine: "Gotoh/Grover 金色全封闭带黑色钮",
        String: xszh,
        FinishTop: "自然色",
        FinishNeck: "哑光",
        FinishBody: "亮光"
      },
      fr: {
        model: "000-S18AC DLX",
        shape: "000",
        Top: "Cèdre Rouge Massif",
        BackSides: "Epicéa Palissandre Massif",
        Neck: pinfr,
        Fingerboard: "Ebène",
        brg: "Ebène",
        Nut: "Os",
        Saddle: "Os",
        TuningMachine: "Gotoh/Grover Die Cast Doré avec boutons noirs",
        String: xsen,
        FinishTop: "Nature",
        FinishNeck: "Satiné",
        FinishBody: "Brillant"
      }
    }
  },
  o000_S19: {
    series: "000",
    shape: "000",
    model: "000-S19",
    desc: {
      en: "",
      zh: "",
      fr: ""
    },
    pics: {
      nav: "/000-S19/nav.jpg",
      head: "/000-S19/head.jpg",
      bg: "/000-S19/bg.jpg",
      special: [
        "/000-S19/1.jpg",
        "/000-S19/2.jpg",
        "/000-S19/3.jpg",
        "/000-S19/4.jpg",
        "/000-S19/5.jpg",
        "/000-S19/6.jpg",
        "/000-S19/7.jpg",
      ]
    },
    video: {
      zh: "//player.bilibili.com/player.html?bvid=BV1AY4y1w7uu&autoplay=0",
      en: ""
    },
    spec: {
      en: {
        model: "000-S19",
        shape: "000",
        Top: "Solid Red Cedar",
        BackSides: "Solid Acacia Koa",
        Neck: pinen,
        Fingerboard: "Ebony",
        brg: "Ebony",
        Nut: "Bone",
        Saddle: "Bone",
        TuningMachine: "Gomans Grover Gold With Butterbean Knobs",
        String: xsen,
        FinishTop: "Satin",
        FinishNeck: "Satin",
        FinishBody: "Satin"
      },
      zh: {
        model: "000-S19",
        shape: "000 型",
        Top: "红松单板",
        BackSides: "相思木单板",
        Neck: pinzh,
        Fingerboard: "乌木",
        brg: "乌木",
        Nut: "牛骨",
        Saddle: "牛骨",
        TuningMachine: "金色弦准",
        String: xszh,
        FinishTop: "哑光",
        FinishNeck: "哑光",
        FinishBody: "哑光"
      },
      fr: {
        model: "000-S19",
        shape: "000",
        Top: "Cèdre Rouge Massif",
        BackSides: "Acacia Koa Massif",
        Neck: pinfr,
        Fingerboard: "Ebène",
        brg: "Ebène",
        Nut: "Os",
        Saddle: "Os",
        TuningMachine: "Gomans Grover Doré avec boutons Butterbean",
        String: xsen,
        FinishTop: "Satiné",
        FinishNeck: "Satiné",
        FinishBody: "Satiné"
      }
    }
  },
  o000_SA21HCS: {
    series: "000",
    shape: "000",
    model: "000-SA21HCS",
    desc: {
      en: "",
      zh: "",
      fr: ""
    },
    pics: {
      nav: "/000-SA21HCS/nav.jpg",
      head: "/000-SA21HCS/head.jpg",
      bg: "/000-SA21HCS/bg.jpg",
      special: [
        "/000-SA21HCS/1.jpg",
        "/000-SA21HCS/2.jpg",
        "/000-SA21HCS/3.jpg",
        "/000-SA21HCS/4.jpg",
        "/000-SA21HCS/5.jpg",
        "/000-SA21HCS/6.jpg",
        "/000-SA21HCS/7.jpg",
      ]
    },
    video: {
      zh: "",
      en: ""
    },
    spec: {
      en: {
        model: "000-SA21HCS",
        shape: "000",
        Top: "Solid Flammed Maple",
        BackSides: "Solid Flammed Maple",
        Neck: "5 Pieces Redwood/Maple",
        Fingerboard: "Maple",
        brg: "Solid Padouk",
        Nut: "Bone",
        Saddle: "Bone",
        TuningMachine: "Gotoh/Grover Die Cast Gold With Black Buttons",
        String: xsen,
        FinishTop: "Natural",
        FinishNeck: "Satin",
        FinishBody: "Gloss"
      },
      zh: {
        model: "000-SA21HCS",
        shape: "000 型",
        Top: "强影枫木单板",
        BackSides: "强影枫木单板",
        Neck: "五拼一体 红木/枫木",
        Fingerboard: "枫木",
        brg: "红花梨木",
        Nut: "牛骨",
        Saddle: "牛骨",
        TuningMachine: "Gotoh/Grover 金色全封闭黑色钮",
        String: xszh,
        FinishTop: "自然色",
        FinishNeck: "哑光",
        FinishBody: "亮光"
      },
      fr: {
        model: "000-SA21HCS",
        shape: "000",
        Top: "Solide Flamme Erable",
        BackSides: "Solide Flamme Erable",
        Neck: "5 piéces Acajou/Erable",
        Fingerboard: "Erable",
        brg: "Padouk Massif",
        Nut: "Os",
        Saddle: "Os",
        TuningMachine: "Gotoh/Grover Die Cast Doré avec boutons noirs",
        String: xsen,
        FinishTop: "Nature",
        FinishNeck: "Satiné",
        FinishBody: "Brillant"
      }
    }
  },
  o000_SA21HCSB: {
    series: "000",
    shape: "000",
    model: "000-SA21HCSB",
    desc: {
      en: "",
      zh: "",
      fr: ""
    },
    aud: "/aud/000 SA21HCB   you got a nerve.mp3",
    ply: "Tak Chan",
    sti: "You Got a Nerve Cover",
    pics: {
      nav: "/000-SA21HCSB/nav.jpg",
      head: "/000-SA21HCSB/head.jpg",
      bg: "/000-SA21HCSB/bg.jpg",
      special: [
        "/000-SA21HCSB/1.jpg",
        "/000-SA21HCSB/2.jpg",
        "/000-SA21HCSB/3.jpg",
        "/000-SA21HCSB/4.jpg",
        "/000-SA21HCSB/5.jpg",
        "/000-SA21HCSB/6.jpg",
      ]
    },
    video: {
      zh: "",
      en: ""
    },
    spec: {
      en: {
        model: "000-SA21HCSB",
        shape: "000",
        Top: "Solid Flammed Maple",
        BackSides: "Solid Flammed Maple",
        Neck: "5 Pieces Mahogany/Maple",
        Fingerboard: "Maple",
        brg: "Solid Padouk",
        Nut: "Bone",
        Saddle: "Bone",
        TuningMachine: "Gotoh/Grover Die Cast Gold With Black Buttons",
        String: xsen,
        FinishTop: "Natural",
        FinishNeck: "Satin",
        FinishBody: "Gloss"
      },
      zh: {
        model: "000-SA21HCSB",
        shape: "000 型",
        Top: "强影枫木单板",
        BackSides: "强影枫木单板",
        Neck: "五拼一体 桃花芯/枫木",
        Fingerboard: "枫木",
        brg: "红花梨木",
        Nut: "牛骨",
        Saddle: "牛骨",
        TuningMachine: "Gotoh/Grover 金色全封闭黑色钮",
        String: xszh,
        FinishTop: "自然色",
        FinishNeck: "哑光",
        FinishBody: "亮光"
      },
      fr: {
        model: "000-SA21HCSB",
        shape: "000",
        Top: "Solide Flamme Erable",
        BackSides: "Solide Flamme Erable",
        Neck: "5 piéces Acajou/Erable",
        Fingerboard: "Erable",
        brg: "Padouk Massif",
        Nut: "Os",
        Saddle: "Os",
        TuningMachine: "Gotoh/Grover Die Cast Doré avec boutons noirs",
        String: xsen,
        FinishTop: "Nature",
        FinishNeck: "Satiné",
        FinishBody: "Brillant"
      }
    }
  },
  o000_S22AC: {
    series: "000",
    shape: "000",
    model: "000-S22AC",
    desc: {
      en: "",
      zh: "",
      fr: ""
    },
    pics: {
      nav: "/000-S22AC/nav.jpg",
      head: "/000-S22AC/head.jpg",
      bg: "/000-S22AC/bg.jpg",
      special: [
        "/000-S22AC/1.jpg",
        "/000-S22AC/2.jpg",
        "/000-S22AC/3.jpg",
        "/000-S22AC/4.jpg",
        "/000-S22AC/5.jpg",
        "/000-S22AC/6.jpg"
      ]
    },
    video: {
      zh: "//player.bilibili.com/player.html?bvid=BV14d4y1P7Go&autoplay=0",
      en: ""
    },
    spec: {
      en: {
        model: "000-S22AC",
        shape: "000",
        Top: "Solid Alpine Moon Spruce",
        BackSides: "Solid Santos Rosewood",
        Neck: pinen,
        Fingerboard: "Ebony",
        brg: "Ebony",
        Nut: "Bone",
        Saddle: "Bone",
        TuningMachine: "Gomans Die Cast Gold With Black Buttons",
        String: xsen,
        FinishTop: "Natural",
        FinishNeck: "Satin",
        FinishBody: "Gloss"
      },
      zh: {
        model: "000-S22AC",
        shape: "000 型",
        Top: "月亮云杉单板",
        BackSides: "桑托斯玫瑰木单板",
        Neck: pinzh,
        Fingerboard: "乌木",
        brg: "乌木",
        Nut: "牛骨",
        Saddle: "牛骨",
        TuningMachine: "金色全封闭黑色钮",
        String: xszh,
        FinishTop: "自然色",
        FinishNeck: "哑光",
        FinishBody: "亮光"
      },
      fr: {
        model: "000-S22AC",
        shape: "000",
        Top: "Epicéa Alpine Massif",
        BackSides: "Epicéa Palissandre De Santos Massif",
        Neck: pinfr,
        Fingerboard: "Ebène",
        brg: "Ebène",
        Nut: "Os",
        Saddle: "Os",
        TuningMachine: "Gomans Die Cast Doré avec boutons noirs",
        String: xsen,
        FinishTop: "Nature",
        FinishNeck: "Satiné",
        FinishBody: "Brillant"
      }
    }
  },
  o000_S23HC: {
    series: "000",
    shape: "000",
    model: "000-S23HC",
    desc: {
      en: "",
      zh: "",
      fr: ""
    },
    aud: "/aud/000-S23HC SOUND.mp3",
    ply: "Tak Chan",
    sti: "Improvasation",
    pics: {
      nav: "/000-S23HC/nav.jpg",
      head: "/000-S23HC/head.jpg",
      bg: "/000-S23HC/bg.jpg",
      special: [
        "/000-S23HC/1.jpg",
        "/000-S23HC/2.jpg",
        "/000-S23HC/3.jpg",
        "/000-S23HC/4.jpg",
        "/000-S23HC/5.jpg",
        "/000-S23HC/6.jpg",
        "/000-S23HC/7.jpg",
      ]
    },
    video: {
      zh: "//player.bilibili.com/player.html?bvid=BV1wd4y1Z7SB&autoplay=0",
      en: "https://www.youtube.com/embed/THQkq_lcj7s"
    },
    spec: {
      en: {
        model: "000-S23HC",
        shape: "000",
        Top: "Solid Sitka Spruce",
        BackSides: "Solid Rosewood+Solid Maple",
        Neck: pinen,
        Fingerboard: "Ebony",
        brg: "Ebony",
        Nut: "Bone",
        Saddle: "Bone",
        TuningMachine: "Gotoh/Grover Die Cast Gold With Gold Buttons",
        String: xsen,
        FinishTop: "Natural",
        FinishNeck: "Satin",
        FinishBody: "Gloss"
      },
      zh: {
        model: "000-S23HC",
        shape: "000 型",
        Top: "西特卡云杉单板",
        BackSides: "玫瑰木单板+枫木单板",
        Neck: pinzh,
        Fingerboard: "乌木",
        brg: "乌木",
        Nut: "牛骨",
        Saddle: "牛骨",
        TuningMachine: "Gotoh/Grover 金色全封闭",
        String: xszh,
        FinishTop: "自然色",
        FinishNeck: "哑光",
        FinishBody: "亮光"
      },
      fr: {
        model: "000-S23HC",
        shape: "000",
        Top: "Epicéa Sitka Massif",
        BackSides: "Epicéa Palissandre Massif+ Erable Massif",
        Neck: pinfr,
        Fingerboard: "Ebène",
        brg: "Ebène",
        Nut: "Os",
        Saddle: "Os",
        TuningMachine: "Gotoh/Grover Die Cast Doré avec boutons dorés",
        String: xsen,
        FinishTop: "Nature",
        FinishNeck: "Satiné",
        FinishBody: "Brillant"
      }
    }
  },
  AH12_S12HC: {
    series: "AH12",
    shape: "AH",
    model: "AH12-S12HC",
    desc: {
      en: "",
      zh: "",
      fr: ""
    },
    pics: {
      nav: "/AH12-S12HC/nav.jpg",
      head: "/AH12-S12HC/head.jpg",
      bg: "/AH12-S12HC/bg.jpg",
      special: [
        "/AH12-S12HC/1.jpg",
        "/AH12-S12HC/2.jpg",
        "/AH12-S12HC/3.jpg",
        "/AH12-S12HC/4.jpg",
        "/AH12-S12HC/5.jpg",
        "/AH12-S12HC/6.jpg"
      ]
    },
    video: {
      zh: "",
      en: ""
    },
    spec: {
      en: {
        model: "AH12-S12HC",
        shape: "AH",
        Top: "Solid Sitka Spruce",
        BackSides: "Solid Mahogany",
        Neck: pinen,
        Fingerboard: "Indian Rosewood",
        brg: "Indian Rosewood",
        Nut: "Bone",
        Saddle: "Bone",
        TuningMachine: "Grover Gold With Butterbean Knobs",
        String: "D'Addario EXP38",
        FinishTop: "Natural",
        FinishNeck: "Satin",
        FinishBody: "Gloss"
      },
      zh: {
        model: "AH12-S12HC",
        shape: "AH 型",
        Top: "西特卡云杉单板",
        BackSides: "桃花芯单板",
        Neck: pinzh,
        Fingerboard: "玫瑰木",
        brg: "玫瑰木",
        Nut: "牛骨",
        Saddle: "牛骨",
        TuningMachine: "金色弦准",
        String: "达达里奥 EXP38",
        FinishTop: "自然色",
        FinishNeck: "哑光",
        FinishBody: "亮光"
      },
      fr: {
        model: "AH12-S12HC",
        shape: "AH",
        Top: "Epicéa Sitka Massif",
        BackSides: "Epicéa Acajou Massif",
        Neck: pinfr,
        Fingerboard: "Palissandre Indien",
        brg: "Palissandre Indien",
        Nut: "Os",
        Saddle: "Os",
        TuningMachine: "Grover Doré avec boutons Butterbean",
        String: "D'Addario EXP38",
        FinishTop: "Nature",
        FinishNeck: "Satiné",
        FinishBody: "Brillant"
      }
    }
  },
  AH12_S18: {
    series: "AH12",
    shape: "AH",
    model: "AH12-S18",
    desc: {
      en: "",
      zh: "",
      fr: ""
    },
    aud: "/aud/AH12-S18 hotel california COVER.mp3",
    ply: "COVER",
    sti: "Hotel California",
    pics: {
      nav: "/AH12-S18/nav.jpg",
      head: "/AH12-S18/head.jpg",
      bg: "/AH12-S18/bg.jpg",
      special: [
        "/AH12-S18/1.jpg",
        "/AH12-S18/2.jpg",
        "/AH12-S18/3.jpg",
        "/AH12-S18/4.jpg",
        "/AH12-S18/5.jpg",
      ]
    },
    video: {
      zh: "",
      en: ""
    },
    spec: {
      en: {
        model: "AH12-S18",
        shape: "AH",
        Top: "Solid Red Cedar",
        BackSides: "Solid Indian Rosewood",
        Neck: pinen,
        Fingerboard: "Ebony",
        brg: "Ebony",
        Nut: "Bone",
        Saddle: "Bone",
        TuningMachine: "Grover Gold With Butterbean Knobs",
        String: "D'Addario EXP38",
        FinishTop: "Natural",
        FinishNeck: "Satin",
        FinishBody: "Gloss"
      },
      zh: {
        model: "AH12-S18",
        shape: "AH 型",
        Top: "红松单板",
        BackSides: "玫瑰木单板",
        Neck: pinzh,
        Fingerboard: "乌木",
        brg: "乌木",
        Nut: "牛骨",
        Saddle: "牛骨",
        TuningMachine: "金色弦准",
        String: "达达里奥 EXP38",
        FinishTop: "自然色",
        FinishNeck: "哑光",
        FinishBody: "亮光"
      },
      fr: {
        model: "AH12-S18",
        shape: "AH",
        Top: "Cèdre Rouge Massif",
        BackSides: "Epicéa Palissandre Massif",
        Neck: pinfr,
        Fingerboard: "Ebène",
        brg: "Ebène",
        Nut: "Os",
        Saddle: "Os",
        TuningMachine: "Grover Doré avec boutons Butterbean",
        String: "D'Addario EXP38",
        FinishTop: "Nature",
        FinishNeck: "Satiné",
        FinishBody: "Brillant"
      }
    }
  },
  AH_S18: {
    series: "AH",
    shape: "AH",
    model: "AH-S18",
    desc: {
      en: "",
      zh: "",
      fr: ""
    },
    aud: "/aud/AH-S18 GHISLAINE 'S SONG.mp3",
    ply: "Tak Chan",
    sti: "GHISLAINE'S SONG",
    pics: {
      nav: "/AH-S18/nav.jpg",
      head: "/AH-S18/head.jpg",
      bg: "/AH-S18/bg.jpg",
      special: [
        "/AH-S18/1.jpg",
        "/AH-S18/2.jpg",
        "/AH-S18/3.jpg",
        "/AH-S18/4.jpg",
        "/AH-S18/5.jpg",
        "/AH-S18/6.jpg"
      ]
    },
    video: {
      zh: "",
      en: ""
    },
    spec: {
      en: {
        model: "AH-S18",
        shape: "AH",
        Top: "Solid Red Cedar",
        BackSides: "Solid Indian Rosewood",
        Neck: pinen,
        Fingerboard: "Ebony",
        brg: "Ebony",
        Nut: "Bone",
        Saddle: "Bone",
        TuningMachine: "Grover Gold With Butterbean Knobs",
        String: xsen,
        FinishTop: "Natural",
        FinishNeck: "Satin",
        FinishBody: "Gloss"
      },
      zh: {
        model: "AH-S18",
        shape: "AH 型",
        Top: "红松单板",
        BackSides: "玫瑰木单板",
        Neck: pinzh,
        Fingerboard: "乌木",
        brg: "乌木",
        Nut: "牛骨",
        Saddle: "牛骨",
        TuningMachine: "金色弦准",
        String: xszh,
        FinishTop: "自然色",
        FinishNeck: "哑光",
        FinishBody: "亮光"
      },
      fr: {
        model: "AH-S18",
        shape: "AH",
        Top: "Cèdre Rouge Massif",
        BackSides: "Epicéa Palissandre Massif",
        Neck: pinfr,
        Fingerboard: "Ebène",
        brg: "Ebène",
        Nut: "Os",
        Saddle: "Os",
        TuningMachine: "Grover Doré avec boutons Butterbean",
        String: xsen,
        FinishTop: "Nature",
        FinishNeck: "Satiné",
        FinishBody: "Brillant"
      }
    }
  },
  AH_S20: {
    series: "AH",
    shape: "AH",
    model: "AH-S20",
    desc: {
      en: "",
      zh: "",
      fr: ""
    },
    pics: {
      nav: "/AH-S20/nav.jpg",
      head: "/AH-S20/head.jpg",
      bg: "/AH-S20/bg.jpg",
      special: [
        "/AH-S20/1.jpg",
        "/AH-S20/2.jpg",
        "/AH-S20/3.jpg",
        "/AH-S20/4.jpg",
      ]
    },
    video: {
      zh: "",
      en: ""
    },
    spec: {
      en: {
        model: "AH-S20",
        shape: "AH",
        Top: "Solid Sitka Spruce",
        BackSides: "Solid Purpleheart",
        Neck: pinen,
        Fingerboard: "Ebony",
        brg: "Ebony",
        Nut: "Bone",
        Saddle: "Bone",
        TuningMachine: "Grover Gold With Butterbean Knobs",
        String: xsen,
        FinishTop: "Natural",
        FinishNeck: "Satin",
        FinishBody: "Gloss"
      },
      zh: {
        model: "AH-S20",
        shape: "AH 型",
        Top: "西特卡云杉单板",
        BackSides: "紫心苏木单板",
        Neck: pinzh,
        Fingerboard: "乌木",
        brg: "乌木",
        Nut: "牛骨",
        Saddle: "牛骨",
        TuningMachine: "金色弦准",
        String: xszh,
        FinishTop: "自然色",
        FinishNeck: "哑光",
        FinishBody: "亮光"
      },
      fr: {
        model: "AH-S20",
        shape: "AH",
        Top: "Epicéa Sitka Massif",
        BackSides: "Solid Purpleheart",
        Neck: pinfr,
        Fingerboard: "Ebène",
        brg: "Ebène",
        Nut: "Os",
        Saddle: "Os",
        TuningMachine: "Grover Doré avec boutons Butterbean",
        String: xsen,
        FinishTop: "Nature",
        FinishNeck: "Satiné",
        FinishBody: "Brillant"
      }
    }
  },
  AH_S21: {
    series: "AH",
    shape: "AH",
    model: "AH-S21",
    desc: {
      en: "",
      zh: "",
      fr: ""
    },
    pics: {
      nav: "/AH-S21/nav.jpg",
      head: "/AH-S21/head.jpg",
      bg: "/AH-S21/bg.jpg",
      special: [
        "/AH-S21/1.jpg",
        "/AH-S21/2.jpg",
        "/AH-S21/3.jpg",
        "/AH-S21/4.jpg",
      ]
    },
    video: {
      zh: "",
      en: ""
    },
    spec: {
      en: {
        model: "AH-S21",
        shape: "AH",
        Top: "Solid Alpine Moon Spruce ",
        BackSides: "Solid Maple",
        Neck: "5 Pieces Maple/Rosewood",
        Fingerboard: "Ebony",
        brg: "Ebony",
        Nut: "Bone",
        Saddle: "Bone",
        TuningMachine: "Grover Gold With Butterbean Knobs",
        String: xsen,
        FinishTop: "Natural",
        FinishNeck: "Satin",
        FinishBody: "Gloss"
      },
      zh: {
        model: "AH-S21",
        shape: "AH 型",
        Top: "月亮云杉单板 ",
        BackSides: "枫木单板",
        Neck: "五拼一体 枫木/玫瑰木",
        Fingerboard: "乌木",
        brg: "乌木",
        Nut: "牛骨",
        Saddle: "牛骨",
        TuningMachine: "金色弦准",
        String: xszh,
        FinishTop: "自然色",
        FinishNeck: "哑光",
        FinishBody: "亮光"
      },
      fr: {
        model: "AH-S21",
        shape: "AH",
        Top: "Epicéa Alpine Massif ",
        BackSides: "Erable Massif",
        Neck: "5 piéces Erable/Palissandre",
        Fingerboard: "Ebène",
        brg: "Ebène",
        Nut: "Os",
        Saddle: "Os",
        TuningMachine: "Grover Doré avec boutons Butterbean",
        String: xsen,
        FinishTop: "Nature",
        FinishNeck: "Satiné",
        FinishBody: "Brillant"
      }
    }
  },
  AH_SMG: {
    series: "AH",
    shape: "AH",
    model: "AH-SMG",
    desc: {
      en: "",
      zh: "全单芒果木吉他，音色亦如热带岛屿般温暖\n清醇暖音  邀君共听",
      fr: ""
    },
    aud: "/aud/AH-SMG AFTER FIVE YEAR.mp3",
    ply: "Tak Chan",
    sti: "AFTER FIVE YEARS",
    pics: {
      nav: "/AH-SMG/nav.jpg",
      head: "/AH-SMG/head.jpg",
      bg: "/AH-SMG/bg.jpg",
      special: [
        "/AH-SMG/1.jpg",
        "/AH-SMG/2.jpg",
        "/AH-SMG/3.jpg",
        "/AH-SMG/4.jpg",
      ]
    },
    video: {
      zh: "",
      en: ""
    },
    spec: {
      en: {
        model: "AH-SMG",
        shape: "AH",
        Top: "Solid Red Cedar",
        BackSides: "Solid Mango Wood",
        Neck: pinen,
        Fingerboard: "Ebony",
        brg: "Ebony",
        Nut: "Bone",
        Saddle: "Bone",
        TuningMachine: "Grover Gold With Butterbean Knobs",
        String: xsen,
        FinishTop: "Natural",
        FinishNeck: "Satin",
        FinishBody: "Gloss"
      },
      zh: {
        model: "AH-SMG",
        shape: "AH 型",
        Top: "红松单板",
        BackSides: "芒果木单板",
        Neck: pinzh,
        Fingerboard: "乌木",
        brg: "乌木",
        Nut: "牛骨",
        Saddle: "牛骨",
        TuningMachine: "金色弦准",
        String: xszh,
        FinishTop: "自然色",
        FinishNeck: "哑光",
        FinishBody: "亮光"
      },
      fr: {
        model: "AH-SMG",
        shape: "AH",
        Top: "Cèdre rouge Massif",
        BackSides: "Bois de mangue",
        Neck: pinfr,
        Fingerboard: "Ebène",
        brg: "Ebène",
        Nut: "Os",
        Saddle: "Os",
        TuningMachine: "Grover Doré avec boutons Butterbean",
        String: xsen,
        FinishTop: "Nature",
        FinishNeck: "Satiné",
        FinishBody: "Brillant"
      }
    }
  },
  BJ_S18DC_DLX: {
    series: "DLX",
    shape: "BJ",
    model: "BJ-S18DC DLX",
    desc: {
      en: "",
      zh: "",
      fr: ""
    },
    pics: {
      nav: "/BJ-S18DC_DLX/nav.jpg",
      head: "/BJ-S18DC_DLX/head.jpg",
      bg: "/BJ-S18DC_DLX/bg.jpg",
      special: [
        "/BJ-S18DC_DLX/1.jpg",
        "/BJ-S18DC_DLX/2.jpg",
        "/BJ-S18DC_DLX/3.jpg",
        "/BJ-S18DC_DLX/4.jpg",
        "/BJ-S18DC_DLX/5.jpg",
        "/BJ-S18DC_DLX/6.jpg"
      ]
    },
    video: {
      zh: "",
      en: ""
    },
    spec: {
      en: {
        model: "BJ-S18DC DLX",
        shape: "BJ",
        Top: "Solid Alpine Moon Spruce",
        BackSides: "Solid Indian Rosewood",
        Neck: pinen,
        Fingerboard: "Ebony",
        brg: "Ebony",
        Nut: "Bone",
        Saddle: "Bone",
        TuningMachine: "Gotoh/Grover Die Cast Gold With Black Button",
        String: xsen,
        FinishTop: "Natural",
        FinishNeck: "Satin",
        FinishBody: "Gloss"
      },
      zh: {
        model: "BJ-S18DC DLX",
        shape: "BJ 型",
        Top: "月亮云杉单板",
        BackSides: "玫瑰木单板",
        Neck: pinzh,
        Fingerboard: "乌木",
        brg: "乌木",
        Nut: "牛骨",
        Saddle: "牛骨",
        TuningMachine: "Gotoh/Grover 金色全封闭带黑色钮",
        String: xszh,
        FinishTop: "自然色",
        FinishNeck: "哑光",
        FinishBody: "亮光"
      },
      fr: {
        model: "BJ-S18DC DLX",
        shape: "BJ",
        Top: "Alpine Moon Spruce Massif",
        BackSides: "Epicéa Palissandre Massif",
        Neck: pinfr,
        Fingerboard: "Ebène",
        brg: "Ebène",
        Nut: "Os",
        Saddle: "Os",
        TuningMachine: "Gotoh/Grover Die Cast Doré avec boutons noirs",
        String: xsen,
        FinishTop: "Nature",
        FinishNeck: "Satiné",
        FinishBody: "Brillant"
      }
    }
  },
  BJ_S19FDC: {
    series: "BJ",
    shape: "BJ",
    model: "BJ-S19FDC",
    desc: {
      en: "",
      zh: "",
      fr: ""
    },
    aud: "/aud/BJ-S19FDC.mp3",
    ply: "Tak Chan",
    sti: "Improvasation",
    pics: {
      nav: "/BJ-S19FDC/nav.jpg",
      head: "/BJ-S19FDC/head.jpg",
      bg: "/BJ-S19FDC/bg.jpg",
      special: [
        "/BJ-S19FDC/1.jpg",
        "/BJ-S19FDC/2.jpg",
        "/BJ-S19FDC/3.jpg",
        "/BJ-S19FDC/4.jpg",
        "/BJ-S19FDC/5.jpg",
        "/BJ-S19FDC/6.jpg",
      ]
    },
    video: {
      zh: "//player.bilibili.com/player.html?bvid=BV1rN4y1G7sv&autoplay=0",
      en: "https://www.youtube.com/embed/CGBStu_vFw0"
    },
    spec: {
      en: {
        model: "BJ-S19FDC",
        shape: "BJ",
        Top: "Solid Red Cedar",
        BackSides: "Solid Acacia Koa",
        Neck: pinen,
        Fingerboard: "Ebony",
        brg: "Ebony",
        Nut: "Bone",
        Saddle: "Bone",
        TuningMachine: "Gotoh/Grover Die Cast Gold With Gold Buttons",
        String: xsen,
        FinishTop: "Natural",
        FinishNeck: "Satin",
        FinishBody: "Gloss"
      },
      zh: {
        model: "BJ-S19FDC",
        shape: "BJ 型",
        Top: "红松单板",
        BackSides: "相思木单板",
        Neck: pinzh,
        Fingerboard: "乌木",
        brg: "乌木",
        Nut: "牛骨",
        Saddle: "牛骨",
        TuningMachine: "Gotoh/Grover 金色全封闭",
        String: xszh,
        FinishTop: "自然色",
        FinishNeck: "哑光",
        FinishBody: "亮光"
      },
      fr: {
        model: "BJ-S19FDC",
        shape: "BJ",
        Top: "Cèdre Rouge Massif",
        BackSides: "Acacia Koa Massif",
        Neck: pinfr,
        Fingerboard: "Ebène",
        brg: "Ebène",
        Nut: "Os",
        Saddle: "Os",
        TuningMachine: "Gotoh/Grover Die Cast Doré avec boutons dorés",
        String: xsen,
        FinishTop: "Nature",
        FinishNeck: "Satiné",
        FinishBody: "Brillant"
      }
    }
  },
  BJ_S21DC_DLX: {
    series: "DLX",
    shape: "BJ",
    model: "BJ-S21DC DLX",
    desc: {
      en: "",
      zh: "",
      fr: ""
    },
    pics: {
      nav: "/BJ-S21DC_DLX/nav.jpg",
      head: "/BJ-S21DC_DLX/head.jpg",
      bg: "/BJ-S21DC_DLX/bg.jpg",
      special: [
        "/BJ-S21DC_DLX/1.jpg",
        "/BJ-S21DC_DLX/2.jpg",
        "/BJ-S21DC_DLX/3.jpg",
        "/BJ-S21DC_DLX/4.jpg",
        "/BJ-S21DC_DLX/5.jpg",
        "/BJ-S21DC_DLX/6.jpg"
      ]
    },
    video: {
      zh: "",
      en: ""
    },
    spec: {
      en: {
        model: "BJ-S21DC DLX",
        shape: "BJ",
        Top: "Solid Alpine Moon Spruce ",
        BackSides: "Solid Maple",
        Neck: "5 Pieces Maple/Rosewood",
        Fingerboard: "Ebony",
        brg: "Ebony",
        Nut: "Bone",
        Saddle: "Bone",
        TuningMachine: "Gotoh/Grover Die Cast Gold With Black Buttons",
        String: xsen,
        FinishTop: "Natural",
        FinishNeck: "Satin",
        FinishBody: "Gloss"
      },
      zh: {
        model: "BJ-S21DC DLX",
        shape: "BJ 型",
        Top: "月亮云杉单板 ",
        BackSides: "枫木单板",
        Neck: "五拼一体 枫木/玫瑰木",
        Fingerboard: "乌木",
        brg: "乌木",
        Nut: "牛骨",
        Saddle: "牛骨",
        TuningMachine: "Gotoh/Grover 金色全封闭带黑色钮",
        String: xszh,
        FinishTop: "自然色",
        FinishNeck: "哑光",
        FinishBody: "亮光"
      },
      fr: {
        model: "BJ-S21DC DLX",
        shape: "BJ",
        Top: "Epicéa Alpine Massif ",
        BackSides: "Erable Massif",
        Neck: "5 piéces Erable/Palissandre",
        Fingerboard: "Ebène",
        brg: "Ebène",
        Nut: "Os",
        Saddle: "Os",
        TuningMachine: "Gotoh/Grover Die Cast Doré avec boutons noirs",
        String: xsen,
        FinishTop: "Nature",
        FinishNeck: "Satiné",
        FinishBody: "Brillant"
      }
    }
  },
  BJ_S22BFDC: {
    series: "BJ",
    shape: "BJ",
    model: "BJ-S22BFDC",
    desc: {
      en: "",
      zh: "",
      fr: ""
    },
    pics: {
      nav: "/BJ-S22BFDC/nav.jpg",
      head: "/BJ-S22BFDC/head.jpg",
      bg: "/BJ-S22BFDC/bg.jpg",
      special: [
        "/BJ-S22BFDC/1.jpg",
        "/BJ-S22BFDC/2.jpg",
        "/BJ-S22BFDC/3.jpg",
        "/BJ-S22BFDC/4.jpg",
        "/BJ-S22BFDC/5.jpg",
        "/BJ-S22BFDC/6.jpg",
        "/BJ-S22BFDC/7.jpg",
      ]
    },
    video: {
      zh: "",
      en: ""
    },
    spec: {
      en: {
        model: "BJ-S22BFDC",
        shape: "BJ",
        Top: "Solid Sitka Spruce",
        BackSides: "Solid Santos Rosewood",
        Neck: pinen,
        Fingerboard: "Ebony",
        brg: "Ebony",
        Nut: "Bone",
        Saddle: "Bone",
        TuningMachine: "Gotoh/Grover Die Cast Gold With Gold Buttons",
        String: xsen,
        FinishTop: "Natural",
        FinishNeck: "Satin",
        FinishBody: "Gloss"
      },
      zh: {
        model: "BJ-S22BFDC",
        shape: "BJ 型",
        Top: "西特卡云杉单板",
        BackSides: "桑托斯玫瑰木单板",
        Neck: pinzh,
        Fingerboard: "乌木",
        brg: "乌木",
        Nut: "牛骨",
        Saddle: "牛骨",
        TuningMachine: "Gotoh/Grover 金色全封闭",
        String: xszh,
        FinishTop: "自然色",
        FinishNeck: "哑光",
        FinishBody: "亮光"
      },
      fr: {
        model: "BJ-S22BFDC",
        shape: "BJ",
        Top: "Epicéa Sitka Massif",
        BackSides: "Epicéa Palissandre De Santos Massif",
        Neck: pinfr,
        Fingerboard: "Ebène",
        brg: "Ebène",
        Nut: "Os",
        Saddle: "Os",
        TuningMachine: "Gotoh/Grover Die Cast Doré avec boutons dorés",
        String: xsen,
        FinishTop: "Nature",
        FinishNeck: "Satiné",
        FinishBody: "Brillant"
      }
    }
  },
  D_S12AC: {
    series: "D",
    shape: "D",
    model: "D-S12AC",
    desc: {
      en: "",
      zh: "",
      fr: ""
    },
    pics: {
      nav: "/D-S12AC/nav.jpg",
      head: "/D-S12AC/head.jpg",
      bg: "/D-S12AC/bg.jpg",
      special: [
        "/D-S12AC/1.jpg",
        "/D-S12AC/2.jpg",
        "/D-S12AC/3.jpg",
        "/D-S12AC/4.jpg",
      ]
    },
    video: {
      zh: "//player.bilibili.com/player.html?bvid=BV1kN4y1G7Y1&autoplay=0",
      en: "https://www.youtube.com/embed/92nZWYboVjA"
    },
    spec: {
      en: {
        model: "D-S12AC",
        shape: "D",
        Top: "Solid Sitka Spruce ",
        BackSides: "Solid Mahogany",
        Neck: pinen,
        Fingerboard: "Rosewood",
        brg: "Rosewood",
        Nut: "Bone",
        Saddle: "Bone",
        TuningMachine: gdcen,
        String: xsen,
        FinishTop: "Natural",
        FinishNeck: "Satin",
        FinishBody: "Gloss"
      },
      zh: {
        model: "D-S12AC",
        shape: "D 型",
        Top: "西特卡云杉单板 ",
        BackSides: "桃花芯单板",
        Neck: pinzh,
        Fingerboard: "玫瑰木",
        brg: "玫瑰木",
        Nut: "牛骨",
        Saddle: "牛骨",
        TuningMachine: "金色全封闭带金色钮",
        String: xszh,
        FinishTop: "自然色",
        FinishNeck: "哑光",
        FinishBody: "亮光"
      },
      fr: {
        model: "D-S12AC",
        shape: "D",
        Top: "Epicéa Sitka Massif ",
        BackSides: "Epicéa Acajou Massif",
        Neck: pinfr,
        Fingerboard: "Palissandre",
        brg: "Palissandre",
        Nut: "Os",
        Saddle: "Os",
        TuningMachine: "Gomans Die Cast Doré avec boutons dorés",
        String: xsen,
        FinishTop: "Nature",
        FinishNeck: "Satiné",
        FinishBody: "Brillant"
      }
    }
  },
  D_S18: {
    series: "D",
    shape: "D",
    model: "D-S18",
    desc: {
      en: "",
      zh: "",
      fr: ""
    },
    pics: {
      nav: "/D-S18/nav.jpg",
      head: "/D-S18/head.jpg",
      bg: "/D-S18/bg.jpg",
      special: [
        "/D-S18/1.jpg",
        "/D-S18/2.jpg",
        "/D-S18/3.jpg",
        "/D-S18/4.jpg",
        "/D-S18/5.jpg",
        "/D-S18/6.jpg"
      ]
    },
    video: {
      zh: "",
      en: ""
    },
    spec: {
      en: {
        model: "D-S18",
        shape: "D",
        Top: "Solid Alpine Moon Spruce ",
        BackSides: "Solid Indian Rosewood",
        Neck: pinen,
        Fingerboard: "Ebony",
        brg: "Ebony",
        Nut: "Bone",
        Saddle: "Bone",
        TuningMachine: gdcen,
        String: xsen,
        FinishTop: "Natural",
        FinishNeck: "Satin",
        FinishBody: "Gloss"
      },
      zh: {
        model: "D-S18",
        shape: "D 型",
        Top: "月亮云杉单板 ",
        BackSides: "玫瑰木单板",
        Neck: pinzh,
        Fingerboard: "乌木",
        brg: "乌木",
        Nut: "牛骨",
        Saddle: "牛骨",
        TuningMachine: "金色全封闭带金色钮",
        String: xszh,
        FinishTop: "自然色",
        FinishNeck: "哑光",
        FinishBody: "亮光"
      },
      fr: {
        model: "D-S18",
        shape: "D",
        Top: "Epicéa Alpine Massif ",
        BackSides: "Epicéa Palissandre Massif",
        Neck: pinfr,
        Fingerboard: "Ebène",
        brg: "Ebène",
        Nut: "Os",
        Saddle: "Os",
        TuningMachine: "Gomans Die Cast Doré avec boutons dorés",
        String: xsen,
        FinishTop: "Nature",
        FinishNeck: "Satiné",
        FinishBody: "Brillant"
      }
    }
  },
  D_S18AC: {
    series: "D",
    shape: "D",
    model: "D-S18AC",
    desc: {
      en: "",
      zh: "",
      fr: ""
    },
    aud: "/aud/D-S18ACSC GAUTHIER.mp3",
    ply: "Tak Chan",
    sti: "GAUTHIER",
    pics: {
      nav: "/D-S18AC/nav.jpg",
      bg: "/D-S18AC/bg.jpg",
      special: [
        "/D-S18AC/1.jpg",
        "/D-S18AC/2.jpg",
        "/D-S18AC/3.jpg",
        "/D-S18AC/4.jpg",
        "/D-S18AC/5.jpg",
        "/D-S18AC/6.jpg",
        "/D-S18AC/7.jpg",
      ]
    },
    video: {
      zh: "",
      en: ""
    },
    spec: {
      en: {
        model: "D-S18AC",
        shape: "D",
        Top: "Solid Alpine Moon Spruce",
        BackSides: "Solid Indian Rosewood",
        Neck: pinen,
        Fingerboard: "Ebony",
        brg: "Ebony",
        Nut: "Bone",
        Saddle: "Bone",
        TuningMachine: "Gomans Die Cast Gold With Black Buttons",
        String: xsen,
        FinishTop: "Natural",
        FinishNeck: "Satin",
        FinishBody: "Gloss"
      },
      zh: {
        model: "D-S18AC",
        shape: "D 型",
        Top: "月亮云杉单板",
        BackSides: "玫瑰木单板",
        Neck: pinzh,
        Fingerboard: "乌木",
        brg: "乌木",
        Nut: "牛骨",
        Saddle: "牛骨",
        TuningMachine: "金色全封闭黑色钮",
        String: xszh,
        FinishTop: "自然色",
        FinishNeck: "哑光",
        FinishBody: "亮光"
      },
      fr: {
        model: "D-S18AC",
        shape: "D",
        Top: "Epicéa Alpine Massif",
        BackSides: "Epicéa Palissandre Massif",
        Neck: pinfr,
        Fingerboard: "Ebène",
        brg: "Ebène",
        Nut: "Os",
        Saddle: "Os",
        TuningMachine: "Gomans Die Cast Doré avec boutons noirs",
        String: xsen,
        FinishTop: "Nature",
        FinishNeck: "Satiné",
        FinishBody: "Brillant"
      }
    }
  },
  F_S16C: {
    series: "F",
    shape: "F",
    model: "F-S16C",
    desc: {
      en: "",
      zh: "",
      fr: ""
    },
    pics: {
      nav: "/F-S16C/nav.jpg",
      head: "/F-S16C/head.jpg",
      bg: "/F-S16C/bg.jpg",
      special: [
        "/F-S16C/1.jpg",
        "/F-S16C/2.jpg",
        "/F-S16C/3.jpg",
        "/F-S16C/4.jpg",
        "/F-S16C/5.jpg",
      ]
    },
    video: {
      zh: "",
      en: ""
    },
    spec: {
      en: {
        model: "F-S16C",
        shape: "F",
        Top: "Solid Sitka Spruce ",
        BackSides: "Solid Padouk",
        Neck: pinen,
        Fingerboard: "Rosewood",
        brg: "Rosewood",
        Nut: "Bone",
        Saddle: "Bone",
        TuningMachine: gdcen,
        String: xsen,
        FinishTop: "Natural",
        FinishNeck: "Satin",
        FinishBody: "Gloss"
      },
      zh: {
        model: "F-S16C",
        shape: "F 型",
        Top: "西特卡云杉单板 ",
        BackSides: "红花梨单板",
        Neck: pinzh,
        Fingerboard: "玫瑰木",
        brg: "玫瑰木",
        Nut: "牛骨",
        Saddle: "牛骨",
        TuningMachine: "金色全封闭带金色钮",
        String: xszh,
        FinishTop: "自然色",
        FinishNeck: "哑光",
        FinishBody: "亮光"
      },
      fr: {
        model: "F-S16C",
        shape: "F",
        Top: "Epicéa Sitka Massif ",
        BackSides: "Epicéa Padouk Massif",
        Neck: pinfr,
        Fingerboard: "Palissandre",
        brg: "Palissandre",
        Nut: "Os",
        Saddle: "Os",
        TuningMachine: "Gomans Die Cast Doré avec boutons dorés",
        String: xsen,
        FinishTop: "Nature",
        FinishNeck: "Satiné",
        FinishBody: "Brillant"
      }
    }
  },
  F_S18FC: {
    series: "F",
    shape: "F",
    model: "F-S18FC",
    desc: {
      en: "",
      zh: "",
      fr: ""
    },
    pics: {
      nav: "/F-S18FC/nav.jpg",
      head: "/F-S18FC/head.jpg",
      bg: "/F-S18FC/bg.jpg",
      special: [
        "/F-S18FC/1.jpg",
        "/F-S18FC/2.jpg",
        "/F-S18FC/3.jpg",
        "/F-S18FC/4.jpg",
        "/F-S18FC/5.jpg",
        "/F-S18FC/6.jpg",
        "/F-S18FC/7.jpg",
      ]
    },
    video: {
      zh: "//player.bilibili.com/player.html?bvid=BV1dN4y1G75L&autoplay=0",
      en: "https://www.youtube.com/embed/lUxzyWQSo-8"
    },
    spec: {
      en: {
        model: "F-S18FC",
        shape: "F",
        Top: "Solid Alpine Moon Spruce ",
        BackSides: "Solid Indian Rosewood",
        Neck: pinen,
        Fingerboard: "Ebony",
        brg: "Ebony",
        Nut: "Bone",
        Saddle: "Bone",
        TuningMachine: "Gomans Die Cast Gold With Black Buttons",
        String: xsen,
        FinishTop: "Natural",
        FinishNeck: "Satin",
        FinishBody: "Gloss"
      },
      zh: {
        model: "F-S18FC",
        shape: "F 型",
        Top: "月亮云杉单板 ",
        BackSides: "玫瑰木单板",
        Neck: pinzh,
        Fingerboard: "乌木",
        brg: "乌木",
        Nut: "牛骨",
        Saddle: "牛骨",
        TuningMachine: "金色全封闭黑色钮",
        String: xszh,
        FinishTop: "自然色",
        FinishNeck: "哑光",
        FinishBody: "亮光"
      },
      fr: {
        model: "F-S18FC",
        shape: "F",
        Top: "Epicéa Alpine Massif ",
        BackSides: "Epicéa Palissandre Massif",
        Neck: pinfr,
        Fingerboard: "Ebène",
        brg: "Ebène",
        Nut: "Os",
        Saddle: "Os",
        TuningMachine: "Gomans Die Cast Doré avec boutons noirs",
        String: xsen,
        FinishTop: "Nature",
        FinishNeck: "Satiné",
        FinishBody: "Brillant"
      }
    }
  },
  F_S12FC_BK: {
    series: "F",
    shape: "F",
    model: "F-S12FC-BK",
    desc: {
      en: "",
      zh: "全单扇品桃花芯木吉他，面板复古处理，声韵厚，音动人，色沉稳，Gomans自研手枕使得弹奏更舒适。",
      fr: ""
    },
    pics: {
      nav: "/F-S12FC-BK/nav.jpg",
      head: "/F-S12FC-BK/head.jpg",
      bg: "/F-S12FC-BK/bg.jpg",
      special: [
        "/F-S12FC-BK/1.jpg",
        "/F-S12FC-BK/2.jpg",
        "/F-S12FC-BK/3.jpg",
        "/F-S12FC-BK/4.jpg",
        "/F-S12FC-BK/5.jpg",
        "/F-S12FC-BK/6.jpg",
        "/F-S12FC-BK/7.jpg",
      ]
    },
    video: {
      zh: "",
      en: ""
    },
    spec: {
      en: {
        model: "F-S12FC-BK",
        shape: "F",
        Top: "Solid Sitka Spruce",
        BackSides: "Solid Mahogany",
        Neck: pinen,
        Fingerboard: "Rosewood",
        brg: "Rosewood",
        Nut: "Bone",
        Saddle: "Bone",
        TuningMachine: "Gomans Die Cast Gold With Black Buttons",
        String: xsen,
        FinishTop: "Satin Relic",
        FinishNeck: "Satin",
        FinishBody: "Satin"
      },
      zh: {
        model: "F-S12FC-BK",
        shape: "F 型",
        Top: "西特卡云杉单板",
        BackSides: "桃花芯单板",
        Neck: pinzh,
        Fingerboard: "玫瑰木",
        brg: "玫瑰木",
        Nut: "牛骨",
        Saddle: "牛骨",
        TuningMachine: "金色全封闭黑色钮",
        String: xszh,
        FinishTop: "哑光 复古",
        FinishNeck: "哑光",
        FinishBody: "哑光"
      },
      fr: {
        model: "F-S12FC-BK",
        shape: "F",
        Top: "Epicéa Sitka Massif",
        BackSides: "Epicéa Acajou Massif",
        Neck: pinfr,
        Fingerboard: "Palissandre",
        brg: "Palissandre",
        Nut: "Os",
        Saddle: "Os",
        TuningMachine: "Gomans Die Cast Doré avec boutons noirs",
        String: xsen,
        FinishTop: "Satiné Reliques",
        FinishNeck: "Satiné",
        FinishBody: "Satiné"
      }
    }
  },
  F_S21C: {
    series: "F",
    shape: "F",
    model: "F-S21C",
    desc: {
      en: "",
      zh: "",
      fr: ""
    },
    aud: "/aud/F-S21C BLUE BINDING AND PURFLING.mp3",
    ply: "Tak Chan",
    sti: "Em",
    pics: {
      nav: "/F-S21C/nav.jpg",
      head: "/F-S21C/head.jpg",
      bg: "/F-S21C/bg.jpg",
      special: [
        "/F-S21C/1.jpg",
        "/F-S21C/2.jpg",
        "/F-S21C/3.jpg",
        "/F-S21C/4.jpg",
        "/F-S21C/5.jpg",
        "/F-S21C/6.jpg",
        "/F-S21C/7.jpg",
        "/F-S21C/8.jpg",
      ]
    },
    video: {
      zh: "",
      en: ""
    },
    spec: {
      en: {
        model: "F-S21C",
        shape: "F",
        Top: "Solid Alpine Moon Spruce ",
        BackSides: "Solid Maple",
        Neck: "5 Pieces Maple/Rosewood",
        Fingerboard: "Ebony",
        brg: "Ebony",
        Nut: "Bone",
        Saddle: "Bone",
        TuningMachine: "Gotoh/Grover Die Cast Gold With Gold Buttons",
        String: xsen,
        FinishTop: "Natural",
        FinishNeck: "Satin",
        FinishBody: "Gloss"
      },
      zh: {
        model: "F-S21C",
        shape: "F 型",
        Top: "月亮云杉单板 ",
        BackSides: "枫木单板",
        Neck: "五拼一体 枫木/玫瑰木",
        Fingerboard: "乌木",
        brg: "乌木",
        Nut: "牛骨",
        Saddle: "牛骨",
        TuningMachine: "Gotoh/Grover 金色全封闭",
        String: xszh,
        FinishTop: "自然色",
        FinishNeck: "哑光",
        FinishBody: "亮光"
      },
      fr: {
        model: "F-S21C",
        shape: "F",
        Top: "Epicéa Alpine Massif ",
        BackSides: "Erable Massif",
        Neck: "5 piéces Erable/Palissandre",
        Fingerboard: "Ebène",
        brg: "Ebène",
        Nut: "Os",
        Saddle: "Os",
        TuningMachine: "Gotoh/Grover Die Cast Doré avec boutons dorés",
        String: xsen,
        FinishTop: "Nature",
        FinishNeck: "Satiné",
        FinishBody: "Brillant"
      }
    }
  },
  F_S21FHC: {
    series: "F",
    shape: "F",
    model: "F-S21FHC",
    desc: {
      en: "",
      zh: "",
      fr: ""
    },
    pics: {
      nav: "/F-S21FHC/nav.jpg",
      head: "/F-S21FHC/head.jpg",
      bg: "/F-S21FHC/bg.jpg",
      special: [
        "/F-S21FHC/1.jpg",
        "/F-S21FHC/2.jpg",
        "/F-S21FHC/3.jpg",
        "/F-S21FHC/4.jpg",
        "/F-S21FHC/5.jpg",
        "/F-S21FHC/6.jpg",
        "/F-S21FHC/7.jpg",
        "/F-S21FHC/8.jpg",
      ]
    },
    video: {
      zh: "//player.bilibili.com/player.html?bvid=BV1Ha411Z7vq&autoplay=0",
      en: "https://www.youtube.com/embed/JtQKoMNiICw"
    },
    spec: {
      en: {
        model: "F-S21FHC",
        shape: "F",
        Top: "Solid Alpine Moon Spruce ",
        BackSides: "Solid Maple",
        Neck: "5 Pieces Maple/Rosewood",
        Fingerboard: "Ebony",
        brg: "Ebony",
        Nut: "Bone",
        Saddle: "Bone",
        TuningMachine: "Gotoh/Grover Die Cast Gold With Gold Buttons",
        String: xsen,
        FinishTop: "Natural",
        FinishNeck: "Satin",
        FinishBody: "Gloss"
      },
      zh: {
        model: "F-S21FHC",
        shape: "F 型",
        Top: "月亮云杉单板 ",
        BackSides: "枫木单板",
        Neck: "五拼一体 枫木/玫瑰木",
        Fingerboard: "乌木",
        brg: "乌木",
        Nut: "牛骨",
        Saddle: "牛骨",
        TuningMachine: "Gotoh/Grover 金色全封闭",
        String: xszh,
        FinishTop: "自然色",
        FinishNeck: "哑光",
        FinishBody: "亮光"
      },
      fr: {
        model: "F-S21FHC",
        shape: "F",
        Top: "Epicéa Alpine Massif ",
        BackSides: "Erable Massif",
        Neck: "5 piéces Erable/Palissandre",
        Fingerboard: "Ebène",
        brg: "Ebène",
        Nut: "Os",
        Saddle: "Os",
        TuningMachine: "Gotoh/Grover Die Cast Doré avec boutons dorés",
        String: xsen,
        FinishTop: "Nature",
        FinishNeck: "Satiné",
        FinishBody: "Brillant"
      }
    }
  },
  F_S38FAC_DLX: {
    series: "F",
    shape: "F",
    model: "F-S38FAC DLX",
    desc: {
      en: "",
      zh: "",
      fr: ""
    },
    pics: {
      nav: "/F-S38FAC_DLX/nav.jpg",
      head: "/F-S38FAC_DLX/head.jpg",
      bg: "/F-S38FAC_DLX/bg.jpg",
      special: [
        "/F-S38FAC_DLX/1.jpg",
        "/F-S38FAC_DLX/2.jpg",
        "/F-S38FAC_DLX/3.jpg",
        "/F-S38FAC_DLX/4.jpg",
        "/F-S38FAC_DLX/5.jpg",
        "/F-S38FAC_DLX/6.jpg",
        "/F-S38FAC_DLX/7.jpg",
      ]
    },
    video: {
      zh: "",
      en: ""
    },
    spec: {
      en: {
        model: "F-S38FAC DLX",
        shape: "F",
        Top: "Solid Alpine Moon Spruce",
        BackSides: "Solid Cocobolo",
        Neck: pinen,
        Fingerboard: "Ebony",
        brg: "Ebony",
        Nut: "Bone",
        Saddle: "Bone",
        TuningMachine: "Gotoh/Grover Die Cast Gold With Gold Buttons",
        String: xsen,
        FinishTop: "Natural",
        FinishNeck: "Satin",
        FinishBody: "Gloss"
      },
      zh: {
        model: "F-S38FAC DLX",
        shape: "F 型",
        Top: "月亮云杉单板",
        BackSides: "可可菠萝单板",
        Neck: pinzh,
        Fingerboard: "乌木",
        brg: "乌木",
        Nut: "牛骨",
        Saddle: "牛骨",
        TuningMachine: "Gotoh/Grover 金色全封闭",
        String: xszh,
        FinishTop: "自然色",
        FinishNeck: "哑光",
        FinishBody: "亮光"
      },
      fr: {
        model: "F-S38FAC DLX",
        shape: "F",
        Top: "Epicéa Alpine Massif",
        BackSides: "Epicéa Cocobolo Massif",
        Neck: pinfr,
        Fingerboard: "Ebène",
        brg: "Ebène",
        Nut: "Os",
        Saddle: "Os",
        TuningMachine: "Gotoh/Grover Die Cast Doré avec boutons dorés",
        String: xsen,
        FinishTop: "Nature",
        FinishNeck: "Satiné",
        FinishBody: "Brillant"
      }
    }
  },
  F12_S12AC: {
    series: "F12",
    shape: "F",
    model: "F12-S12AC",
    desc: {
      en: "",
      zh: "",
      fr: ""
    },
    pics: {
      nav: "/F12-S12AC/nav.jpg",
      head: "/F12-S12AC/head.jpg",
      bg: "/F12-S12AC/bg.jpg",
      special: [
        "/F12-S12AC/1.jpg",
        "/F12-S12AC/2.jpg",
        "/F12-S12AC/3.jpg",
        "/F12-S12AC/4.jpg",
        "/F12-S12AC/5.jpg",
        "/F12-S12AC/6.jpg"
      ]
    },
    video: {
      zh: "",
      en: ""
    },
    spec: {
      en: {
        model: "F12-S12AC",
        shape: "F",
        Top: "Solid Sitka Spruce",
        BackSides: "Solid Mahogany",
        Neck: pinen,
        Fingerboard: "Indian Rosewood",
        brg: "Indian Rosewood",
        Nut: "Bone",
        Saddle: "Bone",
        TuningMachine: "Die Cast Gold With Gold Buttons",
        String: "D'Addario EXP38",
        FinishTop: "Natural",
        FinishNeck: "Satin",
        FinishBody: "Gloss"
      },
      zh: {
        model: "F12-S12AC",
        shape: "F 型",
        Top: "西特卡云杉单板",
        BackSides: "桃花芯单板",
        Neck: pinzh,
        Fingerboard: "玫瑰木",
        brg: "玫瑰木",
        Nut: "牛骨",
        Saddle: "牛骨",
        TuningMachine: "金色全封闭带金色",
        String: "达达里奥 EXP38",
        FinishTop: "自然色",
        FinishNeck: "哑光",
        FinishBody: "亮光"
      },
      fr: {
        model: "F12-S12AC",
        shape: "F",
        Top: "Epicéa Sitka Massif",
        BackSides: "Epicéa Acajou Massif",
        Neck: pinfr,
        Fingerboard: "Palissandre",
        brg: "Palissandre",
        Nut: "Os",
        Saddle: "Os",
        TuningMachine: "Die Cast Doré avec boutons dorés",
        String: "D'Addario EXP38",
        FinishTop: "Nature",
        FinishNeck: "Satiné",
        FinishBody: "Brillant"
      }
    }
  },
  GB_S12: {
    series: "GB",
    shape: "GB",
    model: "GB-S12",
    desc: {
      en: "",
      zh: "",
      fr: ""
    },
    pics: {
      nav: "/GB-S12/nav.jpg",
      head: "/GB-S12/head.jpg",
      bg: "/GB-S12/bg.jpg",
      special: [
        "/GB-S12/1.jpg",
        "/GB-S12/2.jpg",
        "/GB-S12/3.jpg",
        "/GB-S12/4.jpg",
        "/GB-S12/5.jpg",
      ]
    },
    video: {
      zh: "//player.bilibili.com/player.html?bvid=BV19P411L7GG&autoplay=0",
      en: ""
    },
    spec: {
      en: {
        model: "GB-S12",
        shape: "GB",
        Top: "Solid Sitka Spruce",
        BackSides: "Solid Mahogany",
        Neck: "Mahogany",
        Fingerboard: "Rosewood",
        brg: "Rosewood",
        Nut: "Bone",
        Saddle: "Bone",
        TuningMachine: "Die Cast Chrome",
        String: xsen,
        FinishTop: "Natural",
        FinishNeck: "Satin",
        FinishBody: "Satin"
      },
      zh: {
        model: "GB-S12",
        shape: "GB 型",
        Top: "西特卡云杉单板",
        BackSides: "桃花芯单板",
        Neck: "桃花芯",
        Fingerboard: "玫瑰木",
        brg: "玫瑰木",
        Nut: "牛骨",
        Saddle: "牛骨",
        TuningMachine: "铬色全封",
        String: xszh,
        FinishTop: "自然色",
        FinishNeck: "哑光",
        FinishBody: "哑光"
      },
      fr: {
        model: "GB-S12",
        shape: "GB",
        Top: "Epicéa Sitka Massif",
        BackSides: "Epicéa Acajou Massife",
        Neck: "Acajou",
        Fingerboard: "Palissandre",
        brg: "Palissandre",
        Nut: "Os",
        Saddle: "Os",
        TuningMachine: "Die Cast Chromé",
        String: xsen,
        FinishTop: "Nature",
        FinishNeck: "Satiné",
        FinishBody: "Satiné"
      }
    }
  },
  GC_S18ACA: {
    series: "GC",
    shape: "G",
    model: "GC-S18ACA",
    desc: {
      en: "",
      zh: "",
      fr: ""
    },
    pics: {
      nav: "/GC-S18ACA/nav.jpg",
      head: "/GC-S18ACA/head.jpg",
      bg: "/GC-S18ACA/bg.jpg",
      special: [
        "/GC-S18ACA/1.jpg",
        "/GC-S18ACA/2.jpg",
        "/GC-S18ACA/3.jpg",
        "/GC-S18ACA/4.jpg",
        "/GC-S18ACA/5.jpg",
        "/GC-S18ACA/6.jpg",
        "/GC-S18ACA/7.jpg",
      ]
    },
    video: {
      zh: "",
      en: ""
    },
    spec: {
      en: {
        model: "GC-S18ACA",
        shape: "G",
        Top: "Solid Alpine Moon Spruce ",
        BackSides: "Solid Indian Rosewood",
        Neck: pinen,
        Fingerboard: "Ebony",
        brg: "Ebony",
        Nut: "Bone",
        Saddle: "Bone",
        TuningMachine: "Gotoh/Grover Die Cast Gold With Black Button",
        String: xsen,
        FinishTop: "Natural Relic",
        FinishNeck: "Satin",
        FinishBody: "Gloss"
      },
      zh: {
        model: "GC-S18ACA",
        shape: "G 型",
        Top: "月亮云杉单板 ",
        BackSides: "玫瑰木单板",
        Neck: pinzh,
        Fingerboard: "乌木",
        brg: "乌木",
        Nut: "牛骨",
        Saddle: "牛骨",
        TuningMachine: "Gotoh/Grover 金色全封闭带黑色钮",
        String: xszh,
        FinishTop: "自然复古",
        FinishNeck: "哑光",
        FinishBody: "亮光"
      },
      fr: {
        model: "GC-S18ACA",
        shape: "G",
        Top: "Epicéa Alpine Massif ",
        BackSides: "Epicéa Palissandre Massif",
        Neck: pinfr,
        Fingerboard: "Ebène",
        brg: "Ebène",
        Nut: "Os",
        Saddle: "Os",
        TuningMachine: "Gotoh/Grover Die Cast Doré avec boutons noirs",
        String: xsen,
        FinishTop: "Nature Reliqué",
        FinishNeck: "Satiné",
        FinishBody: "Brillant"
      }
    }
  },
  GC_S18AC: {
    series: "GC",
    shape: "G",
    model: "GC-S18AC",
    desc: {
      en: "",
      zh: "",
      fr: ""
    },
    aud: "/aud/GC-S18AC WITH ANTHEM AND NEUMANN TLM 102.mp3",
    ply: "Tak Chan",
    sti: "WITH ANTHEM AND NEUMANN TLM 102",
    pics: {
      nav: "/GC-S18AC/nav.jpg",
      head: "/GC-S18AC/head.jpg",
      bg: "/GC-S18AC/bg.jpg",
      special: [
        "/GC-S18AC/1.jpg",
        "/GC-S18AC/2.jpg",
        "/GC-S18AC/3.jpg",
        "/GC-S18AC/4.jpg",
        "/GC-S18AC/5.jpg",
        "/GC-S18AC/6.jpg",
        "/GC-S18AC/7.jpg",
        "/GC-S18AC/8.jpg",
      ]
    },
    video: {
      zh: "",
      en: ""
    },
    spec: {
      en: {
        model: "GC-S18AC",
        shape: "G",
        Top: "Solid Alpine Moon Spruce ",
        BackSides: "Solid Indian Rosewood",
        Neck: pinen,
        Fingerboard: "Ebony",
        brg: "Ebony",
        Nut: "Bone",
        Saddle: "Bone",
        TuningMachine: "Gotoh/Grover Die Cast Gold With Black Button",
        String: xsen,
        FinishTop: "Natural",
        FinishNeck: "Satin",
        FinishBody: "Gloss"
      },
      zh: {
        model: "GC-S18AC",
        shape: "G 型",
        Top: "月亮云杉单板 ",
        BackSides: "玫瑰木单板",
        Neck: pinzh,
        Fingerboard: "乌木",
        brg: "乌木",
        Nut: "牛骨",
        Saddle: "牛骨",
        TuningMachine: "Gotoh/Grover 金色全封闭带黑色钮",
        String: xszh,
        FinishTop: "自然色",
        FinishNeck: "哑光",
        FinishBody: "亮光"
      },
      fr: {
        model: "GC-S18AC",
        shape: "G",
        Top: "Epicéa Alpine Massif ",
        BackSides: "Epicéa Palissandre Massif",
        Neck: pinfr,
        Fingerboard: "Ebène",
        brg: "Ebène",
        Nut: "Os",
        Saddle: "Os",
        TuningMachine: "Gotoh/Grover Die Cast Doré avec boutons noirs",
        String: xsen,
        FinishTop: "Nature",
        FinishNeck: "Satiné",
        FinishBody: "Brillant"
      }
    }
  },
  GC_S18DC_DLX: {
    series: "DLX",
    shape: "G",
    model: "GC-S18DC DLX",
    desc: {
      en: "",
      zh: "",
      fr: ""
    },
    pics: {
      nav: "/GC-S18DC_DLX/nav.jpg",
      head: "/GC-S18DC_DLX/head.jpg",
      bg: "/GC-S18DC_DLX/bg.jpg",
      special: [
        "/GC-S18DC_DLX/1.jpg",
        "/GC-S18DC_DLX/2.jpg",
        "/GC-S18DC_DLX/3.jpg",
        "/GC-S18DC_DLX/4.jpg",
        "/GC-S18DC_DLX/5.jpg",
        "/GC-S18DC_DLX/6.jpg"
      ]
    },
    video: {
      zh: "",
      en: ""
    },
    spec: {
      en: {
        model: "GC-S18DC DLX",
        shape: "G",
        Top: "Solid Red Cedar",
        BackSides: "Solid Indian Rosewood",
        Neck: pinen,
        Fingerboard: "Ebony",
        brg: "Ebony",
        Nut: "Bone",
        Saddle: "Bone",
        TuningMachine: "Gotoh/Grover Die Cast Gold With Black Button",
        String: xsen,
        FinishTop: "Natural",
        FinishNeck: "Satin",
        FinishBody: "Gloss"
      },
      zh: {
        model: "GC-S18DC DLX",
        shape: "G 型",
        Top: "红松单板",
        BackSides: "玫瑰木单板",
        Neck: pinzh,
        Fingerboard: "乌木",
        brg: "乌木",
        Nut: "牛骨",
        Saddle: "牛骨",
        TuningMachine: "Gotoh/Grover 金色全封闭带黑色钮",
        String: xszh,
        FinishTop: "自然色",
        FinishNeck: "哑光",
        FinishBody: "亮光"
      },
      fr: {
        model: "GC-S18DC DLX",
        shape: "G",
        Top: "Cèdre Rouge Massif",
        BackSides: "Epicéa Palissandre Massif",
        Neck: pinfr,
        Fingerboard: "Ebène",
        brg: "Ebène",
        Nut: "Os",
        Saddle: "Os",
        TuningMachine: "Gotoh/Grover Die Cast Doré avec boutons noirs",
        String: xsen,
        FinishTop: "Nature",
        FinishNeck: "Satiné",
        FinishBody: "Brillant"
      }
    }
  },
  GC_S18FAC_DLX: {
    series: "DLX",
    shape: "G",
    model: "GC-S18FAC DLX",
    desc: {
      en: "",
      zh: "",
      fr: ""
    },
    pics: {
      nav: "/GC-S18FAC_DLX/nav.jpg",
      head: "/GC-S18FAC_DLX/head.jpg",
      bg: "/GC-S18FAC_DLX/bg.jpg",
      special: [
        "/GC-S18FAC_DLX/1.jpg",
        "/GC-S18FAC_DLX/2.jpg",
        "/GC-S18FAC_DLX/3.jpg",
        "/GC-S18FAC_DLX/4.jpg",
        "/GC-S18FAC_DLX/5.jpg",
        "/GC-S18FAC_DLX/6.jpg"
      ]
    },
    video: {
      zh: "",
      en: ""
    },
    spec: {
      en: {
        model: "GC-S18FAC DLX",
        shape: "G",
        Top: "Solid Alpine Moon Spruce ",
        BackSides: "Solid Indian Rosewood",
        Neck: pinen,
        Fingerboard: "Ebony",
        brg: "Ebony",
        Nut: "Bone",
        Saddle: "Bone",
        TuningMachine: "Gotoh/Grover Die Cast Gold With Black Button",
        String: xsen,
        FinishTop: "Natural",
        FinishNeck: "Satin",
        FinishBody: "Gloss"
      },
      zh: {
        model: "GC-S18FAC DLX",
        shape: "G 型",
        Top: "月亮云杉单板 ",
        BackSides: "玫瑰木单板",
        Neck: pinzh,
        Fingerboard: "乌木",
        brg: "乌木",
        Nut: "牛骨",
        Saddle: "牛骨",
        TuningMachine: "Gotoh/Grover 金色全封闭带黑色钮",
        String: xszh,
        FinishTop: "自然色",
        FinishNeck: "哑光",
        FinishBody: "亮光"
      },
      fr: {
        model: "GC-S18FAC DLX",
        shape: "G",
        Top: "Epicéa Alpine Massif ",
        BackSides: "Epicéa Palissandre Massif",
        Neck: pinfr,
        Fingerboard: "Ebène",
        brg: "Ebène",
        Nut: "Os",
        Saddle: "Os",
        TuningMachine: "Gotoh/Grover Die Cast Doré avec boutons noirs",
        String: xsen,
        FinishTop: "Nature",
        FinishNeck: "Satiné",
        FinishBody: "Brillant"
      }
    }
  },
  GC_S19AC_DLX: {
    series: "DLX",
    shape: "G",
    model: "GC-S19AC DLX",
    desc: {
      en: "",
      zh: "",
      fr: ""
    },
    pics: {
      nav: "/GC-S19AC_DLX/nav.jpg",
      head: "/GC-S19AC_DLX/head.jpg",
      bg: "/GC-S19AC_DLX/bg.jpg",
      special: [
        "/GC-S19AC_DLX/1.jpg",
        "/GC-S19AC_DLX/2.jpg",
        "/GC-S19AC_DLX/3.jpg",
        "/GC-S19AC_DLX/4.jpg",
        "/GC-S19AC_DLX/5.jpg",
        "/GC-S19AC_DLX/6.jpg",
        "/GC-S19AC_DLX/7.jpg",
      ]
    },
    video: {
      zh: "",
      en: ""
    },
    spec: {
      en: {
        model: "GC-S19AC DLX",
        shape: "G",
        Top: "Solid Alpine Moon Spruce ",
        BackSides: "Solid Acacia Koa",
        Neck: pinen,
        Fingerboard: "Ebony",
        brg: "Ebony",
        Nut: "Bone",
        Saddle: "Bone",
        TuningMachine: "Gotoh/Grover Die Cast Gold With Black Button",
        String: xsen,
        FinishTop: "Natural",
        FinishNeck: "Satin",
        FinishBody: "Gloss"
      },
      zh: {
        model: "GC-S19AC DLX",
        shape: "G 型",
        Top: "月亮云杉单板 ",
        BackSides: "相思木单板",
        Neck: pinzh,
        Fingerboard: "乌木",
        brg: "乌木",
        Nut: "牛骨",
        Saddle: "牛骨",
        TuningMachine: "Gotoh/Grover 金色全封闭带黑色钮",
        String: xszh,
        FinishTop: "自然色",
        FinishNeck: "哑光",
        FinishBody: "亮光"
      },
      fr: {
        model: "GC-S19AC DLX",
        shape: "G",
        Top: "Epicéa Alpine Massif ",
        BackSides: "Acacia Koa Massif",
        Neck: pinfr,
        Fingerboard: "Ebène",
        brg: "Ebène",
        Nut: "Os",
        Saddle: "Os",
        TuningMachine: "Gotoh/Grover Die Cast Doré avec boutons noirs",
        String: xsen,
        FinishTop: "Nature",
        FinishNeck: "Satiné",
        FinishBody: "Brillant"
      }
    }
  },
  GC_S38AC_DLX: {
    series: "GC",
    shape: "G",
    model: "GC-S38AC DLX",
    desc: {
      en: "",
      zh: "",
      fr: ""
    },
    pics: {
      nav: "/GC-S38AC_DLX/nav.jpg",
      head: "/GC-S38AC_DLX/head.jpg",
      bg: "/GC-S38AC_DLX/bg.jpg",
      special: [
        "/GC-S38AC_DLX/1.jpg",
        "/GC-S38AC_DLX/2.jpg",
        "/GC-S38AC_DLX/3.jpg",
        "/GC-S38AC_DLX/4.jpg",
        "/GC-S38AC_DLX/5.jpg",
        "/GC-S38AC_DLX/6.jpg",
        "/GC-S38AC_DLX/7.jpg",
      ]
    },
    video: {
      zh: "",
      en: ""
    },
    spec: {
      en: {
        model: "GC-S38AC DLX",
        shape: "G",
        Top: "Solid Alpine Moon Spruce ",
        BackSides: "Solid Cocobolo",
        Neck: pinen,
        Fingerboard: "Ebony",
        brg: "Ebony",
        Nut: "Bone",
        Saddle: "Bone",
        TuningMachine: "Gotoh/Grover Die Cast Gold With Gold Buttons",
        String: xsen,
        FinishTop: "Natural",
        FinishNeck: "Satin",
        FinishBody: "Gloss"
      },
      zh: {
        model: "GC-S38AC DLX",
        shape: "G 型",
        Top: "月亮云杉单板 ",
        BackSides: "可可菠萝单板",
        Neck: pinzh,
        Fingerboard: "乌木",
        brg: "乌木",
        Nut: "牛骨",
        Saddle: "牛骨",
        TuningMachine: "Gotoh/Grover 金色全封闭",
        String: xszh,
        FinishTop: "自然色",
        FinishNeck: "哑光",
        FinishBody: "亮光"
      },
      fr: {
        model: "GC-S38AC DLX",
        shape: "G",
        Top: "Epicéa Alpine Massif ",
        BackSides: "Epicéa Cocobolo Massif",
        Neck: pinfr,
        Fingerboard: "Ebène",
        brg: "Ebène",
        Nut: "Os",
        Saddle: "Os",
        TuningMachine: "Gotoh/Grover Die Cast Doré avec boutons dorés",
        String: xsen,
        FinishTop: "Nature",
        FinishNeck: "Satiné",
        FinishBody: "Brillant"
      }
    }
  },
  GC_S88: {
    series: "GC",
    shape: "G",
    model: "GC-S88",
    desc: {
      en: "",
      zh: "",
      fr: ""
    },
    pics: {
      nav: "/GC-S88/nav.jpg",
      head: "/GC-S88/head.jpg",
      bg: "/GC-S88/bg.jpg",
      special: [
        "/GC-S88/1.jpg",
        "/GC-S88/2.jpg",
        "/GC-S88/3.jpg",
        "/GC-S88/4.jpg",
        "/GC-S88/5.jpg",
        "/GC-S88/6.jpg"
      ]
    },
    video: {
      zh: "",
      en: ""
    },
    spec: {
      en: {
        model: "GC-S88",
        shape: "G",
        Top: "Solid Adirondack Spruce",
        BackSides: "Solid Indian Rosewood",
        Neck: pinen,
        Fingerboard: "Ebony",
        brg: "Ebony",
        Nut: "Bone",
        Saddle: "Bone",
        TuningMachine: "Gotoh/Grover Die Cast Gold With Gold Buttons",
        String: xsen,
        FinishTop: "Natural",
        FinishNeck: "Satin",
        FinishBody: "Gloss"
      },
      zh: {
        model: "GC-S88",
        shape: "G 型",
        Top: "阿迪朗达克单板",
        BackSides: "玫瑰木单板",
        Neck: pinzh,
        Fingerboard: "乌木",
        brg: "乌木",
        Nut: "牛骨",
        Saddle: "牛骨",
        TuningMachine: "Gotoh/Grover 金色全封闭",
        String: xszh,
        FinishTop: "自然色",
        FinishNeck: "哑光",
        FinishBody: "亮光"
      },
      fr: {
        model: "GC-S88",
        shape: "G",
        Top: "Epicéa Adirondack Massif",
        BackSides: "Epicéa Palissandre Massif",
        Neck: pinfr,
        Fingerboard: "Ebène",
        brg: "Ebène",
        Nut: "Os",
        Saddle: "Os",
        TuningMachine: "Gotoh/Grover Die Cast Doré avec boutons dorés",
        String: "D'AddarioXS",
        FinishTop: "Nature",
        FinishNeck: "Satiné",
        FinishBody: "Brillant"
      }
    }
  },
  GC_S88AC_DLX: {
    series: "GC",
    shape: "G",
    model: "GC-S88AC DLX",
    desc: {
      en: "",
      zh: "",
      fr: ""
    },
    pics: {
      nav: "/GC-S88AC_DLX/nav.jpg",
      head: "/GC-S88AC_DLX/head.jpg",
      bg: "/GC-S88AC_DLX/bg.jpg",
      special: [
        "/GC-S88AC_DLX/1.jpg",
        "/GC-S88AC_DLX/2.jpg",
        "/GC-S88AC_DLX/3.jpg",
        "/GC-S88AC_DLX/4.jpg",
        "/GC-S88AC_DLX/5.jpg",
        "/GC-S88AC_DLX/6.jpg",
        "/GC-S88AC_DLX/7.jpg",
      ]
    },
    video: {
      zh: "",
      en: ""
    },
    spec: {
      en: {
        model: "GC-S88AC DLX",
        shape: "G",
        Top: "Solid Adirondack Spruce",
        BackSides: "Solid Master Grade Rosewood",
        Neck: pinen,
        Fingerboard: "Ebony",
        brg: "Ebony",
        Nut: "Bone",
        Saddle: "Bone",
        TuningMachine: "Gotoh 510 / Grover Die Cast Gold With Black Button",
        String: xsen,
        FinishTop: "Natural",
        FinishNeck: "Satin",
        FinishBody: "Gloss"
      },
      zh: {
        model: "GC-S88AC DLX",
        shape: "G 型",
        Top: "阿迪朗达云杉单板",
        BackSides: "大师级玫瑰木单板",
        Neck: pinzh,
        Fingerboard: "乌木",
        brg: "乌木",
        Nut: "牛骨",
        Saddle: "牛骨",
        TuningMachine: "Gotoh 510 / Grover 金色全封闭带黑色钮",
        String: xszh,
        FinishTop: "自然色",
        FinishNeck: "哑光",
        FinishBody: "亮光"
      },
      fr: {
        model: "GC-S88AC DLX",
        shape: "G",
        Top: "Epicéa Alpine Massif ",
        BackSides: "Niveau master Palissandre Massif",
        Neck: pinfr,
        Fingerboard: "Ebène",
        brg: "Ebène",
        Nut: "Os",
        Saddle: "Os",
        TuningMachine: "Gotoh 510 / Grover Die Cast Doré avec boutons noirs",
        String: xsen,
        FinishTop: "Nature",
        FinishNeck: "Satiné",
        FinishBody: "Brillant"
      }
    }
  },
  G_SA19DC: {
    series: "G",
    shape: "G",
    model: "G-SA19DC",
    desc: {
      en: "",
      zh: "",
      fr: ""
    },
    aud: "/aud/G-SA19DC.mp3",
    ply: "Tak Chan",
    sti: "AM",
    pics: {
      nav: "/G-SA19DC/nav.jpg",
      head: "/G-SA19DC/head.jpg",
      bg: "/G-SA19DC/bg.jpg",
      special: [
        "/G-SA19DC/1.jpg",
        "/G-SA19DC/2.jpg",
        "/G-SA19DC/3.jpg",
        "/G-SA19DC/4.jpg",
        "/G-SA19DC/5.jpg",
        "/G-SA19DC/6.jpg"
      ]
    },
    video: {
      zh: "",
      en: ""
    },
    spec: {
      en: {
        model: "G-SA19DC",
        shape: "G",
        Top: "Solid Acacia Koa",
        BackSides: "Solid Acacia Koa",
        Neck: pinen,
        Fingerboard: "Ebony",
        brg: "Ebony",
        Nut: "Bone",
        Saddle: "Bone",
        TuningMachine: "Gotoh/Grover Die Cast Gold With Black Button",
        String: xsen,
        FinishTop: "Satin",
        FinishNeck: "Satin",
        FinishBody: "Satin"
      },
      zh: {
        model: "G-SA19DC",
        shape: "G 型",
        Top: "相思木单板",
        BackSides: "相思木单板",
        Neck: pinzh,
        Fingerboard: "乌木",
        brg: "乌木",
        Nut: "牛骨",
        Saddle: "牛骨",
        TuningMachine: "Gotoh/Grover 金色全封闭黑色钮",
        String: xszh,
        FinishTop: "哑光",
        FinishNeck: "哑光",
        FinishBody: "哑光"
      },
      fr: {
        model: "G-SA19DC",
        shape: "G",
        Top: "Acacia Koa Massif",
        BackSides: "Acacia Koa Massif",
        Neck: pinfr,
        Fingerboard: "Ebène",
        brg: "Ebène",
        Nut: "Os",
        Saddle: "Os",
        TuningMachine: "Gotoh/Grover Die Cast Doré avec boutons noirs",
        String: xsen,
        FinishTop: "Satiné",
        FinishNeck: "Satiné",
        FinishBody: "Satiné"
      }
    }
  },
  G_S12_BK: {
    series: "G",
    shape: "G",
    model: "G-S12-BK",
    desc: {
      en: "",
      zh: "",
      fr: ""
    },
    pics: {
      nav: "/G-S12-BK/nav.jpg",
      head: "/G-S12-BK/head.jpg",
      bg: "/G-S12-BK/bg.jpg",
      special: [
        "/G-S12-BK/1.jpg",
        "/G-S12-BK/2.jpg",
        "/G-S12-BK/3.jpg",
        "/G-S12-BK/4.jpg",
        "/G-S12-BK/5.jpg",
      ]
    },
    video: {
      zh: "",
      en: "https://www.youtube.com/embed/iIUDXG8j1f0"
    },
    spec: {
      en: {
        model: "G-S12-BK",
        shape: "G",
        Top: "Solid Sitka Spruce",
        BackSides: "Solid Mahogany",
        Neck: pinen,
        Fingerboard: "Rosewood",
        brg: "Rosewood",
        Nut: "Bone",
        Saddle: "Bone",
        TuningMachine: "Gomans Die Cast Gold With Black Buttons",
        String: xsen,
        FinishTop: "Satin Relic",
        FinishNeck: "Satin",
        FinishBody: "Satin"
      },
      zh: {
        model: "G-S12-BK",
        shape: "G 型",
        Top: "西特卡云杉单板",
        BackSides: "桃花芯单板",
        Neck: pinzh,
        Fingerboard: "玫瑰木",
        brg: "玫瑰木",
        Nut: "牛骨",
        Saddle: "牛骨",
        TuningMachine: "金色全封闭黑色钮",
        String: xszh,
        FinishTop: "哑光 复古",
        FinishNeck: "哑光",
        FinishBody: "哑光"
      },
      fr: {
        model: "G-S12-BK",
        shape: "G",
        Top: "Epicéa Sitka Massif",
        BackSides: "Epicéa Acajou Massif",
        Neck: pinfr,
        Fingerboard: "Palissandre",
        brg: "Palissandre",
        Nut: "Os",
        Saddle: "Os",
        TuningMachine: "Gomans Die Cast Doré avec boutons noirs",
        String: xsen,
        FinishTop: "Satiné Reliques",
        FinishNeck: "Satiné",
        FinishBody: "Satiné"
      }
    }
  },
  G_S12CS: {
    series: "G",
    shape: "G",
    model: "G-S12CS",
    desc: {
      en: "",
      zh: "",
      fr: ""
    },
    aud: "/aud/G-S12CS GAUTHIER.mp3",
    ply: "Tak Chan",
    sti: "GAUTHIER",
    pics: {
      nav: "/G-S12CS/nav.jpg",
      head: "/G-S12CS/head.jpg",
      bg: "/G-S12CS/bg.jpg",
      special: [
        "/G-S12CS/1.jpg",
        "/G-S12CS/2.jpg",
        "/G-S12CS/3.jpg",
        "/G-S12CS/4.jpg",
        "/G-S12CS/5.jpg",
        "/G-S12CS/6.jpg",
        "/G-S12CS/7.jpg",
      ]
    },
    video: {
      zh: "",
      en: ""
    },
    spec: {
      en: {
        model: "G-S12CS",
        shape: "G",
        Top: "Solid Sitka Spruce",
        BackSides: "Solid Flammed Mahogany",
        Neck: pinen,
        Fingerboard: "Rosewood",
        brg: "Rosewood",
        Nut: "Bone",
        Saddle: "Bone",
        TuningMachine: "Gomans Die Cast Gold With Black Buttons",
        String: xsen,
        FinishTop: "Satin Relic",
        FinishNeck: "Satin",
        FinishBody: "Satin"
      },
      zh: {
        model: "G-S12CS",
        shape: "G 型",
        Top: "西特卡云杉单板",
        BackSides: "强影桃花芯单板",
        Neck: pinzh,
        Fingerboard: "玫瑰木",
        brg: "玫瑰木",
        Nut: "牛骨",
        Saddle: "牛骨",
        TuningMachine: "金色全封闭黑色钮",
        String: xszh,
        FinishTop: "哑光 复古",
        FinishNeck: "哑光",
        FinishBody: "哑光"
      },
      fr: {
        model: "G-S12CS",
        shape: "G",
        Top: "Epicéa Sitka Massif",
        BackSides: "Epicéa Flamme Acajou Massif",
        Neck: pinfr,
        Fingerboard: "Palissandre",
        brg: "Palissandre",
        Nut: "Os",
        Saddle: "Os",
        TuningMachine: "Gomans Die Cast Doré avec boutons noirs",
        String: xsen,
        FinishTop: "Satiné Reliques",
        FinishNeck: "Satiné",
        FinishBody: "Satiné"
      }
    }
  },
  G_S15CS: {
    series: "G",
    shape: "G",
    model: "G-S15CS",
    desc: {
      en: "",
      zh: "",
      fr: ""
    },
    pics: {
      nav: "/G-S15CS/nav.jpg",
      head: "/G-S15CS/head.jpg",
      bg: "/G-S15CS/bg.jpg",
      special: [
        "/G-S15CS/1.jpg",
        "/G-S15CS/2.jpg",
        "/G-S15CS/3.jpg",
        "/G-S15CS/4.jpg",
        "/G-S15CS/5.jpg",
        "/G-S15CS/6.jpg",
        "/G-S15CS/7.jpg",
      ]
    },
    video: {
      zh: "",
      en: ""
    },
    spec: {
      en: {
        model: "G-S15CS",
        shape: "G",
        Top: "Solid Alpine Moon Spruce",
        BackSides: "Solid Flammed Walnut",
        Neck: pinen,
        Fingerboard: "Ebony",
        brg: "Ebony",
        Nut: "Bone",
        Saddle: "Bone",
        TuningMachine: "Gotoh/Grover Die Cast Gold With Black Buttons",
        String: xsen,
        FinishTop: "Natural",
        FinishNeck: "Satin",
        FinishBody: "Gloss"
      },
      zh: {
        model: "G-S15CS",
        shape: "G 型",
        Top: "月亮云杉单板",
        BackSides: "强影胡桃木单板",
        Neck: pinzh,
        Fingerboard: "乌木",
        brg: "乌木",
        Nut: "牛骨",
        Saddle: "牛骨",
        TuningMachine: "Gotoh/Grover 金色全封闭黑色钮",
        String: xszh,
        FinishTop: "自然色",
        FinishNeck: "哑光",
        FinishBody: "亮光"
      },
      fr: {
        model: "G-S15CS",
        shape: "G",
        Top: "Alpine Moon Spruce Massif",
        BackSides: "Solide Flamme Noyer",
        Neck: pinfr,
        Fingerboard: "Ebène",
        brg: "Ebène",
        Nut: "Os",
        Saddle: "Os",
        TuningMachine: "Gotoh/Grover Die Cast Doré avec boutons noirs",
        String: xsen,
        FinishTop: "Nature",
        FinishNeck: "Satiné",
        FinishBody: "Brillant"
      }
    }
  },
  G_S16: {
    series: "G",
    shape: "G",
    model: "G-S16",
    desc: {
      en: "",
      zh: "Gomans全单红花梨木吉他G-S16，出声清丽，出色动人。",
      fr: ""
    },
    pics: {
      nav: "/G-S16/nav.jpg",
      head: "/G-S16/head.jpg",
      bg: "/G-S16/bg.jpg",
      special: [
        "/G-S16/1.jpg",
        "/G-S16/2.jpg",
        "/G-S16/3.jpg",
        "/G-S16/4.jpg",
        "/G-S16/5.jpg",
      ]
    },
    video: {
      zh: "//player.bilibili.com/player.html?bvid=BV1QW4y1h7gs&autoplay=0",
      en: "https://www.youtube.com/embed/IX0KJNKzMfM"
    },
    spec: {
      en: {
        model: "G-S16",
        shape: "G",
        Top: "Solid Sitka Spruce",
        BackSides: "Solid Padouk",
        Neck: pinen,
        Fingerboard: "Rosewood",
        brg: "Rosewood",
        Nut: "Bone",
        Saddle: "Bone",
        TuningMachine: gdcen,
        String: xsen,
        FinishTop: "Natural",
        FinishNeck: "Satin",
        FinishBody: "Gloss"
      },
      zh: {
        model: "G-S16",
        shape: "G 型",
        Top: "西特卡云杉单板",
        BackSides: "红花梨单板",
        Neck: pinzh,
        Fingerboard: "玫瑰木",
        brg: "玫瑰木",
        Nut: "牛骨",
        Saddle: "牛骨",
        TuningMachine: "金色全封闭带金色钮",
        String: xszh,
        FinishTop: "自然色",
        FinishNeck: "哑光",
        FinishBody: "亮光"
      },
      fr: {
        model: "G-S16",
        shape: "G",
        Top: "Epicéa Sitka Massif",
        BackSides: "Epicéa Padouk Massif",
        Neck: pinfr,
        Fingerboard: "Palissandre",
        brg: "Palissandre",
        Nut: "Os",
        Saddle: "Os",
        TuningMachine: "Gomans Die Cast Doré avec boutons dorés",
        String: xsen,
        FinishTop: "Nature",
        FinishNeck: "Satiné",
        FinishBody: "Brillant"
      }
    }
  },
  G_SA21CSB: {
    series: "G",
    shape: "G",
    model: "G-SA21CSB",
    desc: {
      en: "",
      zh: "",
      fr: ""
    },
    aud: "/aud/G-SA21CSB song for adam cover jackson browne.mp3",
    ply: "Cover Jackson Browne",
    sti: "Song for Adam",
    pics: {
      nav: "/G-SA21CSB/nav.jpg",
      head: "/G-SA21CSB/head.jpg",
      bg: "/G-SA21CSB/bg.jpg",
      special: [
        "/G-SA21CSB/1.jpg",
        "/G-SA21CSB/2.jpg",
        "/G-SA21CSB/3.jpg",
        "/G-SA21CSB/4.jpg",
        "/G-SA21CSB/5.jpg",
        "/G-SA21CSB/6.jpg"
      ]
    },
    video: {
      zh: "",
      en: ""
    },
    spec: {
      en: {
        model: "G-SA21CSB",
        shape: "G",
        Top: "Solid Flammed Maple",
        BackSides: "Solid Flammed Maple",
        Neck: "5 Pieces Mahogany/Maple",
        Fingerboard: "Maple",
        brg: "Solid Maple",
        Nut: "Bone",
        Saddle: "Bone",
        TuningMachine: "Gotoh/Grover Die Cast Gold With Black Buttons",
        String: xsen,
        FinishTop: "Natural",
        FinishNeck: "Satin",
        FinishBody: "Gloss"
      },
      zh: {
        model: "G-SA21CSB",
        shape: "G 型",
        Top: "强影枫木单板",
        BackSides: "强影枫木单板",
        Neck: "五拼一体 桃花芯/枫木",
        Fingerboard: "枫木",
        brg: "枫木",
        Nut: "牛骨",
        Saddle: "牛骨",
        TuningMachine: "Gotoh/Grover 金色全封闭黑色钮",
        String: xszh,
        FinishTop: "自然色",
        FinishNeck: "哑光",
        FinishBody: "亮光"
      },
      fr: {
        model: "G-SA21CSB",
        shape: "G",
        Top: "Solide Flamme Erable",
        BackSides: "Solide Flamme Erable",
        Neck: "5 piéces Acajou/Erable",
        Fingerboard: "Erable",
        brg: "Erable",
        Nut: "Os",
        Saddle: "Os",
        TuningMachine: "Gotoh/Grover Die Cast Doré avec boutons noirs",
        String: xsen,
        FinishTop: "Nature",
        FinishNeck: "Satiné",
        FinishBody: "Brillant"
      }
    }
  },
  G_S21DCTB: {
    series: "G",
    shape: "G",
    model: "G-S21DCTB",
    desc: {
      en: "",
      zh: "",
      fr: ""
    },
    aud: "/aud/G-S21DCTB.mp3",
    ply: "Tak Chan",
    sti: "GOBAIN DANS LE FORET",
    pics: {
      nav: "/G-S21DCTB/nav.jpg",
      bg: "/G-S21DCTB/bg.jpg",
      special: [
        "/G-S21DCTB/1.jpg",
        "/G-S21DCTB/2.jpg",
        "/G-S21DCTB/3.jpg",
        "/G-S21DCTB/4.jpg",
        "/G-S21DCTB/5.jpg",
        "/G-S21DCTB/6.jpg",
        "/G-S21DCTB/7.jpg",
      ]
    },
    video: {
      zh: "",
      en: ""
    },
    spec: {
      en: {
        model: "G-S21DCTB",
        shape: "G",
        Top: "Solid Alpine Moon Spruce",
        BackSides: "Solid Maple",
        Neck: "5 Pieces Maple/Rosewood",
        Fingerboard: "Maple",
        brg: "Ebony",
        Nut: "Bone",
        Saddle: "Bone",
        TuningMachine: "Gotoh/Grover Die Cast Gold With Gold Buttons",
        String: xsen,
        FinishTop: "Natural",
        FinishNeck: "Satin",
        FinishBody: "Gloss"
      },
      zh: {
        model: "G-S21DCTB",
        shape: "G 型",
        Top: "月亮云杉单板 ",
        BackSides: "枫木单板",
        Neck: "五拼一体 枫木/玫瑰木",
        Fingerboard: "枫木",
        brg: "乌木",
        Nut: "牛骨",
        Saddle: "牛骨",
        TuningMachine: "Gotoh/Grover 金色全封闭",
        String: xszh,
        FinishTop: "自然色",
        FinishNeck: "哑光",
        FinishBody: "亮光"
      },
      fr: {
        model: "G-S21DCTB",
        shape: "G",
        Top: "Epicéa Alpine Massif ",
        BackSides: "Erable Massif",
        Neck: "5 piéces Erable/Palissandre",
        Fingerboard: "Erable",
        brg: "Ebène",
        Nut: "Os",
        Saddle: "Os",
        TuningMachine: "Gotoh/Grover Die Cast Doré avec boutons dorés",
        String: xsen,
        FinishTop: "Nature",
        FinishNeck: "Satiné",
        FinishBody: "Brillant"
      }
    }
  },
  G_S22C: {
    series: "G",
    shape: "G",
    model: "G-S22C",
    desc: {
      en: "",
      zh: "",
      fr: ""
    },
    pics: {
      nav: "/G-S22C/nav.jpg",
      bg: "/G-S22C/bg.jpg",
      special: [
        "/G-S22C/1.jpg",
        "/G-S22C/2.jpg",
        "/G-S22C/3.jpg",
        "/G-S22C/4.jpg",
        "/G-S22C/5.jpg",
        "/G-S22C/6.jpg",
      ]
    },
    video: {
      zh: "",
      en: ""
    },
    spec: {
      en: {
        model: "G-S22C",
        shape: "G",
        Top: "Solid Alpine Moon Spruce",
        BackSides: "Solid Santos Rosewood",
        Neck: pinen,
        Fingerboard: "Ebony",
        brg: "Ebony",
        Nut: "Bone",
        Saddle: "Bone",
        TuningMachine: "Gotoh/Grover Die Cast Gold With Black Button",
        String: xsen,
        FinishTop: "Natural",
        FinishNeck: "Satin",
        FinishBody: "Gloss"
      },
      zh: {
        model: "G-S22C",
        shape: "G 型",
        Top: "月亮云杉单板",
        BackSides: "桑托斯玫瑰木单板",
        Neck: pinzh,
        Fingerboard: "乌木",
        brg: "乌木",
        Nut: "牛骨",
        Saddle: "牛骨",
        TuningMachine: "Gotoh/Grover 金色全封闭带黑色钮",
        String: xszh,
        FinishTop: "自然色",
        FinishNeck: "哑光",
        FinishBody: "亮光"
      },
      fr: {
        model: "G-S22C",
        shape: "G",
        Top: "Alpine Moon Spruce Massif",
        BackSides: "Epicéa Palissandre De Santos Massif",
        Neck: pinfr,
        Fingerboard: "Ebène",
        brg: "Ebène",
        Nut: "Os",
        Saddle: "Os",
        TuningMachine: "Gotoh/Grover Die Cast Doré avec boutons noirs",
        String: xsen,
        FinishTop: "Nature",
        FinishNeck: "Satiné",
        FinishBody: "Brillant"
      }
    }
  },
  G_S22C_PinkLady: {
    series: "G",
    shape: "G",
    model: "G-S22C PinkLady",
    desc: {
      en: "",
      zh: "",
      fr: ""
    },
    aud: "/aud/G-S22C PINK LADY final.mp3",
    ply: "Tak Chan",
    sti: "WALKING",
    pics: {
      nav: "/G-S22C_PinkLady/nav.jpg",
      head: "/G-S22C_PinkLady/head.jpg",
      bg: "/G-S22C_PinkLady/bg.jpg",
      special: [
        "/G-S22C_PinkLady/1.jpg",
        "/G-S22C_PinkLady/2.jpg",
        "/G-S22C_PinkLady/3.jpg",
        "/G-S22C_PinkLady/4.jpg",
        "/G-S22C_PinkLady/5.jpg",
        "/G-S22C_PinkLady/6.jpg",
        "/G-S22C_PinkLady/7.jpg",
      ]
    },
    video: {
      zh: "",
      en: ""
    },
    spec: {
      en: {
        model: "G-S22C Pink Lady",
        shape: "G",
        Top: "Solid Alpine Moon Spruce",
        BackSides: "Solid Santos Rosewood",
        Neck: pinen,
        Fingerboard: "Maple",
        brg: "Ebony",
        Nut: "Bone",
        Saddle: "Bone",
        TuningMachine: "Gotoh/Grover Die Cast Gold With Black Button",
        String: xsen,
        FinishTop: "Natural",
        FinishNeck: "Satin",
        FinishBody: "Gloss"
      },
      zh: {
        model: "G-S22C Pink Lady",
        shape: "G 型",
        Top: "月亮云杉单板",
        BackSides: "桑托斯玫瑰木单板",
        Neck: pinzh,
        Fingerboard: "枫木",
        brg: "乌木",
        Nut: "牛骨",
        Saddle: "牛骨",
        TuningMachine: "Gotoh/Grover 金色全封闭带黑色钮",
        String: xszh,
        FinishTop: "自然色",
        FinishNeck: "哑光",
        FinishBody: "亮光"
      },
      fr: {
        model: "G-S22C Pink Lady",
        shape: "G",
        Top: "Alpine Moon Spruce Massif",
        BackSides: "Epicéa Palissandre De Santos Massif",
        Neck: pinfr,
        Fingerboard: "Erable",
        brg: "Ebène",
        Nut: "Os",
        Saddle: "Os",
        TuningMachine: "Gotoh/Grover Die Cast Doré avec boutons noirs",
        String: xsen,
        FinishTop: "Nature",
        FinishNeck: "Satiné",
        FinishBody: "Brillant"
      }
    }
  },
  G_S22AC: {
    series: "G",
    shape: "G",
    model: "G-S22AC",
    desc: {
      en: "",
      zh: "",
      fr: ""
    },
    aud: "/aud/G-S22AC IF I WERE A CARPENTER COVER.mp3",
    ply: "Tak Chan",
    sti: "IF I WERE A CARPENTER COVER",
    pics: {
      nav: "/G-S22AC/nav.jpg",
      head: "/G-S22AC/head.jpg",
      bg: "/G-S22AC/bg.jpg",
      special: [
        "/G-S22AC/1.jpg",
        "/G-S22AC/2.jpg",
        "/G-S22AC/3.jpg",
        "/G-S22AC/4.jpg",
        "/G-S22AC/5.jpg",
        "/G-S22AC/6.jpg",
        "/G-S22AC/7.jpg",
      ]
    },
    video: {
      zh: "",
      en: ""
    },
    spec: {
      en: {
        model: "G-S22AC",
        shape: "G",
        Top: "Solid Alpine Moon Spruce",
        BackSides: "Solid Santos Rosewood",
        Neck: pinen,
        Fingerboard: "Ebony",
        brg: "Ebony",
        Nut: "Bone",
        Saddle: "Bone",
        TuningMachine: "Gotoh/Grover Die Cast Gold With Black Button",
        String: xsen,
        FinishTop: "Natural",
        FinishNeck: "Satin",
        FinishBody: "Gloss"
      },
      zh: {
        model: "G-S22AC",
        shape: "G 型",
        Top: "月亮云杉单板",
        BackSides: "桑托斯玫瑰木单板",
        Neck: pinzh,
        Fingerboard: "乌木",
        brg: "乌木",
        Nut: "牛骨",
        Saddle: "牛骨",
        TuningMachine: "Gotoh/Grover 金色全封闭带黑色钮",
        String: xszh,
        FinishTop: "自然色",
        FinishNeck: "哑光",
        FinishBody: "亮光"
      },
      fr: {
        model: "G-S22AC",
        shape: "G",
        Top: "Alpine Moon Spruce Massif",
        BackSides: "Epicéa Palissandre De Santos Massif",
        Neck: pinfr,
        Fingerboard: "Ebène",
        brg: "Ebène",
        Nut: "Os",
        Saddle: "Os",
        TuningMachine: "Gotoh/Grover Die Cast Doré avec boutons noirs",
        String: xsen,
        FinishTop: "Nature",
        FinishNeck: "Satiné",
        FinishBody: "Brillant"
      }
    }
  },
  GC_S22AC_DLX: {
    series: "DLX",
    shape: "G",
    model: "GC-S22AC DLX",
    desc: {
      en: "",
      zh: "",
      fr: ""
    },
    pics: {
      nav: "/G-S22AC_DLX/nav.jpg",
      head: "/G-S22AC_DLX/head.jpg",
      bg: "/G-S22AC_DLX/bg.jpg",
      special: [
        "/G-S22AC_DLX/1.jpg",
        "/G-S22AC_DLX/2.jpg",
        "/G-S22AC_DLX/3.jpg",
        "/G-S22AC_DLX/4.jpg",
        "/G-S22AC_DLX/5.jpg",
        "/G-S22AC_DLX/6.jpg",
        "/G-S22AC_DLX/7.jpg",
        "/G-S22AC_DLX/8.jpg",
      ]
    },
    video: {
      zh: "",
      en: ""
    },
    spec: {
      en: {
        model: "GC-S22AC DLX",
        shape: "G",
        Top: "Solid Alpine Moon Spruce",
        BackSides: "Solid Santos Rosewood",
        Neck: pinen,
        Fingerboard: "Ebony",
        brg: "Ebony",
        Nut: "Bone",
        Saddle: "Bone",
        TuningMachine: "Gotoh/Grover Die Cast Gold With Black Button",
        String: xsen,
        FinishTop: "Natural",
        FinishNeck: "Satin",
        FinishBody: "Gloss"
      },
      zh: {
        model: "GC-S22AC DLX",
        shape: "G 型",
        Top: "月亮云杉单板",
        BackSides: "桑托斯玫瑰木单板",
        Neck: pinzh,
        Fingerboard: "乌木",
        brg: "乌木",
        Nut: "牛骨",
        Saddle: "牛骨",
        TuningMachine: "Gotoh/Grover 金色全封闭带黑色钮",
        String: xszh,
        FinishTop: "自然色",
        FinishNeck: "哑光",
        FinishBody: "亮光"
      },
      fr: {
        model: "GC-S22AC DLX",
        shape: "G",
        Top: "Alpine Moon Spruce Massif",
        BackSides: "Epicéa Palissandre De Santos Massif",
        Neck: pinfr,
        Fingerboard: "Ebène",
        brg: "Ebène",
        Nut: "Os",
        Saddle: "Os",
        TuningMachine: "Gotoh/Grover Die Cast Doré avec boutons noirs",
        String: xsen,
        FinishTop: "Nature",
        FinishNeck: "Satiné",
        FinishBody: "Brillant"
      }
    }
  },
  G_S18AC_KP: {
    series: "G",
    shape: "G",
    model: "G-S18AC-KP",
    desc: {
      en: "",
      zh: "",
      fr: ""
    },
    aud: "/aud/G-S18AC KOPO MERCI A FRED.mp3",
    ply: "Tak Chan",
    sti: "MERCI A FRED",
    pics: {
      nav: "/G-S18AC-KP/nav.jpg",
      head: "/G-S18AC-KP/head.jpg",
      bg: "/G-S18AC-KP/bg.jpg",
      special: [
        "/G-S18AC-KP/1.jpg",
        "/G-S18AC-KP/2.jpg",
        "/G-S18AC-KP/3.jpg",
        "/G-S18AC-KP/4.jpg",
        "/G-S18AC-KP/5.jpg",
        "/G-S18AC-KP/6.jpg",
        "/G-S18AC-KP/7.jpg",
      ]
    },
    video: {
      zh: "",
      en: ""
    },
    spec: {
      en: {
        model: "G-S18AC-KP",
        shape: "G",
        Top: "Solid Sitka Spruce",
        BackSides: "Solid Indian Rosewood",
        Neck: pinen,
        Fingerboard: "Ebony",
        brg: "Ebony",
        Nut: "Bone",
        Saddle: "Bone",
        TuningMachine: "Gomans Die Cast Gold With Black Buttons",
        String: xsen,
        FinishTop: "Natural",
        FinishNeck: "Satin",
        FinishBody: "Gloss"
      },
      zh: {
        model: "G-S18AC-KP",
        shape: "G 型",
        Top: "西特卡云杉单板",
        BackSides: "玫瑰木单板",
        Neck: pinzh,
        Fingerboard: "乌木",
        brg: "乌木",
        Nut: "牛骨",
        Saddle: "牛骨",
        TuningMachine: "金色全封闭带黑色钮",
        String: xszh,
        FinishTop: "自然色",
        FinishNeck: "哑光",
        FinishBody: "亮光"
      },
      fr: {
        model: "G-S18AC-KP",
        shape: "G",
        Top: "Epicéa Sitka Massif",
        BackSides: "Epicéa Palissandre Massif",
        Neck: pinfr,
        Fingerboard: "Ebène",
        brg: "Ebène",
        Nut: "Os",
        Saddle: "Os",
        TuningMachine: "Gomans Die Cast Doré avec boutons noirs",
        String: xsen,
        FinishTop: "Nature",
        FinishNeck: "Satiné",
        FinishBody: "Brillant"
      }
    }
  },
  J12_S12AC: {
    series: "J12",
    shape: "J",
    model: "J12-S12AC",
    desc: {
      en: "",
      zh: "",
      fr: ""
    },
    pics: {
      nav: "/J12-S12AC/nav.jpg",
      head: "/J12-S12AC/head.jpg",
      bg: "/J12-S12AC/bg.jpg",
      special: [
        "/J12-S12AC/1.jpg",
        "/J12-S12AC/2.jpg",
        "/J12-S12AC/3.jpg",
        "/J12-S12AC/4.jpg",
        "/J12-S12AC/5.jpg",
        "/J12-S12AC/6.jpg"
      ]
    },
    video: {
      zh: "",
      en: ""
    },
    spec: {
      en: {
        model: "J12-S12AC",
        shape: "J",
        Top: "Solid Sidka Spruce ",
        BackSides: "Solid Mahogany",
        Neck: pinen,
        Fingerboard: "Indian Rosewood",
        brg: "Indian Rosewood",
        Nut: "Bone",
        Saddle: "Bone",
        TuningMachine: "Die Cast Gold With Gold Buttons",
        String: "D'Addario EXP38",
        FinishTop: "Natural",
        FinishNeck: "Satin",
        FinishBody: "Gloss"
      },
      zh: {
        model: "J12-S12AC",
        shape: "J 型",
        Top: "西特卡云杉单板",
        BackSides: "桃花芯单板",
        Neck: pinzh,
        Fingerboard: "玫瑰木",
        brg: "玫瑰木",
        Nut: "牛骨",
        Saddle: "牛骨",
        TuningMachine: "金色全封闭带金色钮",
        String: "达达里奥 EXP38",
        FinishTop: "自然色",
        FinishNeck: "哑光",
        FinishBody: "亮光"
      },
      fr: {
        model: "J12-S12AC",
        shape: "J",
        Top: "Epicéa Sitka Massif ",
        BackSides: "Epicéa Acajou Massif",
        Neck: pinfr,
        Fingerboard: "Palissandre",
        brg: "Palissandre",
        Nut: "Os",
        Saddle: "Os",
        TuningMachine: "Die Cast Doré avec boutons dorés",
        String: "D'Addario EXP38",
        FinishTop: "Nature",
        FinishNeck: "Satiné",
        FinishBody: "Brillant"
      }
    }
  },
  J_S12FC: {
    series: "J",
    shape: "J",
    model: "J-S12FC",
    desc: {
      en: "",
      zh: "",
      fr: ""
    },
    pics: {
      nav: "/J-S12FC/nav.jpg",
      head: "/J-S12FC/head.jpg",
      bg: "/J-S12FC/bg.jpg",
      special: [
        "/J-S12FC/1.jpg",
        "/J-S12FC/2.jpg",
        "/J-S12FC/3.jpg",
        "/J-S12FC/4.jpg",
        "/J-S12FC/5.jpg",
        "/J-S12FC/6.jpg"
      ]
    },
    video: {
      zh: "",
      en: ""
    },
    spec: {
      en: {
        model: "J-S12FC",
        shape: "J",
        Top: "Solid Sitka Spruce  ",
        BackSides: "Solid Mahogany",
        Neck: pinen,
        Fingerboard: "Indian Rosewood",
        brg: "Indian Rosewood",
        Nut: "Bone",
        Saddle: "Bone",
        TuningMachine: gdcen,
        String: xsen,
        FinishTop: "Natural",
        FinishNeck: "Satin",
        FinishBody: "Gloss"
      },
      zh: {
        model: "J-S12FC",
        shape: "J 型",
        Top: "西特卡云杉单板  ",
        BackSides: "桃花芯单板",
        Neck: pinzh,
        Fingerboard: "玫瑰木",
        brg: "玫瑰木",
        Nut: "牛骨",
        Saddle: "牛骨",
        TuningMachine: "金色全封闭带金色钮",
        String: xszh,
        FinishTop: "自然色",
        FinishNeck: "哑光",
        FinishBody: "亮光"
      },
      fr: {
        model: "J-S12FC",
        shape: "J",
        Top: "Epicéa Sitka Massif ",
        BackSides: "Epicéa Acajou Massif",
        Neck: pinfr,
        Fingerboard: "Palissandre Indien",
        brg: "Palissandre Indien",
        Nut: "Os",
        Saddle: "Os",
        TuningMachine: "Gomans Die Cast Doré avec boutons dorés",
        String: xsen,
        FinishTop: "Nature",
        FinishNeck: "Satiné",
        FinishBody: "Brillant"
      }
    }
  },
  J_S18FC: {
    series: "J",
    shape: "J",
    model: "J-S18FC",
    desc: {
      en: "",
      zh: "",
      fr: ""
    },
    pics: {
      nav: "/J-S18FC/nav.jpg",
      head: "/J-S18FC/head.jpg",
      bg: "/J-S18FC/bg.jpg",
      special: [
        "/J-S18FC/1.jpg",
        "/J-S18FC/2.jpg",
        "/J-S18FC/3.jpg",
        "/J-S18FC/4.jpg",
        "/J-S18FC/5.jpg",
        "/J-S18FC/6.jpg"
      ]
    },
    video: {
      zh: "//player.bilibili.com/player.html?aid=854281281&bvid=BV1f54y1Z7wv&cid=728405441&page=1&autoplay=0",
      en: "https://www.youtube.com/embed/VsWE0kGW6wo"
    },
    spec: {
      en: {
        model: "J-S18FC",
        shape: "J",
        Top: "Solid Alpine Moon Spruce",
        BackSides: "Solid Indian Rosewood",
        Neck: pinen,
        Fingerboard: "Ebony",
        brg: "Ebony",
        Nut: "Bone",
        Saddle: "Bone",
        TuningMachine: "Gomans Die Cast Gold With Black Buttons",
        String: xsen,
        FinishTop: "Natural",
        FinishNeck: "Satin",
        FinishBody: "Gloss"
      },
      zh: {
        model: "J-S18FC",
        shape: "J 型",
        Top: "月亮云杉单板",
        BackSides: "玫瑰木单板",
        Neck: pinzh,
        Fingerboard: "乌木",
        brg: "乌木",
        Nut: "牛骨",
        Saddle: "牛骨",
        TuningMachine: "金色全封闭带黑色钮",
        String: xszh,
        FinishTop: "自然色",
        FinishNeck: "哑光",
        FinishBody: "亮光"
      },
      fr: {
        model: "J-S18FC",
        shape: "J",
        Top: "Epicéa Alpine Massif",
        BackSides: "Epicéa Palissandre Massif",
        Neck: pinfr,
        Fingerboard: "Ebène",
        brg: "Ebène",
        Nut: "Os",
        Saddle: "Os",
        TuningMachine: "Gomans Die Cast Doré avec boutons noirs",
        String: xsen,
        FinishTop: "Nature",
        FinishNeck: "Satiné",
        FinishBody: "Brillant"
      }
    }
  },
  J_S19FC: {
    series: "J",
    shape: "J",
    model: "J-S19FC",
    desc: {
      en: "",
      zh: "",
      fr: ""
    },
    aud: "/aud/J-S19FC ANTHEM AND TLM 102.mp3",
    ply: "Tak Chan",
    sti: "ANTHEM AND TLM 102",
    pics: {
      nav: "/J-S19FC/nav.jpg",
      head: "/J-S19FC/head.jpg",
      bg: "/J-S19FC/bg.jpg",
      special: [
        "/J-S19FC/1.jpg",
        "/J-S19FC/2.jpg",
        "/J-S19FC/3.jpg",
        "/J-S19FC/4.jpg",
        "/J-S19FC/5.jpg",
        "/J-S19FC/6.jpg",
        "/J-S19FC/7.jpg",
        "/J-S19FC/8.jpg",
        "/J-S19FC/9.jpg",
      ]
    },
    video: {
      zh: "",
      en: ""
    },
    spec: {
      en: {
        model: "J-S19FC",
        shape: "J",
        Top: "Solid Red Cedar",
        BackSides: "Solid Acacia Koa",
        Neck: pinen,
        Fingerboard: "Ebony",
        brg: "Ebony",
        Nut: "Bone",
        Saddle: "Bone",
        TuningMachine: "Gotoh/Grover Die Cast Gold With Black Button",
        String: xsen,
        FinishTop: "Natural",
        FinishNeck: "Satin",
        FinishBody: "Gloss"
      },
      zh: {
        model: "J-S19FC",
        shape: "J 型",
        Top: "红松单板",
        BackSides: "相思木单板",
        Neck: pinzh,
        Fingerboard: "乌木",
        brg: "乌木",
        Nut: "牛骨",
        Saddle: "牛骨",
        TuningMachine: "Gotoh/Grover 金色全封闭带黑色钮",
        String: xszh,
        FinishTop: "自然色",
        FinishNeck: "哑光",
        FinishBody: "亮光"
      },
      fr: {
        model: "J-S19FC",
        shape: "J",
        Top: "Cèdre Rouge Massif",
        BackSides: "Acacia Koa Massif",
        Neck: pinfr,
        Fingerboard: "Ebène",
        brg: "Ebène",
        Nut: "Os",
        Saddle: "Os",
        TuningMachine: "Gotoh/Grover Die Cast Doré avec boutons noirs",
        String: xsen,
        FinishTop: "Nature",
        FinishNeck: "Satiné",
        FinishBody: "Brillant"
      }
    }
  },
  J_S38AC_DLX: {
    series: "DLX",
    shape: "J",
    model: "J-S38AC DLX",
    desc: {
      en: "",
      zh: "",
      fr: ""
    },
    pics: {
      nav: "/J-S38AC_DLX/nav.jpg",
      head: "/J-S38AC_DLX/head.jpg",
      bg: "/J-S38AC_DLX/bg.jpg",
      special: [
        "/J-S38AC_DLX/1.jpg",
        "/J-S38AC_DLX/2.jpg",
        "/J-S38AC_DLX/3.jpg",
        "/J-S38AC_DLX/4.jpg",
        "/J-S38AC_DLX/5.jpg",
        "/J-S38AC_DLX/6.jpg",
        "/J-S38AC_DLX/7.jpg",
        "/J-S38AC_DLX/8.jpg",
      ]
    },
    video: {
      zh: "",
      en: ""
    },
    spec: {
      en: {
        model: "J-S38AC DLX",
        shape: "J",
        Top: "Solid Alpine Moon Spruce",
        BackSides: "Solid Cocobolo",
        Neck: pinen,
        Fingerboard: "Ebony",
        brg: "Ebony",
        Nut: "Bone",
        Saddle: "Bone",
        TuningMachine: "Gotoh/Grover Die Cast Gold With Gold Buttons",
        String: xsen,
        FinishTop: "Natural",
        FinishNeck: "Satin",
        FinishBody: "Gloss"
      },
      zh: {
        model: "J-S38AC DLX",
        shape: "J 型",
        Top: "月亮云杉单板",
        BackSides: "可可菠萝单板",
        Neck: pinzh,
        Fingerboard: "乌木",
        brg: "乌木",
        Nut: "牛骨",
        Saddle: "牛骨",
        TuningMachine: "Gotoh/Grover 金色全封闭",
        String: xszh,
        FinishTop: "自然色",
        FinishNeck: "哑光",
        FinishBody: "亮光"
      },
      fr: {
        model: "J-S38AC DLX",
        shape: "J",
        Top: "Epicéa Alpine Massif",
        BackSides: "Cocobolo Massif",
        Neck: pinfr,
        Fingerboard: "Ebène",
        brg: "Ebène",
        Nut: "Os",
        Saddle: "Os",
        TuningMachine: "Gotoh/Grover Die Cast Doré avec boutons dorés",
        String: xsen,
        FinishTop: "Nature",
        FinishNeck: "Satiné",
        FinishBody: "Brillant"
      }
    }
  },
  J_S38FAC_DLX: {
    series: "DLX",
    shape: "J",
    model: "J-S38FAC DLX",
    desc: {
      en: "",
      zh: "",
      fr: ""
    },
    pics: {
      nav: "/J-S38FAC_DLX/nav.jpg",
      head: "/J-S38FAC_DLX/head.jpg",
      bg: "/J-S38FAC_DLX/bg.jpg",
      special: [
        "/J-S38FAC_DLX/1.jpg",
        "/J-S38FAC_DLX/2.jpg",
        "/J-S38FAC_DLX/3.jpg",
        "/J-S38FAC_DLX/4.jpg",
        "/J-S38FAC_DLX/5.jpg",
        "/J-S38FAC_DLX/6.jpg",
        "/J-S38FAC_DLX/7.jpg",
      ]
    },
    video: {
      zh: "",
      en: ""
    },
    spec: {
      en: {
        model: "J-S38FAC DLX",
        shape: "J",
        Top: "Solid Alpine Moon Spruce",
        BackSides: "Solid Cocobolo",
        Neck: pinen,
        Fingerboard: "Ebony",
        brg: "Ebony",
        Nut: "Bone",
        Saddle: "Bone",
        TuningMachine: "Gotoh/Grover Die Cast Gold With Gold Buttons",
        String: xsen,
        FinishTop: "Natural",
        FinishNeck: "Satin",
        FinishBody: "Gloss"
      },
      zh: {
        model: "J-S38FAC DLX",
        shape: "J 型",
        Top: "月亮云杉单板",
        BackSides: "可可菠萝单板",
        Neck: pinzh,
        Fingerboard: "乌木",
        brg: "乌木",
        Nut: "牛骨",
        Saddle: "牛骨",
        TuningMachine: "Gotoh/Grover 金色全封闭",
        String: xszh,
        FinishTop: "自然色",
        FinishNeck: "哑光",
        FinishBody: "亮光"
      },
      fr: {
        model: "J-S38FAC DLX",
        shape: "J",
        Top: "Epicéa Alpine Massif",
        BackSides: "Cocobolo Massif",
        Neck: pinfr,
        Fingerboard: "Ebène",
        brg: "Ebène",
        Nut: "Os",
        Saddle: "Os",
        TuningMachine: "Gotoh/Grover Die Cast Doré avec boutons dorés",
        String: xsen,
        FinishTop: "Nature",
        FinishNeck: "Satiné",
        FinishBody: "Brillant"
      }
    }
  },
  J_S38FC: {
    series: "J",
    shape: "J",
    model: "J-S38FC",
    desc: {
      en: "",
      zh: "",
      fr: ""
    },
    pics: {
      nav: "/J-S38FC/nav.jpg",
      head: "/J-S38FC/head.jpg",
      bg: "/J-S38FC/bg.jpg",
      special: [
        "/J-S38FC/1.jpg",
        "/J-S38FC/2.jpg",
        "/J-S38FC/3.jpg",
        "/J-S38FC/4.jpg",
        "/J-S38FC/5.jpg",
        "/J-S38FC/6.jpg"
      ]
    },
    video: {
      zh: "//player.bilibili.com/player.html?bvid=BV1nB4y1L7wK&autoplay=0",
      en: "https://www.youtube.com/embed/-BAmy9QJ8ig"
    },
    spec: {
      en: {
        model: "J-S38FC",
        shape: "J",
        Top: "Solid Alpine Moon Spruce",
        BackSides: "Solid Cocobolo",
        Neck: pinen,
        Fingerboard: "Ebony",
        brg: "Ebony",
        Nut: "Bone",
        Saddle: "Bone",
        TuningMachine: "Gotoh/Grover Die Cast Gold With Gold Buttons",
        String: xsen,
        FinishTop: "Natural",
        FinishNeck: "Satin",
        FinishBody: "Gloss"
      },
      zh: {
        model: "J-S38FC",
        shape: "J 型",
        Top: "月亮云杉单板",
        BackSides: "可可菠萝单板",
        Neck: pinzh,
        Fingerboard: "乌木",
        brg: "乌木",
        Nut: "牛骨",
        Saddle: "牛骨",
        TuningMachine: "Gotoh/Grover 金色全封闭",
        String: xszh,
        FinishTop: "自然色",
        FinishNeck: "哑光",
        FinishBody: "亮光"
      },
      fr: {
        model: "J-S38FC",
        shape: "J",
        Top: "Epicéa Alpine Massif",
        BackSides: "Epicéa Cocobolo Massif",
        Neck: pinfr,
        Fingerboard: "Ebène",
        brg: "Ebène",
        Nut: "Os",
        Saddle: "Os",
        TuningMachine: "Gotoh/Grover Die Cast Doré avec boutons dorés",
        String: xsen,
        FinishTop: "Nature",
        FinishNeck: "Satiné",
        FinishBody: "Brillant"
      }
    }
  },
  o000_S30AC: {
    series: "DLX",
    shape: "000",
    model: "000-S30AC",
    desc: {
      en: "",
      zh: "",
      fr: ""
    },
    pics: {
      nav: "/000-S30AC/nav.jpg",
      head: "/000-S30AC/head.jpg",
      bg: "/000-S30AC/bg.jpg",
      special: [
        "/000-S30AC/1.jpg",
        "/000-S30AC/2.jpg",
        "/000-S30AC/3.jpg",
        "/000-S30AC/4.jpg",
        "/000-S30AC/5.jpg",
        "/000-S30AC/6.jpg",
        "/000-S30AC/7.jpg",
      ]
    },
    video: {
      zh: "",
      en: ""
    },
    spec: {
      en: {
        model: "000-S30AC",
        shape: "000",
        Top: "Solid Alpine Moon Spruce",
        BackSides: "Solid Ziricote",
        Neck: pinen,
        Fingerboard: "Ebony",
        brg: "Ebony",
        Nut: "Bone",
        Saddle: "Bone",
        TuningMachine: "Gotoh/Grover Die Cast Gold With Black Buttons",
        String: xsen,
        FinishTop: "Natural",
        FinishNeck: "Satin",
        FinishBody: "Gloss"
      },
      zh: {
        model: "000-S30AC",
        shape: "000 型",
        Top: "月亮云杉单板",
        BackSides: "破布木单板",
        Neck: pinzh,
        Fingerboard: "乌木",
        brg: "乌木",
        Nut: "牛骨",
        Saddle: "牛骨",
        TuningMachine: "Gotoh/Grover 金色全封闭黑色钮",
        String: xszh,
        FinishTop: "自然色",
        FinishNeck: "哑光",
        FinishBody: "亮光"
      },
      fr: {
        model: "000-S30AC",
        shape: "000",
        Top: "Alpine Moon Spruce Massif",
        BackSides: "Solid Ziricote",
        Neck: pinfr,
        Fingerboard: "Ebène",
        brg: "Ebène",
        Nut: "Os",
        Saddle: "Os",
        TuningMachine: "Gotoh/Grover Die Cast Doré avec boutons noirs",
        String: xsen,
        FinishTop: "Nature",
        FinishNeck: "Satiné",
        FinishBody: "Brillant"
      }
    }
  },
  G_S30AC_DLX: {
    series: "DLX",
    shape: "G",
    model: "G-S30AC DLX",
    desc: {
      en: "",
      zh: "",
      fr: ""
    },
    pics: {
      nav: "/G-S30AC_DLX/nav.jpg",
      head: "/G-S30AC_DLX/head.jpg",
      bg: "/G-S30AC_DLX/bg.jpg",
      special: [
        "/G-S30AC_DLX/1.jpg",
        "/G-S30AC_DLX/2.jpg",
        "/G-S30AC_DLX/3.jpg",
        "/G-S30AC_DLX/4.jpg",
        "/G-S30AC_DLX/5.jpg",
        "/G-S30AC_DLX/6.jpg"
      ]
    },
    video: {
      zh: "",
      en: ""
    },
    spec: {
      en: {
        model: "G-S30AC DLX",
        shape: "G",
        Top: "Solid Alpine Moon Spruce",
        BackSides: "Solid Ziricote",
        Neck: pinen,
        Fingerboard: "Ebony",
        brg: "Ebony",
        Nut: "Bone",
        Saddle: "Bone",
        TuningMachine: "Gotoh/Grover Die Cast Gold With Black Buttons",
        String: xsen,
        FinishTop: "Natural",
        FinishNeck: "Satin",
        FinishBody: "Gloss"
      },
      zh: {
        model: "G-S30AC DLX",
        shape: "G 型",
        Top: "月亮云杉单板",
        BackSides: "破布木单板",
        Neck: pinzh,
        Fingerboard: "乌木",
        brg: "乌木",
        Nut: "牛骨",
        Saddle: "牛骨",
        TuningMachine: "Gotoh/Grover 金色全封闭黑色钮",
        String: xszh,
        FinishTop: "自然色",
        FinishNeck: "哑光",
        FinishBody: "亮光"
      },
      fr: {
        model: "G-S30AC DLX",
        shape: "G",
        Top: "Alpine Moon Spruce Massif",
        BackSides: "Solid Ziricote",
        Neck: pinfr,
        Fingerboard: "Ebène",
        brg: "Ebène",
        Nut: "Os",
        Saddle: "Os",
        TuningMachine: "Gotoh/Grover Die Cast Doré avec boutons noirs",
        String: xsen,
        FinishTop: "Nature",
        FinishNeck: "Satiné",
        FinishBody: "Brillant"
      }
    }
  },
  G_S30AC: {
    series: "G",
    shape: "G",
    model: "G-S30AC",
    desc: {
      en: "",
      zh: "",
      fr: ""
    },
    pics: {
      nav: "/G-S30AC/nav.jpg",
      head: "/G-S30AC/head.jpg",
      bg: "/G-S30AC/bg.jpg",
      special: [
        "/G-S30AC/1.jpg",
        "/G-S30AC/2.jpg",
        "/G-S30AC/3.jpg",
        "/G-S30AC/4.jpg",
        "/G-S30AC/5.jpg",
        "/G-S30AC/6.jpg"
      ]
    },
    video: {
      zh: "",
      en: ""
    },
    spec: {
      en: {
        model: "G-S30AC",
        shape: "G",
        Top: "Solid Alpine Moon Spruce",
        BackSides: "Solid Ziricote",
        Neck: pinen,
        Fingerboard: "Ebony",
        brg: "Ebony",
        Nut: "Bone",
        Saddle: "Bone",
        TuningMachine: "Gotoh/Grover Die Cast Gold With Black Buttons",
        String: xsen,
        FinishTop: "Natural",
        FinishNeck: "Satin",
        FinishBody: "Gloss"
      },
      zh: {
        model: "G-S30AC",
        shape: "G 型",
        Top: "月亮云杉单板",
        BackSides: "破布木单板",
        Neck: pinzh,
        Fingerboard: "乌木",
        brg: "乌木",
        Nut: "牛骨",
        Saddle: "牛骨",
        TuningMachine: "Gotoh/Grover 金色全封闭黑色钮",
        String: xszh,
        FinishTop: "自然色",
        FinishNeck: "哑光",
        FinishBody: "亮光"
      },
      fr: {
        model: "G-S30AC",
        shape: "G",
        Top: "Alpine Moon Spruce Massif",
        BackSides: "Solid Ziricote",
        Neck: pinfr,
        Fingerboard: "Ebène",
        brg: "Ebène",
        Nut: "Os",
        Saddle: "Os",
        TuningMachine: "Gotoh/Grover Die Cast Doré avec boutons noirs",
        String: xsen,
        FinishTop: "Nature",
        FinishNeck: "Satiné",
        FinishBody: "Brillant"
      }
    }
  },
  o000_S31AC: {
    series: "DLX",
    shape: "000",
    model: "000-S31AC",
    desc: {
      en: "",
      zh: "",
      fr: ""
    },
    pics: {
      nav: "/000-S31AC/nav.jpg",
      head: "/000-S31AC/head.jpg",
      bg: "/000-S31AC/bg.jpg",
      special: [
        "/000-S31AC/1.jpg",
        "/000-S31AC/2.jpg",
        "/000-S31AC/3.jpg",
        "/000-S31AC/4.jpg",
        "/000-S31AC/5.jpg",
        "/000-S31AC/6.jpg",
        "/000-S31AC/7.jpg",
      ]
    },
    video: {
      zh: "",
      en: ""
    },
    spec: {
      en: {
        model: "000-S31AC",
        shape: "000",
        Top: "Solid Alpine Moon Spruce",
        BackSides: "Solid Phoebe Zhennan",
        Neck: pinen,
        Fingerboard: "Ebony",
        brg: "Ebony",
        Nut: "Bone",
        Saddle: "Bone",
        TuningMachine: "Gotoh/Grover Die Cast Gold With Black Buttons",
        String: xsen,
        FinishTop: "Natural",
        FinishNeck: "Satin",
        FinishBody: "Gloss"
      },
      zh: {
        model: "000-S31AC",
        shape: "000 型",
        Top: "月亮云杉单板",
        BackSides: "金丝楠单板",
        Neck: pinzh,
        Fingerboard: "乌木",
        brg: "乌木",
        Nut: "牛骨",
        Saddle: "牛骨",
        TuningMachine: "Gotoh/Grover 金色全封闭黑色钮",
        String: xszh,
        FinishTop: "自然色",
        FinishNeck: "哑光",
        FinishBody: "亮光"
      },
      fr: {
        model: "000-S31AC",
        shape: "000",
        Top: "Alpine Moon Spruce Massif",
        BackSides: "Phoebe Zhennan Massif",
        Neck: pinfr,
        Fingerboard: "Ebène",
        brg: "Ebène",
        Nut: "Os",
        Saddle: "Os",
        TuningMachine: "Gotoh/Grover Die Cast Doré avec boutons noirs",
        String: xsen,
        FinishTop: "Nature",
        FinishNeck: "Satiné",
        FinishBody: "Brillant"
      }
    }
  },
  G_S31AC: {
    series: "DLX",
    shape: "G",
    model: "G-S31AC",
    desc: {
      en: "",
      zh: "",
      fr: ""
    },
    pics: {
      nav: "/G-S31AC/nav.jpg",
      head: "/G-S31AC/head.jpg",
      bg: "/G-S31AC/bg.jpg",
      special: [
        "/G-S31AC/1.jpg",
        "/G-S31AC/2.jpg",
        "/G-S31AC/3.jpg",
        "/G-S31AC/4.jpg",
        "/G-S31AC/5.jpg",
        "/G-S31AC/6.jpg"
      ]
    },
    video: {
      zh: "",
      en: ""
    },
    spec: {
      en: {
        model: "G-S31AC",
        shape: "G",
        Top: "Solid Alpine Moon Spruce",
        BackSides: "Solid Phoebe Zhennan",
        Neck: pinen,
        Fingerboard: "Ebony",
        brg: "Ebony",
        Nut: "Bone",
        Saddle: "Bone",
        TuningMachine: "Gotoh/Grover Die Cast Gold With Black Buttons",
        String: xsen,
        FinishTop: "Natural",
        FinishNeck: "Satin",
        FinishBody: "Gloss"
      },
      zh: {
        model: "G-S31AC",
        shape: "G 型",
        Top: "月亮云杉单板",
        BackSides: "金丝楠单板",
        Neck: pinzh,
        Fingerboard: "乌木",
        brg: "乌木",
        Nut: "牛骨",
        Saddle: "牛骨",
        TuningMachine: "Gotoh/Grover 金色全封闭黑色钮",
        String: xszh,
        FinishTop: "自然色",
        FinishNeck: "哑光",
        FinishBody: "亮光"
      },
      fr: {
        model: "G-S31AC",
        shape: "G",
        Top: "Alpine Moon Spruce Massif",
        BackSides: "Phoebe Zhennan Massif",
        Neck: pinfr,
        Fingerboard: "Ebène",
        brg: "Ebène",
        Nut: "Os",
        Saddle: "Os",
        TuningMachine: "Gotoh/Grover Die Cast Doré avec boutons noirs",
        String: xsen,
        FinishTop: "Nature",
        FinishNeck: "Satiné",
        FinishBody: "Brillant"
      }
    }
  },
  o000_S32FAC: {
    series: "DLX",
    shape: "000",
    model: "000-S32FAC",
    desc: {
      en: "",
      zh: "",
      fr: ""
    },
    pics: {
      nav: "/000-S32FAC/nav.jpg",
      head: "/000-S32FAC/head.jpg",
      bg: "/000-S32FAC/bg.jpg",
      special: [
        "/000-S32FAC/1.jpg",
        "/000-S32FAC/2.jpg",
        "/000-S32FAC/3.jpg",
        "/000-S32FAC/4.jpg",
        "/000-S32FAC/5.jpg",
        "/000-S32FAC/6.jpg",
        "/000-S32FAC/7.jpg",
      ]
    },
    video: {
      zh: "",
      en: ""
    },
    spec: {
      en: {
        model: "000-S32FAC",
        shape: "000",
        Top: "Solid Alpine Moon Spruce",
        BackSides: "Solid Flammed Mahogany",
        Neck: pinen,
        Fingerboard: "Ebony",
        brg: "Ebony",
        Nut: "Bone",
        Saddle: "Bone",
        TuningMachine: "Gotoh/Grover Die Cast Gold With Black Buttons",
        String: xsen,
        FinishTop: "Natural",
        FinishNeck: "Satin",
        FinishBody: "Gloss"
      },
      zh: {
        model: "000-S32FAC",
        shape: "000 型",
        Top: "月亮云杉单板",
        BackSides: "斜影木单板",
        Neck: pinzh,
        Fingerboard: "乌木",
        brg: "乌木",
        Nut: "牛骨",
        Saddle: "牛骨",
        TuningMachine: "Gotoh/Grover 金色全封闭黑色钮",
        String: xszh,
        FinishTop: "自然色",
        FinishNeck: "哑光",
        FinishBody: "亮光"
      },
      fr: {
        model: "000-S32FAC",
        shape: "000",
        Top: "Alpine Moon Spruce Massif",
        BackSides: "Flamme Acajou Massif",
        Neck: pinfr,
        Fingerboard: "Ebène",
        brg: "Ebène",
        Nut: "Os",
        Saddle: "Os",
        TuningMachine: "Gotoh/Grover Die Cast Doré avec boutons noirs",
        String: xsen,
        FinishTop: "Nature",
        FinishNeck: "Satiné",
        FinishBody: "Brillant"
      }
    }
  },
  G_S32FAC: {
    series: "DLX",
    shape: "G",
    model: "G-S32FAC",
    desc: {
      en: "",
      zh: "",
      fr: ""
    },
    pics: {
      nav: "/G-S32FAC/nav.jpg",
      head: "/G-S32FAC/head.jpg",
      bg: "/G-S32FAC/bg.jpg",
      special: [
        "/G-S32FAC/1.jpg",
        "/G-S32FAC/2.jpg",
        "/G-S32FAC/3.jpg",
        "/G-S32FAC/4.jpg",
        "/G-S32FAC/5.jpg",
        "/G-S32FAC/6.jpg"
      ]
    },
    video: {
      zh: "",
      en: ""
    },
    spec: {
      en: {
        model: "G-S32FAC",
        shape: "G",
        Top: "Solid Alpine Moon Spruce",
        BackSides: "Solid Flammed Mahogany",
        Neck: pinen,
        Fingerboard: "Ebony",
        brg: "Ebony",
        Nut: "Bone",
        Saddle: "Bone",
        TuningMachine: "Gotoh/Grover Die Cast Gold With Black Buttons",
        String: xsen,
        FinishTop: "Natural",
        FinishNeck: "Satin",
        FinishBody: "Gloss"
      },
      zh: {
        model: "G-S32FAC",
        shape: "G 型",
        Top: "月亮云杉单板",
        BackSides: "斜影木单板",
        Neck: pinzh,
        Fingerboard: "乌木",
        brg: "乌木",
        Nut: "牛骨",
        Saddle: "牛骨",
        TuningMachine: "Gotoh/Grover 金色全封闭黑色钮",
        String: xszh,
        FinishTop: "自然色",
        FinishNeck: "哑光",
        FinishBody: "亮光"
      },
      fr: {
        model: "G-S32FAC",
        shape: "G",
        Top: "Alpine Moon Spruce Massif",
        BackSides: "Flamme Acajou Massif",
        Neck: pinfr,
        Fingerboard: "Ebène",
        brg: "Ebène",
        Nut: "Os",
        Saddle: "Os",
        TuningMachine: "Gotoh/Grover Die Cast Doré avec boutons noirs",
        String: xsen,
        FinishTop: "Nature",
        FinishNeck: "Satiné",
        FinishBody: "Brillant"
      }
    }
  },
  o000_S33AC: {
    series: "DLX",
    shape: "000",
    model: "000-S33AC",
    desc: {
      en: "",
      zh: "",
      fr: ""
    },
    pics: {
      nav: "/000-S33AC/nav.jpg",
      head: "/000-S33AC/head.jpg",
      bg: "/000-S33AC/bg.jpg",
      special: [
        "/000-S33AC/1.jpg",
        "/000-S33AC/2.jpg",
        "/000-S33AC/3.jpg",
        "/000-S33AC/4.jpg",
        "/000-S33AC/5.jpg",
        "/000-S33AC/6.jpg",
        "/000-S33AC/7.jpg",
      ]
    },
    video: {
      zh: "",
      en: ""
    },
    spec: {
      en: {
        model: "000-S33AC",
        shape: "000",
        Top: "Solid Alpine Moon Spruce",
        BackSides: "Solid Lychee",
        Neck: pinen,
        Fingerboard: "Ebony",
        brg: "Ebony",
        Nut: "Bone",
        Saddle: "Bone",
        TuningMachine: "Gotoh/Grover Die Cast Gold With Black Buttons",
        String: xsen,
        FinishTop: "Natural",
        FinishNeck: "Satin",
        FinishBody: "Gloss"
      },
      zh: {
        model: "000-S33AC",
        shape: "000 型",
        Top: "月亮云杉单板",
        BackSides: "荔枝木单板",
        Neck: pinzh,
        Fingerboard: "乌木",
        brg: "乌木",
        Nut: "牛骨",
        Saddle: "牛骨",
        TuningMachine: "Gotoh/Grover 金色全封闭黑色钮",
        String: xszh,
        FinishTop: "自然色",
        FinishNeck: "哑光",
        FinishBody: "亮光"
      },
      fr: {
        model: "000-S33AC",
        shape: "000",
        Top: "Alpine Moon Spruce Massif",
        BackSides: "Litchi Chinensis Massif",
        Neck: pinfr,
        Fingerboard: "Ebène",
        brg: "Ebène",
        Nut: "Os",
        Saddle: "Os",
        TuningMachine: "Gotoh/Grover Die Cast Doré avec boutons noirs",
        String: xsen,
        FinishTop: "Nature",
        FinishNeck: "Satiné",
        FinishBody: "Brillant"
      }
    }
  },
  G_S33AC: {
    series: "DLX",
    shape: "G",
    model: "G-S33AC",
    desc: {
      en: "",
      zh: "",
      fr: ""
    },
    pics: {
      nav: "/G-S33AC/nav.jpg",
      head: "/G-S33AC/head.jpg",
      bg: "/G-S33AC/bg.jpg",
      special: [
        "/G-S33AC/1.jpg",
        "/G-S33AC/2.jpg",
        "/G-S33AC/3.jpg",
        "/G-S33AC/4.jpg",
        "/G-S33AC/5.jpg",
        "/G-S33AC/6.jpg"
      ]
    },
    video: {
      zh: "",
      en: ""
    },
    spec: {
      en: {
        model: "G-S33AC",
        shape: "G",
        Top: "Solid Alpine Moon Spruce",
        BackSides: "Solid Lychee",
        Neck: pinen,
        Fingerboard: "Ebony",
        brg: "Ebony",
        Nut: "Bone",
        Saddle: "Bone",
        TuningMachine: "Gotoh/Grover Die Cast Gold With Black Buttons",
        String: xsen,
        FinishTop: "Natural",
        FinishNeck: "Satin",
        FinishBody: "Gloss"
      },
      zh: {
        model: "G-S33AC",
        shape: "G 型",
        Top: "月亮云杉单板",
        BackSides: "荔枝木单板",
        Neck: pinzh,
        Fingerboard: "乌木",
        brg: "乌木",
        Nut: "牛骨",
        Saddle: "牛骨",
        TuningMachine: "Gotoh/Grover 金色全封闭黑色钮",
        String: xszh,
        FinishTop: "自然色",
        FinishNeck: "哑光",
        FinishBody: "亮光"
      },
      fr: {
        model: "G-S33AC",
        shape: "G",
        Top: "Alpine Moon Spruce Massif",
        BackSides: "Litchi Chinensis Massif",
        Neck: pinfr,
        Fingerboard: "Ebène",
        brg: "Ebène",
        Nut: "Os",
        Saddle: "Os",
        TuningMachine: "Gotoh/Grover Die Cast Doré avec boutons noirs",
        String: xsen,
        FinishTop: "Nature",
        FinishNeck: "Satiné",
        FinishBody: "Brillant"
      }
    }
  },
  o000_S35AC: {
    series: "DLX",
    shape: "000",
    model: "000-S35AC",
    desc: {
      en: "",
      zh: "",
      fr: ""
    },
    pics: {
      nav: "/000-S35AC/nav.jpg",
      head: "/000-S35AC/head.jpg",
      bg: "/000-S35AC/bg.jpg",
      special: [
        "/000-S35AC/1.jpg",
        "/000-S35AC/2.jpg",
        "/000-S35AC/3.jpg",
        "/000-S35AC/4.jpg",
        "/000-S35AC/5.jpg",
        "/000-S35AC/6.jpg",
        "/000-S35AC/7.jpg",
      ]
    },
    video: {
      zh: "",
      en: ""
    },
    spec: {
      en: {
        model: "000-S35AC",
        shape: "000",
        Top: "Solid Alpine Moon Spruce",
        BackSides: "Solid Brazilian Padauk",
        Neck: pinen,
        Fingerboard: "Solid Brazilian Padauk",
        brg: "Solid Brazilian Padauk",
        Nut: "Bone",
        Saddle: "Bone",
        TuningMachine: "Gotoh/Grover Die Cast Gold With Black Buttons",
        String: xsen,
        FinishTop: "Natural",
        FinishNeck: "Satin",
        FinishBody: "Gloss"
      },
      zh: {
        model: "000-S35AC",
        shape: "000 型",
        Top: "月亮云杉单板",
        BackSides: "巴西花梨单板",
        Neck: pinzh,
        Fingerboard: "巴西花梨木",
        brg: "乌木",
        Nut: "牛骨",
        Saddle: "牛骨",
        TuningMachine: "Gotoh/Grover 金色全封闭黑色钮",
        String: xszh,
        FinishTop: "自然色",
        FinishNeck: "哑光",
        FinishBody: "亮光"
      },
      fr: {
        model: "000-S35AC",
        shape: "000",
        Top: "Alpine Moon Spruce Massif",
        BackSides: "Brésilien Padouk Massif",
        Neck: pinfr,
        Fingerboard: "Brésilien Padouk Massif",
        brg: "Ebène",
        Nut: "Os",
        Saddle: "Os",
        TuningMachine: "Gotoh/Grover Die Cast Doré avec boutons noirs",
        String: xsen,
        FinishTop: "Nature",
        FinishNeck: "Satiné",
        FinishBody: "Brillant"
      }
    }
  },
  G_S35AC: {
    series: "DLX",
    shape: "G",
    model: "G-S35AC",
    desc: {
      en: "",
      zh: "",
      fr: ""
    },
    pics: {
      nav: "/G-S35AC/nav.jpg",
      head: "/G-S35AC/head.jpg",
      bg: "/G-S35AC/bg.jpg",
      special: [
        "/G-S35AC/1.jpg",
        "/G-S35AC/2.jpg",
        "/G-S35AC/3.jpg",
        "/G-S35AC/4.jpg",
        "/G-S35AC/5.jpg",
        "/G-S35AC/6.jpg",
        "/G-S35AC/7.jpg",
        "/G-S35AC/8.jpg",
      ]
    },
    video: {
      zh: "",
      en: ""
    },
    spec: {
      en: {
        model: "G-S35AC",
        shape: "G",
        Top: "Solid Alpine Moon Spruce",
        BackSides: "Solid Brazilian Padauk",
        Neck: pinen,
        Fingerboard: "Solid Brazilian Padauk",
        brg: "Solid Brazilian Padauk",
        Nut: "Bone",
        Saddle: "Bone",
        TuningMachine: "Gotoh/Grover Die Cast Gold With Black Buttons",
        String: xsen,
        FinishTop: "Natural",
        FinishNeck: "Satin",
        FinishBody: "Satin"
      },
      zh: {
        model: "G-S35AC",
        shape: "G 型",
        Top: "月亮云杉单板",
        BackSides: "巴西花梨单板",
        Neck: pinzh,
        Fingerboard: "巴西花梨木",
        brg: "乌木",
        Nut: "牛骨",
        Saddle: "牛骨",
        TuningMachine: "Gotoh/Grover 金色全封闭黑色钮",
        String: xszh,
        FinishTop: "自然色",
        FinishNeck: "哑光",
        FinishBody: "哑光"
      },
      fr: {
        model: "G-S35AC",
        shape: "G",
        Top: "Alpine Moon Spruce Massif",
        BackSides: "Brésilien Padouk Massif",
        Neck: pinfr,
        Fingerboard: "Brésilien Padouk Massif",
        brg: "Ebène",
        Nut: "Os",
        Saddle: "Os",
        TuningMachine: "Gotoh/Grover Die Cast Doré avec boutons noirs",
        String: xsen,
        FinishTop: "Nature",
        FinishNeck: "Satiné",
        FinishBody: "Satiné"
      }
    }
  },
  GM_S12: {
    series: "GM",
    shape: "GM",
    model: "GM-S12",
    desc: {
      en: "",
      zh: "",
      fr: ""
    },
    aud: "/aud/GM-S12 DATE 21 09 23 BREEZIN COVER.mp3",
    ply: "Cover",
    sti: "BREEZIN",
    pics: {
      nav: "/GM-S12/nav.jpg",
      head: "/GM-S12/head.jpg",
      bg: "/GM-S12/bg.jpg",
      special: [
        "/GM-S12/1.jpg",
        "/GM-S12/2.jpg",
        "/GM-S12/3.jpg",
        "/GM-S12/4.jpg",
      ]
    },
    video: {
      zh: "",
      en: ""
    },
    spec: {
      en: {
        model: "GM-S12",
        shape: "GM",
        Top: "Solid Sitka Spruce  ",
        BackSides: "Solid Mahogany",
        Neck: "Mahogany",
        Fingerboard: "Indian Rosewood",
        brg: "Indian Rosewood",
        Nut: "Bone",
        Saddle: "Bone",
        TuningMachine: gdcen,
        String: xsen,
        FinishTop: "Natural",
        FinishNeck: "Satin",
        FinishBody: "Gloss"
      },
      zh: {
        model: "GM-S12",
        shape: "GM 型",
        Top: "西特卡云杉单板  ",
        BackSides: "桃花芯单板",
        Neck: "桃花芯",
        Fingerboard: "玫瑰木",
        brg: "玫瑰木",
        Nut: "牛骨",
        Saddle: "牛骨",
        TuningMachine: "金色全封闭带金色钮",
        String: xszh,
        FinishTop: "自然色",
        FinishNeck: "哑光",
        FinishBody: "亮光"
      },
      fr: {
        model: "GM-S12",
        shape: "GM",
        Top: "Epicéa Sitka Massif ",
        BackSides: "Epicéa Acajou Massif",
        Neck: "Acajou",
        Fingerboard: "Palissandre Indien",
        brg: "Palissandre Indien",
        Nut: "Os",
        Saddle: "Os",
        TuningMachine: "Gomans Die Cast Doré avec boutons dorés",
        String: xsen,
        FinishTop: "Nature",
        FinishNeck: "Satiné",
        FinishBody: "Brillant"
      }
    }
  },
  GM_S22: {
    series: "GM",
    shape: "GM",
    model: "GM-S22",
    desc: {
      en: "",
      zh: "全单桑托斯玫瑰木吉他，Gomans专利16品接柄，无需缺角，也可轻松弹到高把位置，桶型缩小同时声音不减，音色厚重朴实，口轮花有如钻石镶嵌。",
      fr: ""
    },
    aud: "/aud/GM-S22 16 FRET COCOPOLO.mp3",
    ply: "Tak Chan",
    sti: "COCOPOLO",
    pics: {
      nav: "/GM-S22/nav.jpg",
      bg: "/GM-S22/bg.jpg",
      special: [
        "/GM-S22/1.jpg",
        "/GM-S22/2.jpg",
        "/GM-S22/3.jpg",
        "/GM-S22/4.jpg",
        "/GM-S22/5.jpg",
        "/GM-S22/6.jpg"
      ]
    },
    video: {
      zh: "",
      en: ""
    },
    spec: {
      en: {
        model: "GM-S22",
        shape: "GM",
        Top: "Solid Alpine Moon Spruce",
        BackSides: "Solid Santos Rosewood",
        Neck: pinen,
        Fingerboard: "Ebony",
        brg: "Ebony",
        Nut: "Bone",
        Saddle: "Bone",
        TuningMachine: "Gomans Die Cast Gold With Black Buttons",
        String: xsen,
        FinishTop: "Natural",
        FinishNeck: "Satin",
        FinishBody: "Gloss"
      },
      zh: {
        model: "GM-S22",
        shape: "GM 型",
        Top: "月亮云杉单板",
        BackSides: "桑托斯玫瑰木单板",
        Neck: pinzh,
        Fingerboard: "乌木",
        brg: "乌木",
        Nut: "牛骨",
        Saddle: "牛骨",
        TuningMachine: "金色全封闭黑色钮",
        String: xszh,
        FinishTop: "自然色",
        FinishNeck: "哑光",
        FinishBody: "亮光"
      },
      fr: {
        model: "GM-S22",
        shape: "GM",
        Top: "Alpine Moon Spruce Massif",
        BackSides: "Epicéa Palissandre De Santos Massif",
        Neck: pinfr,
        Fingerboard: "Ebène",
        brg: "Ebène",
        Nut: "Os",
        Saddle: "Os",
        TuningMachine: "Gomans Die Cast Doré avec boutons noirs",
        String: xsen,
        FinishTop: "Nature",
        FinishNeck: "Satiné",
        FinishBody: "Brillant"
      }
    }
  },
  // Scott Cao
  GSC_AH21: {
    series: "Gomans & Scott Cao",
    shape: "AH",
    model: "GSC-AH21",
    desc: {
      en: "",
      zh: "",
      fr: ""
    },
    aud: "/aud/AH-S21SC CITY OF NEW ORLEANS COVER.mp3",
    ply: "Tak Chan",
    sti: "CITY OF NEW ORLEANS COVER",
    pics: {
      nav: "/AH-S21SC/nav.jpg",
      bg: "/AH-S21SC/bg.jpg",
      special: [
        "/AH-S21SC/1.jpg",
        "/AH-S21SC/2.jpg",
        "/AH-S21SC/3.jpg",
        "/AH-S21SC/4.jpg",
        "/AH-S21SC/5.jpg",
        "/AH-S21SC/6.jpg",
        "/AH-S21SC/7.jpg",
      ]
    },
    video: {
      zh: "",
      en: ""
    },
    spec: {
      en: {
        model: "GSC-AH21",
        shape: "AH",
        Top: "Solid Alpine Moon Spruce ",
        BackSides: "Solid Maple",
        Neck: "5 Pieces Maple/Rosewood",
        Fingerboard: "Ebony",
        brg: "Ebony",
        Nut: "Bone",
        Saddle: "Bone",
        TuningMachine: "Grover Gold With Butterbean Knobs",
        String: xsen,
        FinishTop: "Natural",
        FinishNeck: "Satin",
        FinishBody: "Gloss"
      },
      zh: {
        model: "GSC-AH21",
        shape: "AH 型",
        Top: "月亮云杉单板 ",
        BackSides: "枫木单板",
        Neck: "五拼一体 枫木/玫瑰木",
        Fingerboard: "乌木",
        brg: "乌木",
        Nut: "牛骨",
        Saddle: "牛骨",
        TuningMachine: "金色弦准",
        String: xszh,
        FinishTop: "自然色",
        FinishNeck: "哑光",
        FinishBody: "亮光"
      },
      fr: {
        model: "GSC-AH21",
        shape: "AH",
        Top: "Epicéa Alpine Massif ",
        BackSides: "Erable Massif",
        Neck: "5 piéces Erable/Palissandre",
        Fingerboard: "Ebène",
        brg: "Ebène",
        Nut: "Os",
        Saddle: "Os",
        TuningMachine: "Grover Doré avec boutons Butterbean",
        String: xsen,
        FinishTop: "Nature",
        FinishNeck: "Satiné",
        FinishBody: "Brillant"
      }
    }
  },
  GSC_00018AC: {
    series: "Gomans & Scott Cao",
    shape: "000",
    model: "GSC-00018AC",
    desc: {
      en: "",
      zh: "",
      fr: ""
    },
    aud: "/aud/000-S18ACSC SHANGHAI.mp3",
    ply: "Tak Chan",
    sti: "SHANGHAI",
    pics: {
      nav: "/000-S18ACSC/nav.jpg",
      bg: "/000-S18ACSC/bg.jpg",
      special: [
        "/000-S18ACSC/1.jpg",
        "/000-S18ACSC/2.jpg",
        "/000-S18ACSC/3.jpg",
        "/000-S18ACSC/4.jpg",
        "/000-S18ACSC/5.jpg",
        "/000-S18ACSC/6.jpg",
        "/000-S18ACSC/7.jpg",
      ]
    },
    video: {
      zh: "",
      en: ""
    },
    spec: {
      en: {
        model: "GSC-00018AC",
        shape: "000",
        Top: "Solid Alpine Moon Spruce",
        BackSides: "Solid Indian Rosewood",
        Neck: pinen,
        Fingerboard: "Ebony",
        brg: "Ebony",
        Nut: "Bone",
        Saddle: "Bone",
        TuningMachine: "Gomans Die Cast Gold With Black Buttons",
        String: xsen,
        FinishTop: "Natural",
        FinishNeck: "Satin",
        FinishBody: "Gloss"
      },
      zh: {
        model: "GSC-00018AC",
        shape: "000 型",
        Top: "月亮云杉单板",
        BackSides: "玫瑰木单板",
        Neck: pinzh,
        Fingerboard: "乌木",
        brg: "乌木",
        Nut: "牛骨",
        Saddle: "牛骨",
        TuningMachine: "金色全封闭黑色钮",
        String: xszh,
        FinishTop: "自然色",
        FinishNeck: "哑光",
        FinishBody: "亮光"
      },
      fr: {
        model: "GSC-00018AC",
        shape: "000",
        Top: "Epicéa Alpine Massif",
        BackSides: "Epicéa Palissandre Massif",
        Neck: pinfr,
        Fingerboard: "Ebène",
        brg: "Ebène",
        Nut: "Os",
        Saddle: "Os",
        TuningMachine: "Gomans Die Cast Doré avec boutons noirs",
        String: xsen,
        FinishTop: "Nature",
        FinishNeck: "Satiné",
        FinishBody: "Brillant"
      }
    }
  },
  GSC_D18AC: {
    series: "Gomans & Scott Cao",
    shape: "D",
    model: "GSC-D18AC",
    desc: {
      en: "",
      zh: "",
      fr: ""
    },
    aud: "/aud/D-S18ACSC GAUTHIER.mp3",
    ply: "Tak Chan",
    sti: "GAUTHIER",
    pics: {
      nav: "/D-S18AC/nav.jpg",
      bg: "/D-S18AC/bg.jpg",
      special: [
        "/D-S18AC/1.jpg",
        "/D-S18AC/2.jpg",
        "/D-S18AC/3.jpg",
        "/D-S18AC/4.jpg",
        "/D-S18AC/5.jpg",
        "/D-S18AC/6.jpg",
        "/D-S18AC/7.jpg",
      ]
    },
    video: {
      zh: "",
      en: ""
    },
    spec: {
      en: {
        model: "GSC-D18AC",
        shape: "D",
        Top: "Solid Alpine Moon Spruce",
        BackSides: "Solid Indian Rosewood",
        Neck: pinen,
        Fingerboard: "Ebony",
        brg: "Ebony",
        Nut: "Bone",
        Saddle: "Bone",
        TuningMachine: "Gomans Die Cast Gold With Black Buttons",
        String: xsen,
        FinishTop: "Natural",
        FinishNeck: "Satin",
        FinishBody: "Gloss"
      },
      zh: {
        model: "GSC-D18AC",
        shape: "D 型",
        Top: "月亮云杉单板",
        BackSides: "玫瑰木单板",
        Neck: pinzh,
        Fingerboard: "乌木",
        brg: "乌木",
        Nut: "牛骨",
        Saddle: "牛骨",
        TuningMachine: "金色全封闭黑色钮",
        String: xszh,
        FinishTop: "自然色",
        FinishNeck: "哑光",
        FinishBody: "亮光"
      },
      fr: {
        model: "GSC-D18AC",
        shape: "D",
        Top: "Epicéa Alpine Massif",
        BackSides: "Epicéa Palissandre Massif",
        Neck: pinfr,
        Fingerboard: "Ebène",
        brg: "Ebène",
        Nut: "Os",
        Saddle: "Os",
        TuningMachine: "Gomans Die Cast Doré avec boutons noirs",
        String: xsen,
        FinishTop: "Nature",
        FinishNeck: "Satiné",
        FinishBody: "Brillant"
      }
    }
  },
  G_S36AC: {
    series: "Limited Edition",
    shape: "G",
    model: "G-S36AC",
    desc: {
      en: "",
      zh: "",
      fr: ""
    },
    aud: "",
    ply: "",
    sti: "",
    pics: {
      nav: "/G-S36AC/nav.jpg",
      bg: "/G-S36AC/bg.jpg",
      special: [
        "/G-S36AC/nav.jpg",
        "/G-S36AC/2.jpg",
        "/G-S36AC/3.jpg",
        "/G-S36AC/4.jpg",
        "/G-S36AC/5.jpg",
        "/G-S36AC/bg.jpg",
        "/G-S36AC/7.jpg",
        "/G-S36AC/8.jpg",
      ]
    },
    video: {
      zh: "",
      en: ""
    },
    spec: {
      en: {
        model: "G-S36AC",
        shape: "G",
        Top: "Solid Sinker Red Cedar",
        BackSides: "Solid Sapele",
        Neck: pinen,
        Fingerboard: "Ebony",
        brg: "Ebony",
        Nut: "Bone",
        Saddle: "Bone",
        TuningMachine: "Gomans Die Cast Gold With Black Buttons",
        String: xsen,
        FinishTop: "Natural",
        FinishNeck: "Satin",
        FinishBody: "Gloss"
      },
      zh: {
        model: "G-S36AC",
        shape: "G 型",
        Top: "水浸红松单板",
        BackSides: "球纹沙比利单板",
        Neck: pinzh,
        Fingerboard: "乌木",
        brg: "乌木",
        Nut: "牛骨",
        Saddle: "牛骨",
        TuningMachine: "金色全封闭黑色钮",
        String: xszh,
        FinishTop: "自然色",
        FinishNeck: "哑光",
        FinishBody: "亮光"
      },
      fr: {
        model: "G-S36AC",
        shape: "G",
        Top: "Cèdre Rouge Massif",
        BackSides: "Solid Sapele",
        Neck: pinfr,
        Fingerboard: "Ebène",
        brg: "Ebène",
        Nut: "Os",
        Saddle: "Os",
        TuningMachine: "Gomans Die Cast Doré avec boutons noirs",
        String: xsen,
        FinishTop: "Nature",
        FinishNeck: "Satiné",
        FinishBody: "Brillant"
      }
    }
  },
  o000_S36AC: {
    series: "Limited Edition",
    shape: "000",
    model: "000-S36AC",
    desc: {
      en: "",
      zh: "",
      fr: ""
    },
    aud: "",
    ply: "",
    sti: "",
    pics: {
      nav: "/000-S36AC/nav.jpg",
      bg: "/000-S36AC/bg.jpg",
      special: [
        "/000-S36AC/nav.jpg",
        "/000-S36AC/2.jpg",
        "/000-S36AC/3.jpg",
        "/000-S36AC/4.jpg",
        "/000-S36AC/bg.jpg",
        "/000-S36AC/6.jpg",
        "/000-S36AC/7.jpg",
      ]
    },
    video: {
      zh: "",
      en: ""
    },
    spec: {
      en: {
        model: "000-S36AC",
        shape: "000",
        Top: "Solid Sinker Red Cedar",
        BackSides: "Solid Sapele",
        Neck: pinen,
        Fingerboard: "Ebony",
        brg: "Ebony",
        Nut: "Bone",
        Saddle: "Bone",
        TuningMachine: "Gomans Die Cast Gold With Black Buttons",
        String: xsen,
        FinishTop: "Natural",
        FinishNeck: "Satin",
        FinishBody: "Gloss"
      },
      zh: {
        model: "000-S36AC",
        shape: "000 型",
        Top: "水浸红松单板",
        BackSides: "球纹沙比利单板",
        Neck: pinzh,
        Fingerboard: "乌木",
        brg: "乌木",
        Nut: "牛骨",
        Saddle: "牛骨",
        TuningMachine: "金色全封闭黑色钮",
        String: xszh,
        FinishTop: "自然色",
        FinishNeck: "哑光",
        FinishBody: "亮光"
      },
      fr: {
        model: "000-S36AC",
        shape: "000",
        Top: "Cèdre Rouge Massif",
        BackSides: "Solid Sapele",
        Neck: pinfr,
        Fingerboard: "Ebène",
        brg: "Ebène",
        Nut: "Os",
        Saddle: "Os",
        TuningMachine: "Gomans Die Cast Doré avec boutons noirs",
        String: xsen,
        FinishTop: "Nature",
        FinishNeck: "Satiné",
        FinishBody: "Brillant"
      }
    }
  },
}