export const shapes = {

  ah: {
    name: "AH",
    link: "/guitars/shapes/ah",
    desc: {
      zh: "创新性的琴头设计，紧凑、减重，配合Gomans指板镶嵌，琴身整体更美观。",
      en: "Gomans innovative head design, light & beauty. Gomans fingerboard inlay, makes it a better overall appearance. ",
      fr: "Gomans innovative head design, Gomans fingerboard inlay",
    },
    siz: {
      bdl: "449",
      shd: "245",
      wai: "206",
      tum: "347",
      upt: "80",
      dot: "100",
      stl: "635",
      tot: "1018"
    },
    models: [
      {
        model: "AH-S18",
        desc: {
          zh: "",
          en: "",
          fr: ""
        },
        pic: "/AH-S18/nav.jpg",
        link: "/guitar/AH-S18"
      },
      {
        model: "AH-S21",
        desc: {
          zh: "",
          en: "",
          fr: ""
        },
        pic: "/AH-S21/nav.jpg",
        link: "/guitar/AH-S21"
      },
      {
        model: "GSC-AH21",
        desc: {
          zh: "",
          en: "",
          fr: ""
        },
        pic: "/AH-S21SC/nav.jpg",
        link: "/guitar/GSC-AH21"
      },
      {
        model: "AH-SMG",
        desc: {
          zh: "",
          en: "",
          fr: ""
        },
        pic: "/AH-SMG/nav.jpg",
        link: "/guitar/AH-SMG"
      },
      {
        model: "AH12-S18",
        desc: {
          zh: "",
          en: "",
          fr: ""
        },
        pic: "/AH12-S18/nav.jpg",
        link: "/guitar/AH12-S18"
      },
    ]
  },

  ooo: {
    name: "000",
    link: "/guitars/shapes/000",
    desc: {
      zh: "强劲的中音，清亮而富有颗粒感的高音，不过分的低音，让他的音色充满弹性与力量，从而成为传统指弹吉他艺术家最钟爱的款式。",
      en: "The powerful midrange, clear and grainy treble, and overly low bass make his tone full of elasticity and power, making it a favorite style of traditional fingerstyle guitar artists.",
      fr: "Des médiums forts, des aigus clairs et granuleux, et pas trop de basses rendent son ton plein d'élasticité et de puissance, devenant ainsi le style préféré des artistes de guitare fingerstyle traditionnels.",
    },
    siz: {
      bdl: "489",
      shd: "288",
      wai: "238",
      tum: "388",
      upt: "80",
      dot: "100",
      stl: "648",
      tot: "1033"
    },
    models: [
      {
        model: "000-S12HC",
        desc: {
          zh: "",
          en: "",
          fr: ""
        },
        pic: "/000-S12HC/nav.jpg",
        link: "/guitar/000-S12HC"
      },
      {
        model: "000-S12HCS",
        desc: {
          zh: "",
          en: "",
          fr: ""
        },
        pic: "/000-S12HCS/nav.jpg",
        link: "/guitar/000-S12HCS"
      },
      {
        model: "000-S15HCS",
        desc: {
          zh: "",
          en: "",
          fr: ""
        },
        pic: "/000-S15HCS/nav.jpg",
        link: "/guitar/000-S15HCS"
      },
      {
        model: "000-S18",
        desc: {
          zh: "",
          en: "",
          fr: ""
        },
        pic: "/000-S18/nav.jpg",
        link: "/guitar/000-S18"
      },
      {
        model: "000-S18AC",
        desc: {
          zh: "",
          en: "",
          fr: ""
        },
        pic: "/000-S18AC/nav.jpg",
        link: "/guitar/000-S18AC"
      },
      {
        model: "000-S18ACS",
        desc: {
          zh: "",
          en: "",
          fr: ""
        },
        pic: "/000-S18ACS/nav.jpg",
        link: "/guitar/000-S18ACS"
      },
      {
        model: "GSC-00018AC",
        desc: {
          zh: "",
          en: "",
          fr: ""
        },
        pic: "/000-S18ACSC/nav.jpg",
        link: "/guitar/GSC-00018AC"
      },
      {
        model: "000-S18AC DLX",
        desc: {
          zh: "",
          en: "",
          fr: ""
        },
        pic: "/000-S18AC_DLX/nav.jpg",
        link: "/guitar/000-S18AC_DLX"
      },
      {
        model: "000-S19",
        desc: {
          zh: "",
          en: "",
          fr: ""
        },
        pic: "/000-S19/nav.jpg",
        link: "/guitar/000-S19"
      },
      {
        model: "000-SA21HCS",
        desc: {
          zh: "",
          en: "",
          fr: ""
        },
        pic: "/000-SA21HCS/nav.jpg",
        link: "/guitar/000-SA21HCS"
      },
      {
        model: "000-SA21HCSB",
        desc: {
          zh: "",
          en: "",
          fr: ""
        },
        pic: "/000-SA21HCSB/nav.jpg",
        link: "/guitar/000-SA21HCSB"
      },
      {
        model: "000-S23HC",
        desc: {
          zh: "",
          en: "",
          fr: ""
        },
        pic: "/000-S23HC/nav.jpg",
        link: "/guitar/000-S23HC"
      },
      {
        model: "000-S30AC",
        desc: {
          zh: "",
          en: "",
          fr: ""
        },
        pic: "/000-S30AC/nav.jpg",
        link: "/guitar/000-S30AC"
      },
      {
        model: "000-S31AC",
        desc: {
          zh: "",
          en: "",
          fr: ""
        },
        pic: "/000-S31AC/nav.jpg",
        link: "/guitar/000-S31AC"
      },
      {
        model: "000-S32FAC",
        desc: {
          zh: "",
          en: "",
          fr: ""
        },
        pic: "/000-S32FAC/nav.jpg",
        link: "/guitar/000-S32FAC"
      },
      {
        model: "000-S33AC",
        desc: {
          zh: "",
          en: "",
          fr: ""
        },
        pic: "/000-S33AC/nav.jpg",
        link: "/guitar/000-S33AC"
      },
      {
        model: "000-S35AC",
        desc: {
          zh: "",
          en: "",
          fr: ""
        },
        pic: "/000-S35AC/nav.jpg",
        link: "/guitar/000-S35AC"
      },
      {
        model: "000-S36AC",
        desc: {
          zh: "",
          en: "",
          fr: ""
        },
        pic: "/000-S36AC/nav.jpg",
        link: "/guitar/000-S36AC"
      },
    ]
  },

  g: {
    name: "G",
    link: "/guitars/shapes/g",
    desc: {
      zh: "Gomans自研琴型，结合了Jumbo型与F型的音色特点，拓展了原声吉他音色的可能性，只为满足追求极致音色的演奏者。",
      en: "Gomans own design body, advantages from F and Jumbo combined, making a new possibility for acoustic guitar, all in pursuit of the best.",
      fr: "Gomans own design body, advantages from F and Jumbo combined, making a new possibility for acoustic guitar, all in pursuit of the best.",
    },
    siz: {
      bdl: "505",
      shd: "293",
      wai: "245",
      tum: "393",
      upt: "90/100",
      dot: "110/120",
      stl: "648",
      tot: "1049"
    },
    models: [
      {
        model: "G-S12-BK",
        desc: {
          zh: "",
          en: "",
          fr: ""
        },
        pic: "/G-S12-BK/nav.jpg",
        link: "/guitar/G-S12_BK"
      },
      {
        model: "G-S12CS",
        desc: {
          zh: "",
          en: "",
          fr: ""
        },
        pic: "/G-S12CS/nav.jpg",
        link: "/guitar/G-S12CS"
      },
      {
        model: "G-S15CS",
        desc: {
          zh: "",
          en: "",
          fr: ""
        },
        pic: "/G-S15CS/nav.jpg",
        link: "/guitar/G-S15CS"
      },
      {
        model: "G-S16",
        desc: {
          zh: "",
          en: "",
          fr: ""
        },
        pic: "/G-S16/nav.jpg",
        link: "/guitar/G-S16"
      },
      {
        model: "GC-S18AC",
        desc: {
          zh: "",
          en: "",
          fr: ""
        },
        pic: "/GC-S18AC/nav.jpg",
        link: "/guitar/GC-S18AC"
      },
      {
        model: "GC-S18ACA",
        desc: {
          zh: "",
          en: "",
          fr: ""
        },
        pic: "/GC-S18ACA/nav.jpg",
        link: "/guitar/GC-S18ACA"
      },
      {
        model: "G-S18AC-KP",
        desc: {
          zh: "",
          en: "",
          fr: ""
        },
        pic: "/G-S18AC-KP/nav.jpg",
        link: "/guitar/G-S18AC-KP"
      },
      {
        model: "GC-S19AC DLX",
        desc: {
          zh: "",
          en: "",
          fr: ""
        },
        pic: "/GC-S19AC_DLX/nav.jpg",
        link: "/guitar/GC-S19AC_DLX"
      },
      {
        model: "G-SA19DC",
        desc: {
          zh: "",
          en: "",
          fr: ""
        },
        pic: "/G-SA19DC/nav.jpg",
        link: "/guitar/G-SA19DC"
      },
      {
        model: "G-S21DCTB",
        desc: {
          zh: "",
          en: "",
          fr: ""
        },
        pic: "/G-S21DCTB/nav.jpg",
        link: "/guitar/G-S21DCTB"
      },
      {
        model: "G-SA21CSB",
        desc: {
          zh: "",
          en: "",
          fr: ""
        },
        pic: "/G-SA21CSB/nav.jpg",
        link: "/guitar/G-SA21CSB"
      },
      {
        model: "G-S22C",
        desc: {
          zh: "",
          en: "",
          fr: ""
        },
        pic: "/G-S22C/nav.jpg",
        link: "/guitar/G-S22C"
      },
      {
        model: "G-S22C PinkLady",
        desc: {
          zh: "",
          en: "",
          fr: ""
        },
        pic: "/G-S22C_PinkLady/nav.jpg",
        link: "/guitar/G-S22C_PinkLady"
      },
      {
        model: "G-S22AC",
        desc: {
          zh: "",
          en: "",
          fr: ""
        },
        pic: "/G-S22AC/nav.jpg",
        link: "/guitar/G-S22AC"
      },
      {
        model: "GC-S22AC DLX",
        desc: {
          zh: "",
          en: "",
          fr: ""
        },
        pic: "/G-S22AC_DLX/nav.jpg",
        link: "/guitar/GC-S22AC_DLX"
      },
      {
        model: "G-S30AC",
        desc: {
          zh: "",
          en: "",
          fr: ""
        },
        pic: "/G-S30AC/nav.jpg",
        link: "/guitar/G-S30AC"
      },
      {
        model: "G-S31AC",
        desc: {
          zh: "",
          en: "",
          fr: ""
        },
        pic: "/G-S31AC/nav.jpg",
        link: "/guitar/G-S31AC"
      },
      {
        model: "G-S32FAC",
        desc: {
          zh: "",
          en: "",
          fr: ""
        },
        pic: "/G-S32FAC/nav.jpg",
        link: "/guitar/G-S32FAC"
      },
      {
        model: "G-S33AC",
        desc: {
          zh: "",
          en: "",
          fr: ""
        },
        pic: "/G-S33AC/nav.jpg",
        link: "/guitar/G-S33AC"
      },
      {
        model: "G-S35AC",
        desc: {
          zh: "",
          en: "",
          fr: ""
        },
        pic: "/G-S35AC/nav.jpg",
        link: "/guitar/G-S35AC"
      },
      {
        model: "G-S36AC",
        desc: {
          zh: "",
          en: "",
          fr: ""
        },
        pic: "/G-S36AC/nav.jpg",
        link: "/guitar/G-S36AC"
      },
      {
        model: "GC-S38AC DLX",
        desc: {
          zh: "",
          en: "",
          fr: ""
        },
        pic: "/GC-S38AC_DLX/nav.jpg",
        link: "/guitar/GC-S38AC_DLX"
      },
      {
        model: "GC-S88AC DLX",
        desc: {
          zh: "",
          en: "",
          fr: ""
        },
        pic: "/GC-S88AC_DLX/nav.jpg",
        link: "/guitar/GC-S88AC_DLX"
      },
    ]
  },

  f: {
    name: "F",
    link: "/guitars/shapes/f",
    desc: {
      zh: "拥有与000型一样饱满的颗粒感音色，同时还拥有Jumbo型的震撼低音，F型是Gomans对原声吉他的一次创新与改革。",
      en: "Between G and jumbo, deep bass and clear treble, the F is an innovation to acoustic guitar by Gomans.",
      fr: "Between G and jumbo, deep bass and clear treble, the F is an innovation to acoustic guitar by Gomans.",
    },
    siz: {
      bdl: "513",
      shd: "302",
      wai: "253",
      tum: "408",
      upt: "100",
      dot: "120",
      stl: "648",
      tot: "1057"
    },
    models: [
      {
        model: "F-S16C",
        desc: {
          zh: "",
          en: "",
          fr: ""
        },
        pic: "/F-S16C/nav.jpg",
        link: "/guitar/F-S16C"
      },
      {
        model: "F-S18FC",
        desc: {
          zh: "",
          en: "",
          fr: ""
        },
        pic: "/F-S18FC/nav.jpg",
        link: "/guitar/F-S18FC"
      },
      {
        model: "F-S12FC-BK",
        desc: {
          zh: "",
          en: "",
          fr: ""
        },
        pic: "/F-S12FC-BK/nav.jpg",
        link: "/guitar/F-S12FC-BK"
      },
      {
        model: "F-S21C",
        desc: {
          zh: "",
          en: "",
          fr: ""
        },
        pic: "/F-S21C/nav.jpg",
        link: "/guitar/F-S21C"
      },
      {
        model: "F-S21FHC",
        desc: {
          zh: "",
          en: "",
          fr: ""
        },
        pic: "/F-S21FHC/nav.jpg",
        link: "/guitar/F-S21FHC"
      },
      {
        shape: "F",
        model: "F-S38FAC DLX",
        desc: {
          zh: "",
          en: "",
          fr: ""
        },
        pic: "/F-S38FAC_DLX/nav.jpg",
        link: "/guitar/F-S38FAC_DLX"
      },
    ]
  },

  j: {
    name: "J",
    link: "/guitars/shapes/j",
    desc: {
      zh: "最强劲的低音，最清脆的高音，使它能发出了最具个性的声音。这也是现今众多新锐指弹吉他艺术家选择它的原因。",
      en: "Gomans redesign jumbo body. Matching with fanfret bridge = Full deep sound + Bell like treble sound",
      fr: "Gomans redesign jumbo body. Matching with fanfret bridge = Full deep sound + Bell like treble sound",
    },
    siz: {
      bdl: "514",
      shd: "308",
      wai: "249",
      tum: "418",
      upt: "100",
      dot: "120",
      stl: "648",
      tot: "1058"
    },
    models: [
      {
        model: "J-S18FC",
        desc: {
          zh: "",
          en: "",
          fr: ""
        },
        pic: "/J-S18FC/nav.jpg",
        link: "/guitar/J-S18FC"
      },
      {
        model: "J-S19FC",
        desc: {
          zh: "",
          en: "",
          fr: ""
        },
        pic: "/J-S19FC/nav.jpg",
        link: "/guitar/J-S19FC"
      },
      {
        model: "J-S38AC DLX",
        desc: {
          zh: "",
          en: "",
          fr: ""
        },
        pic: "/J-S38AC_DLX/nav.jpg",
        link: "/guitar/J-S38AC_DLX"
      },
      {
        model: "J-S38FAC DLX",
        desc: {
          zh: "",
          en: "",
          fr: ""
        },
        pic: "/J-S38FAC_DLX/nav.jpg",
        link: "/guitar/J-S38FAC_DLX"
      },
      // {
      //     model: "J-S38FC",
      //     desc: {
      //         zh: "",
      //         en: "",
      //         fr: ""
      //     },
      //     pic: "/J-S38FC/nav.jpg",
      //     link: "/guitar/J-S38FC"
      // },
      {
        model: "J12-S12AC",
        desc: {
          zh: "",
          en: "",
          fr: ""
        },
        pic: "/J12-S12AC/nav.jpg",
        link: "/guitar/J12-S12AC"
      },
    ]
  },

  bj: {
    name: "BJ",
    link: "/guitars/shapes/bj",
    desc: {
      zh: "Gomans 大Jumbo，深度音感，全域覆盖。",
      en: "Gomans Big Jumbo, Depth dimension, full range sounds. ",
      fr: "Gomans Big Jumbo, Depth dimension, full range sounds. ",
    },
    siz: {
      bdl: "539",
      shd: "303",
      wai: "257",
      tum: "413",
      upt: "103",
      dot: "123",
      stl: "648",
      tot: "1083"
    },
    models: [
      // {
      //     model: "BJ-S18DC DLX",
      //     desc: {
      //         zh: "",
      //         en: "",
      //         fr: ""
      //     },
      //     pic: "/BJ-S18DC_DLX/nav.jpg",
      //     link: "/guitar/BJ-S18DC_DLX"
      // },
      {
        model: "BJ-S19FDC",
        desc: {
          zh: "",
          en: "",
          fr: ""
        },
        pic: "/BJ-S19FDC/nav.jpg",
        link: "/guitar/BJ-S19FDC"
      },
      {
        model: "BJ-S21DC DLX",
        desc: {
          zh: "",
          en: "",
          fr: ""
        },
        pic: "/BJ-S21DC_DLX/nav.jpg",
        link: "/guitar/BJ-S21DC_DLX"
      },
      {
        model: "BJ-S22BFDC",
        desc: {
          zh: "",
          en: "",
          fr: ""
        },
        pic: "/BJ-S22BFDC/nav.jpg",
        link: "/guitar/BJ-S22BFDC"
      },
    ]
  },

  d: {
    name: "D",
    link: "/guitars/shapes/d",
    desc: {
      zh: "最传统的原声吉他款式，均衡的高低音分配，极具包围感的共鸣，使它成为当之无愧的经典。",
      en: "The most traditional acoustic guitar style, with balanced high and low pitch distribution and extremely surround resonance, makes it a worthy classic.",
      fr: "The most traditional acoustic guitar style, with balanced high and low pitch distribution and extremely surround resonance, makes it a worthy classic.",
    },
    siz: {
      bdl: "507",
      shd: "295",
      wai: "274",
      tum: "397",
      upt: "100",
      dot: "120",
      stl: "648",
      tot: "1052"
    },
    models: [
      {
        model: "D-S12AC",
        desc: {
          zh: "",
          en: "",
          fr: ""
        },
        pic: "/D-S12AC/nav.jpg",
        link: "/guitar/D-S12AC"
      },
      {
        model: "GSC-D18AC",
        desc: {
          zh: "",
          en: "",
          fr: ""
        },
        pic: "/D-S18AC/nav.jpg",
        link: "/guitar/GSC-D18AC"
      },
    ]
  },

  gm: {
    name: "GM",
    link: "/guitars/shapes/gm",
    desc: {
      zh: "Gomans 迷你吉他，小身材，大声响，让你奏响天涯。",
      en: "Gomans Mini, Small guitar, big sound, brings you enjoy everywhere.",
      fr: "Gomans Mini, Small guitar, big sound, brings you enjoy everywhere.",
    },
    siz: {
      bdl: "470",
      shd: "273",
      wai: "228",
      tum: "366",
      upt: "87",
      dot: "105",
      stl: "630",
      tot: "983"
    },
    models: [
      {
        model: "GM-S12",
        desc: {
          zh: "",
          en: "",
          fr: ""
        },
        pic: "/GM-S12/nav.jpg",
        link: "/guitar/GM-S12"
      },
      {
        model: "GM-S22",
        desc: {
          zh: "",
          en: "",
          fr: ""
        },
        pic: "/GM-S22/nav.jpg",
        link: "/guitar/GM-S22"
      },
    ]
  },

  gb: {
    name: "GB",
    link: "/guitars/shapes/gb",
    desc: {
      zh: "专为追求轻便的旅行家设计，为您的旅途减负的同时，琴声不息。",
      en: "Also known as Gomans Baby, specially designed for travellers all around the globe.",
      fr: "Also known as Gomans Baby, specially designed for travellers all around the globe.",
    },
    siz: {
      bdl: "440",
      shd: "248",
      wai: "210",
      tum: "338",
      upt: "80",
      dot: "100",
      stl: "597",
      tot: "924"
    },
    models: [
      {
        model: "GB-S12",
        desc: {
          zh: "",
          en: "",
          fr: ""
        },
        pic: "/GB-S12/nav.jpg",
        link: "/guitar/GB-S12"
      },
    ]
  },
}