import Vue from 'vue'
import VueRouter from 'vue-router'

import layout from '../layout.vue'
import HomeView from '../views/HomeView.vue'
import Guitar from '@/views/guitars/Guitar.vue'
import Shape from '@/views/shape/Shape.vue'

Vue.use(VueRouter)

const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function (location) {
  return routerPush.call(this, location).catch(error => error);
}

const constRoutes = [
  {
    path: '/',
    meta: {
      title: 'Gomans Guitar'
    },
    component: layout,
    children: [
      { path: '/', name: 'home', component: HomeView },
      // {path:'/home',component:HomeView},
      { path: 'guitars/shapes/:param', name: "shapes", component: Shape },
      { path: 'guitars/fret/:param', name: "Fret", component: () => import(`../views/fret/fret.vue`) },
      { path: 'dlx', component: () => import(`../views/DLX/DLX.vue`) },
      { path: 'ltd', component: () => import(`../views/ltd/ltd.vue`) },
      { path: 'ltd/special', name: "ltd_special", component: () => import(`../views/ltd/special.vue`) },
      { path: 'ltd/limited', name: "ltd_limited", component: () => import(`../views/ltd/limited.vue`) },
      { path: 'ltd/ScottCao', name: "sc", component: () => import(`../views/ltd/sc.vue`) },
      { path: 'xii', name: "xii", component: () => import(`../views/XII/XII.vue`) },
      // { path: 'cust', name: "cust", component: () => import(`../views/cust/custom.vue`) },
      { path: 'videos', name: "videos", component: () => import(`../views/vid/vid.vue`) },
      { path: 'about', name: "about", component: () => import(`../views/AboutView.vue`) },
      { 
        path: '/cust', name: "cust", component: () => import(`../views/cust/welcome.vue`) 
      },
    ]
  },
  {
    path: "/",
    name: "Guitar",
    component: layout,
    children: [
      { path: '/guitar/:param', name: 'guitar', component: Guitar }
    ]
  },
  {
    path: '/custom',
    name: 'custom',
    component: () => import('../views/cust/g/cust.vue'),
  },
  { 
    path: '/custom/o',
    name: 'custO',
    component: () => import('../views/cust/om/custO.vue'),
  },
  { 
    path: '/custom/f',
    name: 'custF',
    component: () => import('../views/cust/f/custF.vue'),
  },
  { 
    path: '/custom/j',
    name: 'custJ',
    component: () => import('../views/cust/j/custJ.vue'),
  },
  {
    path: '/vr',
    name: 'vr',
    component: () => import('../views/vr.vue'),
  },
  {
    path: '/home',
    name: 'homerdr',
    redirect: '/',
    meta: {
      title: 'GomansGuitar'
    },
    component: HomeView,
  },
  {
    path: '/index.php/*',
    name: 'homerdr',
    redirect: '/',
    meta: {
      title: 'GomansGuitar'
    },
    component: HomeView,
  },

  {
    path: '/401',
    component: layout,
    children: [{ path: '/', name: 'Unauthorized', component: () => import('../views/404.vue') },]
  },
  {
    path: '/404',
    component: layout,
    children: [{ path: '/', name: 'NotFound', component: () => import('../views/404.vue') },]
  },
  {
    path: '/*',
    component: layout,
    children: [{ path: '/', name: 'NotFounda', component: () => import('../views/404.vue') },]
  },
]

const router = new VueRouter({
  mode: 'history',
  routes: constRoutes
})

// 全局设置路由跳转回到顶部
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
})

export default router
