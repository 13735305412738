<template>
  <div id="app" class="app">
    <router-view></router-view>
  </div>
</template>

<script>
import axios from 'axios';
import Cookies from "js-cookie";

export default {
  data() {
    return {
    };
  },
  created() {

    // Shut the fuck up
    const log = console.log;
    console.log = () => {};
    const warn = console.warn;
    console.warn = () => {};
    const error = console.error;
    console.error = () => {};

    let adat = { qr: 0, lang: 0, ll: 0, udw: 0 };
    let last = { zh: 1, en: 2, fr: 3 };

    if (this.$route.query.qr) {
      adat.qr = this.$route.query.qr;
      history.replaceState(null, "", '/');
    }

    // 语言设定
    let lastlang = localStorage.getItem("lang");
    if (lastlang == 'en' || lastlang == 'zh' || lastlang == 'fr') {
      this.$i18n.locale = lastlang;
      adat.ll = last[lastlang];
    }
    else {
      // 获取浏览器的语言
      var navilang = navigator.language;

      if (navilang.startsWith('zh')) {
        this.$i18n.locale = 'zh';
      } else if (navilang.startsWith('fr')) {
        this.$i18n.locale = 'fr';
      } else {
        this.$i18n.locale = 'en';
      }
      window.localStorage.setItem("lang", this.$i18n.locale);
    }

    // if (!Cookies.get('uid')) {
      adat.lang = last[this.$i18n.locale];
      adat.udw = screen.width;
      let rt = { tat: btoa(JSON.stringify(adat)) }
      axios.post('/api/check', rt)
        .then(function (response) {
          console.log(response);
          if (response.status == 200 && response.data.code == 0) {
            Cookies.set("uid", response.data?.data, { expires: 1 });
          }
        })
        .catch(function (er) {
          console.log(er);
        })
    // }
  }
}
</script>

<style scoped>
#app {
  /* Avenir, Helvetica, Arial, sans-serif,  */
  font-family: "Microsoft YaHei", "微软雅黑", "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #fff;
  background-color: #000;
  height: 100%;
  width: 100%;
}
</style>